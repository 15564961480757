import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from "firebaseSetup";
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

interface LoginLogoutButtonProps {
  isLoggedIn: boolean;
  closeNavCallback: Dispatch<SetStateAction<boolean>>;
};

const LoginLogoutButton = ({ isLoggedIn, closeNavCallback }: LoginLogoutButtonProps) => {
  const navigate = useNavigate();

  let buttonText = isLoggedIn ? 'Log out' : 'Log in';

  const logout = async () => {
    try {
      await auth.signOut();
      navigate('/')
      closeNavCallback(false)
      
    } catch (e: any) {
      console.log(e)
    }
  }

  const handleButtonClick = () => {
    if (isLoggedIn) {
      logout()

    } else {
      navigate('/login')
      closeNavCallback(false)
    }
  };

  // TODO: add in async button loading
  return (
    <List>
      <ListItem
        key={buttonText.split(' ').join('-')}
        disableGutters
        sx={{
          display: 'flex',
          justifyContent: 'center',
          pt: 0,
          pb: 0,
        }}
      >
        <Button
          variant="contained"
          endIcon={<ExitToAppOutlinedIcon />}
          onClick={handleButtonClick}
        >
          {buttonText}
        </Button>
      </ListItem>
    </List>
  )
};

export default LoginLogoutButton;