import { Box, Divider, Typography, useMediaQuery, useTheme } from "@mui/material";
import { pickEntityColor } from "components/helpers";
import { PickEntity } from "config/competitions";
import { MoneylinePick, OverUnderPick, SpreadPick } from "reducers/types/competitionTypes";

export interface PickDisplayProps {
  pick: SpreadPick | MoneylinePick | OverUnderPick;
};

const PickDisplay = ({ pick }: PickDisplayProps) => {
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (pick.percentage) {
    const lowerNumberEntities = pick.entity === PickEntity.PUBLIC || pick.entity === PickEntity.ODDSMAKERS
    const higherNumberEntities = pick.entity === PickEntity.SL || pick.entity === PickEntity.MONEY_BET || pick.entity === PickEntity.BET_WISER

    let plotBarPositionKey  = ((pick.pickType === 'spread' || pick.pickType === 'moneyline') && pick.homeOrAway === 'away') ||
          (pick.pickType === 'over-under' && pick.overOrUnder === 'under') ? 'right' : 'left';

    let plotBarPositionValue;
    let plotBarDisplayValue;

    const pickPercentageToValue = pick.percentage * 100;
    if (plotBarPositionKey === 'right') {
      plotBarPositionValue = pickPercentageToValue < 50 ? pickPercentageToValue + 1.75 : pickPercentageToValue - 1.75; 
      plotBarDisplayValue = pickPercentageToValue < 50 ? 100 - pickPercentageToValue : pickPercentageToValue;
    } else {
      plotBarPositionValue = pickPercentageToValue > 50 ? pickPercentageToValue - 1.75 : pickPercentageToValue + 1.75;
      plotBarDisplayValue = pickPercentageToValue < 50 ? 100 - pickPercentageToValue : pickPercentageToValue;
    }

    return (
      <Box
        sx={{ 
          position: 'absolute', 
          [plotBarPositionKey]: `${plotBarPositionValue}%`,
          top: lowerNumberEntities ? -6 : -26.0,
          zIndex: 10 
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          { higherNumberEntities && (
              <Typography variant="caption">{`${(plotBarDisplayValue).toFixed(0)}`}</Typography>
            )
          }
          <Divider orientation="vertical" sx={{ height: '20px', border: `${isTabletOrMobile ? '1px' : '2px'} solid ${ pickEntityColor(pick.entity)}`}} />
          { lowerNumberEntities && (
              <Typography variant="caption">{`${(plotBarDisplayValue).toFixed(0)}`}</Typography>
            )
          }
        </Box>
      </Box>
    )
  } else {
    return (<></>);
  }
};

export default PickDisplay;