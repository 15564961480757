import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

import { parseString } from 'models/api';
import { AppDispatch } from 'reducers/store';
import { parseCsvData, buildMlbDataCsvFilePath } from '../services/csvService';
import { MLB_TEAM_CODE } from 'config/mlb';
import { LeagueCode, LeagueStat } from 'reducers/types/sharedTypes';

import {
  buildMLBTeamData,
  buildLeagueData,
  buildMLBTeam,
  buildBattingStats,
  buildPitchingStats,
} from 'reducers/builders';
import {
  BattingStats,
  PitchingStats,
  MLBLeagueData,
  MLBTeam,
  MLBTeams,
  MLBDataReducerState,
  LeagueBattingStats,
  LeaguePitchingStats,
  MLBTimePeriod,
  BattingSplit,
  PitchingSplit,
  BattingSplits,
  PitchingSplits,

  PitcherDataByPitcher,
  MLBPlayer,
  MLBPlayers,
} from 'reducers/types/mlbTypes';
import { analyzeLeagueData } from 'components/helpers';
import { formatPapaBatter, formatPapaBattingStats, formatPapaPitcher, formatPapaPitchingStats } from 'services/fangraphsMLBDataService';
import { formatTeam } from './builders/mlbBuilders';
import { getAverage } from 'components/helpers/statitstics';
import { getTeamCodeForPitcher, getTeamCodeForBatter } from 'components/helpers/mlb/multiTeamPlayers';
import { MLBServiceGameInfos } from 'services/types/mlbApiTypes';


const { mlbTeams, mlbTeamCodes } = buildMLBTeamData();
const initLeagueData = buildLeagueData();
let initPlayers: MLBPlayers = {};

const initialState: MLBDataReducerState = {
  leagueData: initLeagueData,
  playerData: initLeagueData,
  teams: mlbTeams,
  teamIds: mlbTeamCodes,
  batters: initPlayers,
  batterIds: [],
  pitchers: initPlayers,
  pitcherIds: [],
};

export const mlbDataSlice = createSlice({
  name: "mlbData",
  initialState,
  reducers: {
    setLeaguePitchingData(state: MLBDataReducerState, action: PayloadAction<LeaguePitchingStats>) {
      if (!isEqual(state.leagueData.pitching, action.payload)) {
        state.leagueData.pitching = action.payload;
      }
    },
    setLeagueBattingData(state: MLBDataReducerState, action: PayloadAction<LeagueBattingStats>) {
      if (!isEqual(state.leagueData.pitching, action.payload)) {
        state.leagueData.batting = action.payload;
      }
    },

    setTeamCurrentPitchingOverallData(state: MLBDataReducerState, action: PayloadAction<MLBTeams>) {
      for (const team in action.payload) {
        const teamData = state.teams[team].teamData.current.pitching.overall;
        if (!teamData || !isEqual(teamData, action.payload[team].teamData.current.pitching.overall)) {
          state.teams[team].teamData.current.pitching.overall = action.payload[team].teamData.current.pitching.overall;
        }
      }
    },
    setTeamLast14PitchingOverallData(state: MLBDataReducerState, action: PayloadAction<MLBTeams>) {
      for (const team in action.payload) {
        const teamData = state.teams[team].teamData.last14.pitching.overall;
        if (!teamData || !isEqual(teamData, action.payload[team].teamData.last14.pitching.overall)) {
          state.teams[team].teamData.last14.pitching.overall = action.payload[team].teamData.last14.pitching.overall;
        }
      }
    },
    setTeamLast30PitchingOverallData(state: MLBDataReducerState, action: PayloadAction<MLBTeams>) {
      for (const team in action.payload) {
        const teamData = state.teams[team].teamData.last30.pitching.overall;
        if (!teamData || !isEqual(teamData, action.payload[team].teamData.last30.pitching.overall)) {
          state.teams[team].teamData.last30.pitching.overall = action.payload[team].teamData.last30.pitching.overall;
        }
      }
    },
    setTeamCurrentBattingOverallData(state: MLBDataReducerState, action: PayloadAction<MLBTeams>) {
      for (const team in action.payload) {
        const teamData = state.teams[team].teamData.current.batting.overall;
        if (!teamData || !isEqual(teamData, action.payload[team].teamData.current.batting.overall)) {
          state.teams[team].teamData.current.batting.overall = action.payload[team].teamData.current.batting.overall;
        }
      }
    },
    setTeamLast14BattingOverallData(state: MLBDataReducerState, action: PayloadAction<MLBTeams>) {
      for (const team in action.payload) {
        const teamData = state.teams[team].teamData.last14.batting.overall;
        if (!teamData || !isEqual(teamData, action.payload[team].teamData.last14.batting.overall)) {
          state.teams[team].teamData.last14.batting.overall = action.payload[team].teamData.last14.batting.overall;
        }
      }
    },
    setTeamLast30BattingOverallData(state: MLBDataReducerState, action: PayloadAction<MLBTeams>) {
      for (const team in action.payload) {
        const teamData = state.teams[team].teamData.last30.batting.overall;
        if (!teamData || !isEqual(teamData, action.payload[team].teamData.last30.batting.overall)) {
          state.teams[team].teamData.last30.batting.overall = action.payload[team].teamData.last30.batting.overall;
        }
      }
    },







    setPlayerPitchingCurrentOverallData(state: MLBDataReducerState, action: PayloadAction<LeaguePitchingStats>) {
      if (!isEqual(state.playerData.pitching, action.payload)) {
        state.playerData.pitching = action.payload;
      }
    },


    



    setTeamCurrentPitchingLHHData(state: MLBDataReducerState, action: PayloadAction<MLBTeams>) {
      for (const team in action.payload) {
        const teamData = state.teams[team].teamData.current.pitching.lhh;
        if (!teamData || !isEqual(teamData, action.payload[team].teamData.current.pitching.lhh)) {
          state.teams[team].teamData.current.pitching.lhh = action.payload[team].teamData.current.pitching.lhh;
        }
      }
    },
    setTeamCurrentPitchingRHHData(state: MLBDataReducerState, action: PayloadAction<MLBTeams>) {
      for (const team in action.payload) {
        const teamData = state.teams[team].teamData.current.pitching.rhh;
        if (!teamData || !isEqual(teamData, action.payload[team].teamData.current.pitching.rhh)) {
          state.teams[team].teamData.current.pitching.rhh = action.payload[team].teamData.current.pitching.rhh;
        }
      }
    },









    // pitcher
    setPitchersCurrentOverallData(state: MLBDataReducerState, action: PayloadAction<MLBPlayers>) {
      for (const pitcher in action.payload) {
        const existingPitcher = state.pitchers[pitcher];
        if (existingPitcher) {
          const pitcherData = state.pitchers[pitcher].playerData.current as PitchingSplits;
          if (!pitcherData || !isEqual(pitcherData, action.payload[pitcher].playerData.current)) {
            state.pitchers[pitcher].playerData.current = action.payload[pitcher].playerData.current;
          }
        } else {
          state.pitchers[pitcher] = action.payload[pitcher];
        }
      }
    },
    setPitcherIds(state: MLBDataReducerState, action: PayloadAction<number[]>) {
      if (!isEqual(state.pitcherIds, action.payload)) {
        state.pitcherIds = action.payload;
      }
    },

    // batter
    setBattersCurrentOverallData(state: MLBDataReducerState, action: PayloadAction<MLBPlayers>) {
      for (const batter in action.payload) {
        const existingBatter = state.batters[batter];
        if (existingBatter) {
          const batterData = state.batters[batter].playerData.current as BattingSplits;
          if (!batterData || !isEqual(batterData, action.payload[batter].playerData.current)) {
            state.batters[batter].playerData.current = action.payload[batter].playerData.current;
          }
        } else {
          state.batters[batter] = action.payload[batter];
        }
      }
    },
    setBatterIds(state: MLBDataReducerState, action: PayloadAction<number[]>) {
      if (!isEqual(state.batterIds, action.payload)) {
        state.batterIds = action.payload;
      }
    },

    // batting
    

    setPlayerBattingCurrentOverallData(state: MLBDataReducerState, action: PayloadAction<LeagueBattingStats>) {
      if (!isEqual(state.playerData.batting, action.payload)) {
        state.playerData.batting = action.payload;
      }
    },

    
  }
})

export const {
  // setTeams,
  setLeaguePitchingData,
  setLeagueBattingData,
  setTeamCurrentPitchingOverallData,
  setTeamLast14PitchingOverallData,
  setTeamLast30PitchingOverallData,
  setTeamCurrentBattingOverallData,
  setTeamLast14BattingOverallData,
  setTeamLast30BattingOverallData,
  



  setPlayerPitchingCurrentOverallData,
  

  setTeamCurrentPitchingLHHData,
  setTeamCurrentPitchingRHHData,

  setPitchersCurrentOverallData,
  setPitcherIds,
  setBattersCurrentOverallData,
  setBatterIds,

  
  setPlayerBattingCurrentOverallData,
  

} = mlbDataSlice.actions;

export type MLBDataAppState = { [mlbDataSlice.name]: MLBDataReducerState };

export const selectMlbDataState = (state: MLBDataAppState) => state.mlbData;
export const selectTeams = createSelector(selectMlbDataState, (state) => state.teams);
export const selectTeamIds = createSelector(selectMlbDataState, (state) => state.teamIds);

export const selectLeagueData = createSelector(selectMlbDataState, (state) => state.leagueData);
export const selectLeaguePitchingData = createSelector(selectLeagueData, (state) => state.pitching);
export const selectLeagueBattingData = createSelector(selectLeagueData, (state) => state.batting);

export const selectLeaguePlayerData = createSelector(selectMlbDataState, (state) => state.playerData);
export const selectLeaguePlayerPitchingData = createSelector(selectLeaguePlayerData, (state) => state.pitching);
export const selectLeaguePlayerBattingData = createSelector(selectLeaguePlayerData, (state) => state.batting);

export const selectPitchers = createSelector(selectMlbDataState, (state) => state.pitchers);
export const selectPitcherIds = createSelector(selectMlbDataState, (state) => state.pitcherIds);
export const selectBatters = createSelector(selectMlbDataState, (state) => state.batters);
export const selectBatterIds = createSelector(selectMlbDataState, (state) => state.batterIds);

export const selectTeamWinsAndLossesSelector = (
  teamAbbr: MLB_TEAM_CODE,
  timePeriod: MLBTimePeriod,
  split: PitchingSplit,
) => {
  return createSelector(selectTeams, (teams: MLBTeams) => {
    let wins = 0;
    let losses = 0;
    if (teams[teamAbbr]) {
      wins = teams[teamAbbr].teamData[timePeriod].pitching[split].wins;
      losses = teams[teamAbbr].teamData[timePeriod].pitching[split].losses;
    };

    return { wins, losses };
  });
};

export const selectLeagueBattingMetricInfo = (
  metricName: string,
) => {
  return createSelector(selectLeagueBattingData, (leagueBattingData: LeagueBattingStats): LeagueStat => {
    let metricKey = metricName as keyof LeagueBattingStats;
    return leagueBattingData[metricKey];
  });
};

export const selectTeamBattingData = (
  teamAbbr: MLB_TEAM_CODE,
  timePeriod: MLBTimePeriod,
  split: BattingSplit,
) => {
  return createSelector(selectTeams, (teams: MLBTeams): BattingStats => {
    return teams[teamAbbr] ? teams[teamAbbr].teamData[timePeriod].batting[split] : buildBattingStats();
  });
};

export const selectTeamBattingMetricValue = (
  teamAbbr: MLB_TEAM_CODE,
  timePeriod: MLBTimePeriod,
  split: BattingSplit,
  metricName: string,
) => {
  return createSelector(selectTeams, (teams: MLBTeams) => {
    let metricKey = metricName as keyof BattingStats;
    const battingStats = teams[teamAbbr] ? teams[teamAbbr].teamData[timePeriod].batting[split] : buildBattingStats();
    return battingStats[metricKey];
  });
};

export const selectLeaguePitchingMetricInfo = (
  metricName: string,
) => {
  return createSelector(selectLeaguePitchingData, (leaguePitchingData: LeaguePitchingStats): LeagueStat | null => {
    let metricKey = metricName as keyof LeaguePitchingStats;
    if (metricKey === 'pitches') return null;
    return leaguePitchingData[metricKey];
  });
};

export const selectTeamPitchingData = (
  teamAbbr: MLB_TEAM_CODE,
  timePeriod: MLBTimePeriod,
  split: PitchingSplit,
) => {
  return createSelector(selectTeams, (teams: MLBTeams): PitchingStats => {
    return teams[teamAbbr] ? teams[teamAbbr].teamData[timePeriod].pitching[split] : buildPitchingStats();
  });
};

export const selectTeamPitchingMetricValue = (
  teamAbbr: MLB_TEAM_CODE,
  timePeriod: MLBTimePeriod,
  split: PitchingSplit,
  metricName: string,
) => {
  return createSelector(selectTeams, (teams: MLBTeams) => {
    let metricKey = metricName as keyof PitchingStats;
    if (metricKey === 'pitches') return null

    const pitchingStats = teams[teamAbbr] ? teams[teamAbbr].teamData[timePeriod].pitching[split] : buildPitchingStats();
    return pitchingStats[metricKey];
  });
};

export const selectTeamPitchers = (
  teamAbbr: MLB_TEAM_CODE,
) => {
  return createSelector(selectPitchers, (pitchers: MLBPlayers): MLBPlayers => {
    let selectedPitchers: MLBPlayers = {};

    for (const pitcher in pitchers) {
      if (pitchers[pitcher].teamCode === teamAbbr) {
        selectedPitchers[pitcher] = pitchers[pitcher];
      };
    };

    return selectedPitchers;
  });
};

export const selectPitcherStats = (
  pitcherId: string,
  timePeriod: MLBTimePeriod,
  split: PitchingSplit,
) => {
  return createSelector(selectPitchers, (pitchers: MLBPlayers): PitchingStats => {
    if (pitcherId === '') return buildPitchingStats();
    let pitchingSplits = pitchers[pitcherId].playerData[timePeriod] as PitchingSplits;
    let pitchingStats = pitchingSplits[split];
    return pitchingStats ?  pitchingStats : buildPitchingStats();
  });
};

/**
 * since papa parse isn't really setup to handle passing in params and dealing with
 * returning data we have to update pieces individually. To get around the messiness
 * of that we can use the misc builder functions. To handle this for non current data
 * I can pass in timePeriod, battingSplit, and pitchingSplit into initializeTeamData
 * and then pass them in so each data set can be loaded and properly set in state.
 * 
 * Once that is complete, I can build/update selectors to make sure they take timePeriod
 * and split so specific data can be selected using the same selectors.
 * @returns 
 */
export const initializeTeamData = () => {

  return (dispatch: AppDispatch) => {
    // ideally the service is separated but papa parse is a PITA because it doesn't return anything
    // const teamBattingData: TeamBattingData = fangraphsMLBDataService.getTeamBattingData();

    let teams: MLBTeams = {};
    let leagueData: MLBLeagueData = buildLeagueData();
    let playerData: MLBLeagueData = buildLeagueData();
    let leagueWinsArray: number[] = [];
    let batters: MLBPlayers = {};
    let batterIds: number[] = [];
    let pitchers: MLBPlayers = {};
    let pitcherIds: number[] = [];

    const processCurrentLeaguePitchingData = (papaData: any, timePeriod: MLBTimePeriod) => {
      for (const teamData of papaData) {
        let timePeriod: MLBTimePeriod = 'current';
        let pitchingSplit: PitchingSplit = 'overall';
        const teamAbbr = parseString(teamData.Team) as MLB_TEAM_CODE;

        let pitchingStats = formatPapaPitchingStats(teamData);

        let team = formatTeam(
          'pitching',
          teamAbbr,
          timePeriod,
          pitchingSplit,
          pitchingStats,
          teams[teamAbbr],
        );

        teams[teamAbbr] = team;

        // then consider building some kind of league "format" function for the arrays below
        // where should formatTeam and this new format function live?

        let gamesPlayed = leagueData.pitching.gamesPlayed.leagueTotals;
        gamesPlayed.push(pitchingStats.gamesPlayed)
        leagueData.pitching.gamesPlayed.leagueTotals = gamesPlayed;

        let leagueWins = leagueData.pitching.wins.leagueTotals;
        leagueWins.push(pitchingStats.wins)
        leagueWinsArray.push(pitchingStats.wins)
        leagueData.pitching.wins.leagueTotals = leagueWins;

        let leagueLosses = leagueData.pitching.losses.leagueTotals;
        leagueLosses.push(pitchingStats.losses)
        leagueData.pitching.losses.leagueTotals = leagueLosses;

        let leagueSaves = leagueData.pitching.saves.leagueTotals;
        leagueSaves.push(pitchingStats.saves)
        leagueData.pitching.saves.leagueTotals = leagueSaves;

        let leagueInningsPitched = leagueData.pitching.inningsPitched.leagueTotals;
        leagueInningsPitched.push(pitchingStats.inningsPitched)
        leagueData.pitching.inningsPitched.leagueTotals = leagueInningsPitched;

        let leagueWar = leagueData.pitching.war.leagueTotals;
        leagueWar.push(pitchingStats.war)
        leagueData.pitching.war.leagueTotals = leagueWar;

        let leagueXfip = leagueData.pitching.xfip.leagueTotals;
        leagueXfip.push(pitchingStats.xfip)
        leagueData.pitching.xfip.leagueTotals = leagueXfip;

        let leagueEra = leagueData.pitching.era.leagueTotals;
        leagueEra.push(pitchingStats.era)
        leagueData.pitching.era.leagueTotals = leagueEra;

        let leagueLob = leagueData.pitching.leftOnBasePercentage.leagueTotals;
        leagueLob.push(pitchingStats.leftOnBasePercentage)
        leagueData.pitching.leftOnBasePercentage.leagueTotals = leagueLob;

        let leagueTbf = leagueData.pitching.totalBattersFaced.leagueTotals;
        leagueTbf.push(pitchingStats.totalBattersFaced)
        leagueData.pitching.totalBattersFaced.leagueTotals = leagueTbf;

        let leagueHr = leagueData.pitching.homerunsAllowed.leagueTotals;
        leagueHr.push(pitchingStats.homerunsAllowed)
        leagueData.pitching.homerunsAllowed.leagueTotals = leagueHr;

        let leagueRuns = leagueData.pitching.runsAllowed.leagueTotals;
        leagueRuns.push(pitchingStats.runsAllowed)
        leagueData.pitching.runsAllowed.leagueTotals = leagueRuns;

        let leagueWhip = leagueData.pitching.whip.leagueTotals;
        leagueWhip.push(pitchingStats.whip)
        leagueData.pitching.whip.leagueTotals = leagueWhip;

        let leagueAvg = leagueData.pitching.battingAverageAgainst.leagueTotals;
        leagueAvg.push(pitchingStats.battingAverageAgainst)
        leagueData.pitching.battingAverageAgainst.leagueTotals = leagueAvg;

        let leagueClutch = leagueData.pitching.clutch.leagueTotals;
        leagueClutch.push(pitchingStats.clutch)
        leagueData.pitching.clutch.leagueTotals = leagueClutch;

        // pitch specific data
        let leagueFBPct = leagueData.pitching.pitches.fastball.percentageThrow.leagueTotals;
        leagueFBPct.push(pitchingStats.pitches.fastball.percentageThrow)
        leagueData.pitching.pitches.fastball.percentageThrow.leagueTotals = leagueFBPct;

        let leagueFBVel = leagueData.pitching.pitches.fastball.averageVelocity.leagueTotals;
        leagueFBVel.push(pitchingStats.pitches.fastball.averageVelocity)
        leagueData.pitching.pitches.fastball.averageVelocity.leagueTotals = leagueFBVel;

        let leagueSLPct = leagueData.pitching.pitches.slider.percentageThrow.leagueTotals;
        leagueSLPct.push(pitchingStats.pitches.slider.percentageThrow)
        leagueData.pitching.pitches.slider.percentageThrow.leagueTotals = leagueSLPct;

        let leagueSLVel = leagueData.pitching.pitches.slider.averageVelocity.leagueTotals;
        leagueSLVel.push(pitchingStats.pitches.slider.averageVelocity)
        leagueData.pitching.pitches.slider.averageVelocity.leagueTotals = leagueSLVel;

        let leagueCTPct = leagueData.pitching.pitches.cutter.percentageThrow.leagueTotals;
        leagueCTPct.push(pitchingStats.pitches.cutter.percentageThrow)
        leagueData.pitching.pitches.cutter.percentageThrow.leagueTotals = leagueCTPct;

        let leagueCTVel = leagueData.pitching.pitches.cutter.averageVelocity.leagueTotals;
        leagueCTVel.push(pitchingStats.pitches.cutter.averageVelocity)
        leagueData.pitching.pitches.cutter.averageVelocity.leagueTotals = leagueCTVel;

        let leagueCBPct = leagueData.pitching.pitches.curveball.percentageThrow.leagueTotals;
        leagueCBPct.push(pitchingStats.pitches.curveball.percentageThrow)
        leagueData.pitching.pitches.curveball.percentageThrow.leagueTotals = leagueCBPct;

        let leagueCBVel = leagueData.pitching.pitches.curveball.averageVelocity.leagueTotals;
        leagueCBVel.push(pitchingStats.pitches.curveball.averageVelocity)
        leagueData.pitching.pitches.curveball.averageVelocity.leagueTotals = leagueCBVel;

        let leagueCHPct = leagueData.pitching.pitches.changeup.percentageThrow.leagueTotals;
        leagueCHPct.push(pitchingStats.pitches.changeup.percentageThrow)
        leagueData.pitching.pitches.changeup.percentageThrow.leagueTotals = leagueCHPct;

        let leagueCHVel = leagueData.pitching.pitches.changeup.averageVelocity.leagueTotals;
        leagueCHVel.push(pitchingStats.pitches.changeup.averageVelocity)
        leagueData.pitching.pitches.changeup.averageVelocity.leagueTotals = leagueCHVel;
      };

      // calculate remaining league metric data 
      for (const metric in leagueData.pitching) {
        const metricKey = metric as keyof LeaguePitchingStats;

        let leagueStatArray: number[] = [];

        if (metricKey === 'pitches') {
          let pitchesLeagueInfo = {};

          // should loop through pitch keys in case more pitch type want to be included
          // but for now it is faster to focus on specific common pitches
          // fastball
          leagueStatArray = leagueData.pitching.pitches.fastball.percentageThrow.leagueTotals;
          let leagueStatRanking = leagueStatArray.toSorted((a: number, b: number) => a - b);
          const { average: avgFBPctThrow, correlation: corFBPctThrow, stdDeviation: devFBPctThrow } = analyzeLeagueData(metricKey, leagueStatArray, leagueWinsArray);
          leagueData.pitching.pitches.fastball.percentageThrow = {
            ...leagueData.pitching.pitches.fastball.percentageThrow,
            rankedTotals: leagueStatRanking,
            leagueAverage: avgFBPctThrow,
            correlationToWins:  corFBPctThrow,
            stdDeviation: devFBPctThrow,
          };

          leagueStatArray = leagueData.pitching.pitches.fastball.averageVelocity.leagueTotals;
          leagueStatRanking = leagueStatArray.toSorted((a: number, b: number) => a - b);
          const { average: avgFBAvgVel, correlation: corFBAvgVel, stdDeviation: devFBAvgVel } = analyzeLeagueData(metricKey, leagueStatArray, leagueWinsArray);
          leagueData.pitching.pitches.fastball.averageVelocity = {
            ...leagueData.pitching.pitches.fastball.averageVelocity,
            rankedTotals: leagueStatRanking,
            leagueAverage: avgFBAvgVel,
            correlationToWins:  corFBAvgVel,
            stdDeviation: devFBAvgVel,
          };

          // slider
          leagueStatArray = leagueData.pitching.pitches.slider.percentageThrow.leagueTotals;
          leagueStatRanking = leagueStatArray.toSorted((a: number, b: number) => a - b);
          const { average: avgSLPctThrow, correlation: corSLPctThrow, stdDeviation: devSLPctThrow } = analyzeLeagueData(metricKey, leagueStatArray, leagueWinsArray);
          leagueData.pitching.pitches.slider.percentageThrow = {
            ...leagueData.pitching.pitches.slider.percentageThrow,
            rankedTotals: leagueStatRanking,
            leagueAverage: avgSLPctThrow,
            correlationToWins:  corSLPctThrow,
            stdDeviation: devSLPctThrow,
          };

          leagueStatArray = leagueData.pitching.pitches.slider.averageVelocity.leagueTotals;
          leagueStatRanking = leagueStatArray.toSorted((a: number, b: number) => a - b);
          const { average: avgSLVelAvg, correlation: corSLVelAvg, stdDeviation: devSLVelAvg } = analyzeLeagueData(metricKey, leagueStatArray, leagueWinsArray);
          leagueData.pitching.pitches.slider.averageVelocity = {
            ...leagueData.pitching.pitches.slider.averageVelocity,
            rankedTotals: leagueStatRanking,
            leagueAverage: avgSLVelAvg,
            correlationToWins:  corSLVelAvg,
            stdDeviation: devSLVelAvg,
          };

          // slider
          leagueStatArray = leagueData.pitching.pitches.cutter.percentageThrow.leagueTotals;
          leagueStatRanking = leagueStatArray.toSorted((a: number, b: number) => a - b);
          const { average: avgCTPctThrow, correlation: corCTPctThrow, stdDeviation: devCTPctThrow } = analyzeLeagueData(metricKey, leagueStatArray, leagueWinsArray);
          leagueData.pitching.pitches.cutter.percentageThrow = {
            ...leagueData.pitching.pitches.cutter.percentageThrow,
            rankedTotals: leagueStatRanking,
            leagueAverage: avgCTPctThrow,
            correlationToWins:  corCTPctThrow,
            stdDeviation: devCTPctThrow,
          };

          leagueStatArray = leagueData.pitching.pitches.cutter.averageVelocity.leagueTotals;
          leagueStatRanking = leagueStatArray.toSorted((a: number, b: number) => a - b);
          const { average: avgCTVelAvg, correlation: corCTVelAvg, stdDeviation: devCTVelAvg } = analyzeLeagueData(metricKey, leagueStatArray, leagueWinsArray);
          leagueData.pitching.pitches.cutter.averageVelocity = {
            ...leagueData.pitching.pitches.cutter.averageVelocity,
            rankedTotals: leagueStatRanking,
            leagueAverage: avgCTVelAvg,
            correlationToWins:  corCTVelAvg,
            stdDeviation: devCTVelAvg,
          };

          // curveball
          leagueStatArray = leagueData.pitching.pitches.curveball.percentageThrow.leagueTotals;
          leagueStatRanking = leagueStatArray.toSorted((a: number, b: number) => a - b);
          const { average: avgCBPctThrow, correlation: corCBPctThrow, stdDeviation: devCBPctThrow } = analyzeLeagueData(metricKey, leagueStatArray, leagueWinsArray);
          leagueData.pitching.pitches.curveball.percentageThrow = {
            ...leagueData.pitching.pitches.curveball.percentageThrow,
            rankedTotals: leagueStatRanking,
            leagueAverage: avgCBPctThrow,
            correlationToWins:  corCBPctThrow,
            stdDeviation: devCBPctThrow,
          };

          leagueStatArray = leagueData.pitching.pitches.curveball.averageVelocity.leagueTotals;
          leagueStatRanking = leagueStatArray.toSorted((a: number, b: number) => a - b);
          const { average: avgCBVelAvg, correlation: corCBVelAvg, stdDeviation: devCBVelAvg } = analyzeLeagueData(metricKey, leagueStatArray, leagueWinsArray);
          leagueData.pitching.pitches.curveball.averageVelocity = {
            ...leagueData.pitching.pitches.curveball.averageVelocity,
            rankedTotals: leagueStatRanking,
            leagueAverage: avgCBVelAvg,
            correlationToWins:  corCBVelAvg,
            stdDeviation: devCBVelAvg,
          };

          // changeup
          leagueStatArray = leagueData.pitching.pitches.changeup.percentageThrow.leagueTotals;
          leagueStatRanking = leagueStatArray.toSorted((a: number, b: number) => a - b);
          const { average: avgCHPctThrow, correlation: corCHPctThrow, stdDeviation: devCHPctThrow } = analyzeLeagueData(metricKey, leagueStatArray, leagueWinsArray);
          leagueData.pitching.pitches.changeup.percentageThrow = {
            ...leagueData.pitching.pitches.changeup.percentageThrow,
            rankedTotals: leagueStatRanking,
            leagueAverage: avgCHPctThrow,
            correlationToWins:  corCHPctThrow,
            stdDeviation: devCHPctThrow,
          };

          leagueStatArray = leagueData.pitching.pitches.changeup.averageVelocity.leagueTotals;
          leagueStatRanking = leagueStatArray.toSorted((a: number, b: number) => a - b);
          const { average: avgCHVelAvg, correlation: corCHVelAvg, stdDeviation: devCHVelAvg } = analyzeLeagueData(metricKey, leagueStatArray, leagueWinsArray);
          leagueData.pitching.pitches.changeup.averageVelocity = {
            ...leagueData.pitching.pitches.changeup.averageVelocity,
            rankedTotals: leagueStatRanking,
            leagueAverage: avgCHVelAvg,
            correlationToWins:  corCHVelAvg,
            stdDeviation: devCHVelAvg,
          };

        } else {
          leagueStatArray = leagueData.pitching[metricKey].leagueTotals;
          const leagueStatRanking = leagueStatArray.toSorted((a: number, b: number) => a - b);
          const { average, correlation, stdDeviation } = analyzeLeagueData(metricKey, leagueStatArray, leagueWinsArray);

          leagueData.pitching[metricKey] = {
            ...leagueData.pitching[metricKey],
            rankedTotals: leagueStatRanking,
            leagueAverage: average,
            correlationToWins:  correlation,
            stdDeviation: stdDeviation,
          };

        };
      }

      dispatch(setTeamCurrentPitchingOverallData(teams));
      dispatch(setLeaguePitchingData(leagueData.pitching));

    };

    parseCsvData(buildMlbDataCsvFilePath('team-pitching-current-full.csv'), processCurrentLeaguePitchingData);

    // TODO: The copy pasta here is temporary as a refactor of papaparse for an api is looming
    //       For demo and design purposes, this will function
    const processLast14LeaguePitchingData = (papaData: any, timePeriod: MLBTimePeriod) => {
      // let teamsLast14: MLBTeams = {};
      for (const teamData of papaData) {
        let timePeriod: MLBTimePeriod = 'last14';
        let pitchingSplit: PitchingSplit = 'overall';
        const teamAbbr = parseString(teamData.Team) as MLB_TEAM_CODE;

        let pitchingStats = formatPapaPitchingStats(teamData);

        let team = formatTeam(
          'pitching',
          teamAbbr,
          timePeriod,
          pitchingSplit,
          pitchingStats,
          teams[teamAbbr],
        );

        teams[teamAbbr] = team;
      }

      dispatch(setTeamLast14PitchingOverallData(teams));
    };

    parseCsvData(buildMlbDataCsvFilePath('team-pitching-last14-full.csv'), processLast14LeaguePitchingData);

    // TODO: The copy pasta here is temporary as a refactor of papaparse for an api is looming
    //       For demo and design purposes, this will function
    const processLast30LeaguePitchingData = (papaData: any, timePeriod: MLBTimePeriod) => {
      // let teamsLast14: MLBTeams = {};
      for (const teamData of papaData) {
        let timePeriod: MLBTimePeriod = 'last30';
        let pitchingSplit: PitchingSplit = 'overall';
        const teamAbbr = parseString(teamData.Team) as MLB_TEAM_CODE;

        let pitchingStats = formatPapaPitchingStats(teamData);

        let team = formatTeam(
          'pitching',
          teamAbbr,
          timePeriod,
          pitchingSplit,
          pitchingStats,
          teams[teamAbbr],
        );

        teams[teamAbbr] = team;
      }

      dispatch(setTeamLast30PitchingOverallData(teams));
    };

    parseCsvData(buildMlbDataCsvFilePath('team-pitching-last30-full.csv'), processLast30LeaguePitchingData);



    const processCurrentLeagueBattingData = (papaData: any) => {
      for (const teamData of papaData) {
        let timePeriod: MLBTimePeriod = 'current';
        let battingSplit: BattingSplit = 'overall';
        let pitchingSplit: PitchingSplit = 'overall';
        const teamAbbr = parseString(teamData.Team) as MLB_TEAM_CODE;

        let runsAgainst = teams[teamAbbr].teamData[timePeriod].pitching[pitchingSplit].runsAllowed;
        let battingStats = formatPapaBattingStats(teamData, runsAgainst);

        let team = formatTeam(
          'batting',
          teamAbbr,
          timePeriod,
          battingSplit,
          battingStats,
          teams[teamAbbr],
        );

        teams[teamAbbr] = team;

        let leagueAtBats = leagueData.batting.atBats.leagueTotals;
        leagueAtBats.push(battingStats.atBats)
        leagueData.batting.atBats.leagueTotals = leagueAtBats;

        let leaguePlateAttempts = leagueData.batting.plateAttempts.leagueTotals;
        leaguePlateAttempts.push(battingStats.plateAttempts)
        leagueData.batting.plateAttempts.leagueTotals = leaguePlateAttempts;

        let leagueHits = leagueData.batting.hits.leagueTotals;
        leagueHits.push(battingStats.hits)
        leagueData.batting.hits.leagueTotals = leagueHits;

        let leagueHomeruns = leagueData.batting.homeruns.leagueTotals;
        leagueHomeruns.push(battingStats.homeruns)
        leagueData.batting.homeruns.leagueTotals = leagueHomeruns;

        let leagueRunsScored = leagueData.batting.runsScored.leagueTotals;
        leagueRunsScored.push(battingStats.runsScored)
        leagueData.batting.runsScored.leagueTotals = leagueRunsScored;

        let leagueRbis = leagueData.batting.rbis.leagueTotals;
        leagueRbis.push(battingStats.rbis)
        leagueData.batting.rbis.leagueTotals = leagueRbis;

        let leagueWalks = leagueData.batting.walks.leagueTotals;
        leagueWalks.push(battingStats.walks)
        leagueData.batting.walks.leagueTotals = leagueWalks;

        let leagueIntentionalWalks = leagueData.batting.intentionalWalks.leagueTotals;
        leagueIntentionalWalks.push(battingStats.intentionalWalks)
        leagueData.batting.intentionalWalks.leagueTotals = leagueIntentionalWalks;

        let leagueStrikouts = leagueData.batting.strikeouts.leagueTotals;
        leagueStrikouts.push(battingStats.strikeouts)
        leagueData.batting.strikeouts.leagueTotals = leagueStrikouts;

        let leagueDoublePlay = leagueData.batting.groundIntoDoublePlay.leagueTotals;
        leagueDoublePlay.push(battingStats.groundIntoDoublePlay)
        leagueData.batting.groundIntoDoublePlay.leagueTotals = leagueDoublePlay;

        let leagueStolenBases = leagueData.batting.stolenBases.leagueTotals;
        leagueStolenBases.push(battingStats.stolenBases)
        leagueData.batting.stolenBases.leagueTotals = leagueStolenBases;

        let leagueCaughtStealing = leagueData.batting.caughtStealing.leagueTotals;
        leagueCaughtStealing.push(battingStats.caughtStealing)
        leagueData.batting.caughtStealing.leagueTotals = leagueCaughtStealing;

        let leagueBattingAverage = leagueData.batting.battingAverage.leagueTotals;
        leagueBattingAverage.push(battingStats.battingAverage)
        leagueData.batting.battingAverage.leagueTotals = leagueBattingAverage;

        let leagueOnBase = leagueData.batting.onBasePercentage.leagueTotals;
        leagueOnBase.push(battingStats.onBasePercentage)
        leagueData.batting.onBasePercentage.leagueTotals = leagueOnBase;

        let leagueSlugging = leagueData.batting.sluggingPercentage.leagueTotals;
        leagueSlugging.push(battingStats.sluggingPercentage)
        leagueData.batting.sluggingPercentage.leagueTotals = leagueSlugging;

        let leagueOps = leagueData.batting.onBasePlusSluggingPercentage.leagueTotals;
        leagueOps.push(battingStats.onBasePlusSluggingPercentage)
        leagueData.batting.onBasePlusSluggingPercentage.leagueTotals = leagueOps;

        let leagueIsolatedPower = leagueData.batting.isolatedPower.leagueTotals;
        leagueIsolatedPower.push(battingStats.isolatedPower)
        leagueData.batting.isolatedPower.leagueTotals = leagueIsolatedPower;

        let leagueBabip = leagueData.batting.battingAverageOnBallsInPlay.leagueTotals;
        leagueBabip.push(battingStats.battingAverageOnBallsInPlay)
        leagueData.batting.battingAverageOnBallsInPlay.leagueTotals = leagueBabip;

        let leagueWoba = leagueData.batting.weightedOnBaseAverage.leagueTotals;
        leagueWoba.push(battingStats.weightedOnBaseAverage)
        leagueData.batting.weightedOnBaseAverage.leagueTotals = leagueWoba;

        let leagueWraa = leagueData.batting.weightedRunsAboveAverage.leagueTotals;
        leagueWraa.push(battingStats.weightedRunsAboveAverage)
        leagueData.batting.weightedRunsAboveAverage.leagueTotals = leagueWraa;

        // runs per plate attempt scaled where average is 100
        // and is league and park adjusted
        let leagueWrcPlus = leagueData.batting.wRCPlus.leagueTotals;
        leagueWrcPlus.push(battingStats.wRCPlus)
        leagueData.batting.wRCPlus.leagueTotals = leagueWrcPlus

        let leagueWFBC = leagueData.batting.fastballRunsAboveAveragePer100.leagueTotals;
        leagueWFBC.push(battingStats.fastballRunsAboveAveragePer100)
        leagueData.batting.fastballRunsAboveAveragePer100.leagueTotals = leagueWFBC

        let leagueWSLC = leagueData.batting.sliderRunsAboveAveragePer100.leagueTotals;
        leagueWSLC.push(battingStats.sliderRunsAboveAveragePer100)
        leagueData.batting.sliderRunsAboveAveragePer100.leagueTotals = leagueWSLC

        let leagueWCTC = leagueData.batting.cutterRunsAboveAveragePer100.leagueTotals;
        leagueWCTC.push(battingStats.cutterRunsAboveAveragePer100)
        leagueData.batting.cutterRunsAboveAveragePer100.leagueTotals = leagueWCTC

        let leagueWCBC = leagueData.batting.curveballRunsAboveAveragePer100.leagueTotals;
        leagueWCBC.push(battingStats.curveballRunsAboveAveragePer100)
        leagueData.batting.curveballRunsAboveAveragePer100.leagueTotals = leagueWCBC

        let leagueWCHC = leagueData.batting.changeupRunsAboveAveragePer100.leagueTotals;
        leagueWCHC.push(battingStats.changeupRunsAboveAveragePer100)
        leagueData.batting.changeupRunsAboveAveragePer100.leagueTotals = leagueWCHC

        let leagueWSFC = leagueData.batting.splitFingerFastballRunsAboveAveragePer100.leagueTotals;
        leagueWSFC.push(battingStats.splitFingerFastballRunsAboveAveragePer100)
        leagueData.batting.splitFingerFastballRunsAboveAveragePer100.leagueTotals = leagueWSFC

        let leagueHHB = leagueData.batting.hardHitBallPercentage.leagueTotals;
        leagueHHB.push(battingStats.hardHitBallPercentage)
        leagueData.batting.hardHitBallPercentage.leagueTotals = leagueHHB;

        let leagueRD = leagueData.batting.runDifferential.leagueTotals;
        leagueRD.push(battingStats.runDifferential)
        leagueData.batting.runDifferential.leagueTotals = leagueRD;
      };

      // calculate remaining league metric data 
      for (const metric in leagueData.batting) {
        const metricKey = metric as keyof LeagueBattingStats;

        let leagueStatArray = leagueData.batting[metricKey].leagueTotals;

        const leagueStatRanking = leagueStatArray.toSorted((a: number, b: number) => a - b);
        const { average, correlation, stdDeviation } = analyzeLeagueData(metricKey, leagueStatArray, leagueWinsArray);

        leagueData.batting[metricKey] = {
          ...leagueData.batting[metricKey],
          rankedTotals: leagueStatRanking,
          leagueAverage: average,
          correlationToWins:  correlation,
          stdDeviation: stdDeviation,
        };

      }

      dispatch(setTeamCurrentBattingOverallData(teams));
      dispatch(setLeagueBattingData(leagueData.batting));

    };
    parseCsvData(buildMlbDataCsvFilePath('team-batting-current-full.csv'), processCurrentLeagueBattingData);

    const processLast14LeagueBattingData = (papaData: any) => {
      for (const teamData of papaData) {
        let timePeriod: MLBTimePeriod = 'last14';
        let battingSplit: BattingSplit = 'overall';
        let pitchingSplit: PitchingSplit = 'overall';
        const teamAbbr = parseString(teamData.Team) as MLB_TEAM_CODE;

        let runsAgainst = teams[teamAbbr].teamData[timePeriod].pitching[pitchingSplit].runsAllowed;
        let battingStats = formatPapaBattingStats(teamData, runsAgainst);

        let team = formatTeam(
          'batting',
          teamAbbr,
          timePeriod,
          battingSplit,
          battingStats,
          teams[teamAbbr],
        );

        teams[teamAbbr] = team;
      };

      dispatch(setTeamLast14BattingOverallData(teams));

    };
    parseCsvData(buildMlbDataCsvFilePath('team-batting-last14-full.csv'), processLast14LeagueBattingData);


    const processLast30LeagueBattingData = (papaData: any) => {
      for (const teamData of papaData) {
        let timePeriod: MLBTimePeriod = 'last30';
        let battingSplit: BattingSplit = 'overall';
        let pitchingSplit: PitchingSplit = 'overall';
        const teamAbbr = parseString(teamData.Team) as MLB_TEAM_CODE;

        let runsAgainst = teams[teamAbbr].teamData[timePeriod].pitching[pitchingSplit].runsAllowed;
        let battingStats = formatPapaBattingStats(teamData, runsAgainst);

        let team = formatTeam(
          'batting',
          teamAbbr,
          timePeriod,
          battingSplit,
          battingStats,
          teams[teamAbbr],
        );

        teams[teamAbbr] = team;
      };

      dispatch(setTeamLast30BattingOverallData(teams));

    };
    parseCsvData(buildMlbDataCsvFilePath('team-batting-last30-full.csv'), processLast30LeagueBattingData);

    
    // would be nice to make a reusable func here at some point that takes
    // papaData, timePeriod, and split into account.  need to be able to pass
    // extra params through papaparse for this
    const processCurrentPitcherOverallData = (papaData: any) => {

      for (const playerPapaData of papaData) {
        let timePeriod: MLBTimePeriod = 'current';
        let pitchingSplit: PitchingSplit = 'overall';
        let teamAbbr = parseString(playerPapaData.Team) as MLB_TEAM_CODE | '- - -';
        let pitcherId: number = playerPapaData.PlayerId;
        let playerName = parseString(playerPapaData.NameASCII)
        if (teamAbbr === '- - -') {
          teamAbbr = getTeamCodeForPitcher(playerName);
        }

        let pitcherStats = formatPapaPitchingStats(playerPapaData);
        let pitcher = formatPapaPitcher(playerName, teamAbbr, timePeriod, pitchingSplit, pitcherStats);
        let currentPitcherData = pitcher.playerData.current as PitchingSplits;

        pitcherIds.push(pitcherId);
        pitchers[pitcherId] = pitcher;

        let gamesPlayed = playerData.pitching.gamesPlayed.leagueTotals;
        gamesPlayed.push(currentPitcherData.overall.gamesPlayed)
        playerData.pitching.gamesPlayed.leagueTotals = gamesPlayed;

        let leagueWins = playerData.pitching.wins.leagueTotals;
        leagueWins.push(currentPitcherData.overall.wins)
        leagueWinsArray.push(currentPitcherData.overall.wins)
        playerData.pitching.wins.leagueTotals = leagueWins;

        let leagueLosses = playerData.pitching.losses.leagueTotals;
        leagueLosses.push(currentPitcherData.overall.losses)
        playerData.pitching.losses.leagueTotals = leagueLosses;

        let leagueSaves = playerData.pitching.saves.leagueTotals;
        leagueSaves.push(currentPitcherData.overall.saves)
        playerData.pitching.saves.leagueTotals = leagueSaves;

        let leagueInningsPitched = playerData.pitching.inningsPitched.leagueTotals;
        leagueInningsPitched.push(currentPitcherData.overall.inningsPitched)
        playerData.pitching.inningsPitched.leagueTotals = leagueInningsPitched;

        let leagueWar = playerData.pitching.war.leagueTotals;
        leagueWar.push(currentPitcherData.overall.war)
        playerData.pitching.war.leagueTotals = leagueWar;

        let leagueXfip = playerData.pitching.xfip.leagueTotals;
        leagueXfip.push(currentPitcherData.overall.xfip)
        playerData.pitching.xfip.leagueTotals = leagueXfip;

        let leagueEra = playerData.pitching.era.leagueTotals;
        leagueEra.push(currentPitcherData.overall.era)
        playerData.pitching.era.leagueTotals = leagueEra;

        let leagueLob = playerData.pitching.leftOnBasePercentage.leagueTotals;
        leagueLob.push(currentPitcherData.overall.leftOnBasePercentage)
        playerData.pitching.leftOnBasePercentage.leagueTotals = leagueLob;

        let leagueTbf = playerData.pitching.totalBattersFaced.leagueTotals;
        leagueTbf.push(currentPitcherData.overall.totalBattersFaced)
        playerData.pitching.totalBattersFaced.leagueTotals = leagueTbf;

        let leagueHr = playerData.pitching.homerunsAllowed.leagueTotals;
        leagueHr.push(currentPitcherData.overall.homerunsAllowed)
        playerData.pitching.homerunsAllowed.leagueTotals = leagueHr;

        let leagueRuns = playerData.pitching.runsAllowed.leagueTotals;
        leagueRuns.push(currentPitcherData.overall.runsAllowed)
        playerData.pitching.runsAllowed.leagueTotals = leagueRuns;

        let leagueWhip = playerData.pitching.whip.leagueTotals;
        leagueWhip.push(currentPitcherData.overall.whip)
        playerData.pitching.whip.leagueTotals = leagueWhip;

        let leagueAvg = playerData.pitching.battingAverageAgainst.leagueTotals;
        leagueAvg.push(currentPitcherData.overall.battingAverageAgainst)
        playerData.pitching.battingAverageAgainst.leagueTotals = leagueAvg;

        let leagueClutch = playerData.pitching.clutch.leagueTotals;
        leagueClutch.push(currentPitcherData.overall.clutch)
        playerData.pitching.clutch.leagueTotals = leagueClutch;

        // pitch specific data
        let leagueFBPct = playerData.pitching.pitches.fastball.percentageThrow.leagueTotals;
        leagueFBPct.push(currentPitcherData.overall.pitches.fastball.percentageThrow)
        playerData.pitching.pitches.fastball.percentageThrow.leagueTotals = leagueFBPct;

        let leagueFBVel = playerData.pitching.pitches.fastball.averageVelocity.leagueTotals;
        leagueFBVel.push(currentPitcherData.overall.pitches.fastball.averageVelocity)
        playerData.pitching.pitches.fastball.averageVelocity.leagueTotals = leagueFBVel;

        let leagueSLPct = playerData.pitching.pitches.slider.percentageThrow.leagueTotals;
        leagueSLPct.push(currentPitcherData.overall.pitches.slider.percentageThrow)
        playerData.pitching.pitches.slider.percentageThrow.leagueTotals = leagueSLPct;

        let leagueSLVel = playerData.pitching.pitches.slider.averageVelocity.leagueTotals;
        leagueSLVel.push(currentPitcherData.overall.pitches.slider.averageVelocity)
        playerData.pitching.pitches.slider.averageVelocity.leagueTotals = leagueSLVel;

        let leagueCTPct = playerData.pitching.pitches.cutter.percentageThrow.leagueTotals;
        leagueCTPct.push(currentPitcherData.overall.pitches.cutter.percentageThrow)
        playerData.pitching.pitches.cutter.percentageThrow.leagueTotals = leagueCTPct;

        let leagueCTVel = playerData.pitching.pitches.cutter.averageVelocity.leagueTotals;
        leagueCTVel.push(currentPitcherData.overall.pitches.cutter.averageVelocity)
        playerData.pitching.pitches.cutter.averageVelocity.leagueTotals = leagueCTVel;

        let leagueCBPct = playerData.pitching.pitches.curveball.percentageThrow.leagueTotals;
        leagueCBPct.push(currentPitcherData.overall.pitches.curveball.percentageThrow)
        playerData.pitching.pitches.curveball.percentageThrow.leagueTotals = leagueCBPct;

        let leagueCBVel = playerData.pitching.pitches.curveball.averageVelocity.leagueTotals;
        leagueCBVel.push(currentPitcherData.overall.pitches.curveball.averageVelocity)
        playerData.pitching.pitches.curveball.averageVelocity.leagueTotals = leagueCBVel;

        let leagueCHPct = playerData.pitching.pitches.changeup.percentageThrow.leagueTotals;
        leagueCHPct.push(currentPitcherData.overall.pitches.changeup.percentageThrow)
        playerData.pitching.pitches.changeup.percentageThrow.leagueTotals = leagueCHPct;

        let leagueCHVel = playerData.pitching.pitches.changeup.averageVelocity.leagueTotals;
        leagueCHVel.push(currentPitcherData.overall.pitches.changeup.averageVelocity)
        playerData.pitching.pitches.changeup.averageVelocity.leagueTotals = leagueCHVel;
      };

      // calculate remaining league metric data 
      for (const metric in playerData.pitching) {
        const metricKey = metric as keyof LeaguePitchingStats;

        let leagueStatArray: number[] = [];

        if (metricKey === 'pitches') {
          let pitchesLeagueInfo = {};

          // should loop through pitch keys in case more pitch type want to be included
          // but for now it is faster to focus on specific common pitches
          // fastball
          leagueStatArray = playerData.pitching.pitches.fastball.percentageThrow.leagueTotals;
          let leagueStatRanking = leagueStatArray.toSorted((a: number, b: number) => a - b);
          let average = getAverage(leagueStatArray.filter(val => val !== 0));
          playerData.pitching.pitches.fastball.percentageThrow = {
            ...playerData.pitching.pitches.fastball.percentageThrow,
            rankedTotals: leagueStatRanking,
            leagueAverage: average,
            correlationToWins:  0,
            stdDeviation: 0,
          };

          leagueStatArray = playerData.pitching.pitches.fastball.averageVelocity.leagueTotals;
          leagueStatRanking = leagueStatArray.toSorted((a: number, b: number) => a - b);
          average = getAverage(leagueStatArray.filter(val => val !== 0));
          playerData.pitching.pitches.fastball.averageVelocity = {
            ...playerData.pitching.pitches.fastball.averageVelocity,
            rankedTotals: leagueStatRanking,
            leagueAverage: average,
            correlationToWins:  0,
            stdDeviation: 0,
          };

          // slider
          leagueStatArray = playerData.pitching.pitches.slider.percentageThrow.leagueTotals;
          leagueStatRanking = leagueStatArray.toSorted((a: number, b: number) => a - b);
          average = getAverage(leagueStatArray.filter(val => val !== 0));
          playerData.pitching.pitches.slider.percentageThrow = {
            ...playerData.pitching.pitches.slider.percentageThrow,
            rankedTotals: leagueStatRanking,
            leagueAverage: average,
            correlationToWins:  0,
            stdDeviation: 0,
          };

          leagueStatArray = playerData.pitching.pitches.slider.averageVelocity.leagueTotals;
          leagueStatRanking = leagueStatArray.toSorted((a: number, b: number) => a - b);
          average = getAverage(leagueStatArray.filter(val => val !== 0));
          playerData.pitching.pitches.slider.averageVelocity = {
            ...playerData.pitching.pitches.slider.averageVelocity,
            rankedTotals: leagueStatRanking,
            leagueAverage: average,
            correlationToWins:  0,
            stdDeviation: 0,
          };

          // slider
          leagueStatArray = playerData.pitching.pitches.cutter.percentageThrow.leagueTotals;
          leagueStatRanking = leagueStatArray.toSorted((a: number, b: number) => a - b);
          average = getAverage(leagueStatArray.filter(val => val !== 0));
          playerData.pitching.pitches.cutter.percentageThrow = {
            ...playerData.pitching.pitches.cutter.percentageThrow,
            rankedTotals: leagueStatRanking,
            leagueAverage: average,
            correlationToWins:  0,
            stdDeviation: 0,
          };

          leagueStatArray = playerData.pitching.pitches.cutter.averageVelocity.leagueTotals;
          leagueStatRanking = leagueStatArray.toSorted((a: number, b: number) => a - b);
          average = getAverage(leagueStatArray.filter(val => val !== 0));
          playerData.pitching.pitches.cutter.averageVelocity = {
            ...playerData.pitching.pitches.cutter.averageVelocity,
            rankedTotals: leagueStatRanking,
            leagueAverage: average,
            correlationToWins:  0,
            stdDeviation: 0,
          };

          // curveball
          leagueStatArray = playerData.pitching.pitches.curveball.percentageThrow.leagueTotals;
          leagueStatRanking = leagueStatArray.toSorted((a: number, b: number) => a - b);
          average = getAverage(leagueStatArray.filter(val => val !== 0));
          playerData.pitching.pitches.curveball.percentageThrow = {
            ...playerData.pitching.pitches.curveball.percentageThrow,
            rankedTotals: leagueStatRanking,
            leagueAverage: average,
            correlationToWins:  0,
            stdDeviation: 0,
          };

          leagueStatArray = playerData.pitching.pitches.curveball.averageVelocity.leagueTotals;
          leagueStatRanking = leagueStatArray.toSorted((a: number, b: number) => a - b);
          average = getAverage(leagueStatArray.filter(val => val !== 0));
          playerData.pitching.pitches.curveball.averageVelocity = {
            ...playerData.pitching.pitches.curveball.averageVelocity,
            rankedTotals: leagueStatRanking,
            leagueAverage: average,
            correlationToWins:  0,
            stdDeviation: 0,
          };

          // changeup
          leagueStatArray = playerData.pitching.pitches.changeup.percentageThrow.leagueTotals;
          leagueStatRanking = leagueStatArray.toSorted((a: number, b: number) => a - b);
          average = getAverage(leagueStatArray.filter(val => val !== 0));
          playerData.pitching.pitches.changeup.percentageThrow = {
            ...playerData.pitching.pitches.changeup.percentageThrow,
            rankedTotals: leagueStatRanking,
            leagueAverage: average,
            correlationToWins:  0,
            stdDeviation: 0,
          };

          leagueStatArray = playerData.pitching.pitches.changeup.averageVelocity.leagueTotals;
          leagueStatRanking = leagueStatArray.toSorted((a: number, b: number) => a - b);
          average = getAverage(leagueStatArray.filter(val => val !== 0));
          playerData.pitching.pitches.changeup.averageVelocity = {
            ...playerData.pitching.pitches.changeup.averageVelocity,
            rankedTotals: leagueStatRanking,
            leagueAverage: average,
            correlationToWins:  0,
            stdDeviation: 0,
          };

        } else {
          leagueStatArray = playerData.pitching[metricKey].leagueTotals;
          const leagueStatRanking = leagueStatArray.toSorted((a: number, b: number) => a - b);
          const average = getAverage(leagueStatArray.filter(val => val !== 0));

          playerData.pitching[metricKey] = {
            ...playerData.pitching[metricKey],
            rankedTotals: leagueStatRanking,
            leagueAverage: average,
            correlationToWins:  0,
            stdDeviation: 0,
          };

        };
      };

      dispatch(setPitchersCurrentOverallData(pitchers));
      dispatch(setPitcherIds(pitcherIds));
      dispatch(setPlayerPitchingCurrentOverallData(playerData.pitching));

    };
    parseCsvData(buildMlbDataCsvFilePath('player-pitching-full.csv'), processCurrentPitcherOverallData);


    const processCurrentBatterOverallData = (papaData: any) => {
      for (const playerPapaData of papaData) {
        let timePeriod: MLBTimePeriod = 'current';
        let battingSplit: BattingSplit = 'overall';
        let teamAbbr = parseString(playerPapaData.Team) as MLB_TEAM_CODE | '- - -';
        let playerName = parseString(playerPapaData.NameASCII);
        let batterId: number = playerPapaData.PlayerId;

        let freeAgentsList = ['Kevin Pillar'];
        if (freeAgentsList.includes(playerName)) continue;

        if (teamAbbr === '- - -') {
          // console.log('============================================')
          // console.log(`${playerData.NameASCII} is listed with: ${teamNameShort}`)
          teamAbbr = getTeamCodeForBatter(playerName);
        }

        let battingStats = formatPapaBattingStats(playerPapaData);
        let batter = formatPapaBatter(
          playerName,
          teamAbbr,
          timePeriod,
          battingSplit,
          battingStats,
        )
        let currentBatterData = batter.playerData.current as BattingSplits;

        batterIds.push(batterId);
        batters[batterId] = batter;

        let leagueAtBats = playerData.batting.atBats.leagueTotals;
        leagueAtBats.push(currentBatterData.overall.atBats)
        playerData.batting.atBats.leagueTotals = leagueAtBats;

        let leaguePlateAttempts = playerData.batting.plateAttempts.leagueTotals;
        leaguePlateAttempts.push(currentBatterData.overall.plateAttempts)
        playerData.batting.plateAttempts.leagueTotals = leaguePlateAttempts;

        let leagueHits = playerData.batting.hits.leagueTotals;
        leagueHits.push(currentBatterData.overall.hits)
        playerData.batting.hits.leagueTotals = leagueHits;

        let leagueHomeruns = playerData.batting.homeruns.leagueTotals;
        leagueHomeruns.push(currentBatterData.overall.homeruns)
        playerData.batting.homeruns.leagueTotals = leagueHomeruns;

        let leagueRunsScored = playerData.batting.runsScored.leagueTotals;
        leagueRunsScored.push(currentBatterData.overall.runsScored)
        playerData.batting.runsScored.leagueTotals = leagueRunsScored;

        let leagueRbis = playerData.batting.rbis.leagueTotals;
        leagueRbis.push(currentBatterData.overall.rbis)
        playerData.batting.rbis.leagueTotals = leagueRbis;

        let leagueWalks = playerData.batting.walks.leagueTotals;
        leagueWalks.push(currentBatterData.overall.walks)
        playerData.batting.walks.leagueTotals = leagueWalks;

        let leagueIntentionalWalks = playerData.batting.intentionalWalks.leagueTotals;
        leagueIntentionalWalks.push(currentBatterData.overall.intentionalWalks)
        playerData.batting.intentionalWalks.leagueTotals = leagueIntentionalWalks;

        let leagueStrikouts = playerData.batting.strikeouts.leagueTotals;
        leagueStrikouts.push(currentBatterData.overall.strikeouts)
        playerData.batting.strikeouts.leagueTotals = leagueStrikouts;

        let leagueDoublePlay = playerData.batting.groundIntoDoublePlay.leagueTotals;
        leagueDoublePlay.push(currentBatterData.overall.groundIntoDoublePlay)
        playerData.batting.groundIntoDoublePlay.leagueTotals = leagueDoublePlay;

        let leagueStolenBases = playerData.batting.stolenBases.leagueTotals;
        leagueStolenBases.push(currentBatterData.overall.stolenBases)
        playerData.batting.stolenBases.leagueTotals = leagueStolenBases;

        let leagueCaughtStealing = playerData.batting.caughtStealing.leagueTotals;
        leagueCaughtStealing.push(currentBatterData.overall.caughtStealing)
        playerData.batting.caughtStealing.leagueTotals = leagueCaughtStealing;

        let leagueBattingAverage = playerData.batting.battingAverage.leagueTotals;
        leagueBattingAverage.push(currentBatterData.overall.battingAverage)
        playerData.batting.battingAverage.leagueTotals = leagueBattingAverage;

        let leagueOnBase = playerData.batting.onBasePercentage.leagueTotals;
        leagueOnBase.push(currentBatterData.overall.onBasePercentage)
        playerData.batting.onBasePercentage.leagueTotals = leagueOnBase;

        let leagueSlugging = playerData.batting.sluggingPercentage.leagueTotals;
        leagueSlugging.push(currentBatterData.overall.sluggingPercentage)
        playerData.batting.sluggingPercentage.leagueTotals = leagueSlugging;

        let leagueOps = playerData.batting.onBasePlusSluggingPercentage.leagueTotals;
        leagueOps.push(currentBatterData.overall.onBasePlusSluggingPercentage)
        playerData.batting.onBasePlusSluggingPercentage.leagueTotals = leagueOps;

        let leagueIsolatedPower = playerData.batting.isolatedPower.leagueTotals;
        leagueIsolatedPower.push(currentBatterData.overall.isolatedPower)
        playerData.batting.isolatedPower.leagueTotals = leagueIsolatedPower;

        let leagueBabip = playerData.batting.battingAverageOnBallsInPlay.leagueTotals;
        leagueBabip.push(currentBatterData.overall.battingAverageOnBallsInPlay)
        playerData.batting.battingAverageOnBallsInPlay.leagueTotals = leagueBabip;

        let leagueWoba = playerData.batting.weightedOnBaseAverage.leagueTotals;
        leagueWoba.push(currentBatterData.overall.weightedOnBaseAverage)
        playerData.batting.weightedOnBaseAverage.leagueTotals = leagueWoba;

        let leagueWraa = playerData.batting.weightedRunsAboveAverage.leagueTotals;
        leagueWraa.push(currentBatterData.overall.weightedRunsAboveAverage)
        playerData.batting.weightedRunsAboveAverage.leagueTotals = leagueWraa;

        // runs per plate attempt scaled where average is 100
        // and is league and park adjusted
        let leagueWrcPlus = playerData.batting.wRCPlus.leagueTotals;
        leagueWrcPlus.push(currentBatterData.overall.wRCPlus)
        playerData.batting.wRCPlus.leagueTotals = leagueWrcPlus

        let leagueWFBC = playerData.batting.fastballRunsAboveAveragePer100.leagueTotals;
        leagueWFBC.push(currentBatterData.overall.fastballRunsAboveAveragePer100)
        playerData.batting.fastballRunsAboveAveragePer100.leagueTotals = leagueWFBC

        let leagueWSLC = playerData.batting.sliderRunsAboveAveragePer100.leagueTotals;
        leagueWSLC.push(currentBatterData.overall.sliderRunsAboveAveragePer100)
        playerData.batting.sliderRunsAboveAveragePer100.leagueTotals = leagueWSLC

        let leagueWCTC = playerData.batting.cutterRunsAboveAveragePer100.leagueTotals;
        leagueWCTC.push(currentBatterData.overall.cutterRunsAboveAveragePer100)
        playerData.batting.cutterRunsAboveAveragePer100.leagueTotals = leagueWCTC

        let leagueWCBC = playerData.batting.curveballRunsAboveAveragePer100.leagueTotals;
        leagueWCBC.push(currentBatterData.overall.curveballRunsAboveAveragePer100)
        playerData.batting.curveballRunsAboveAveragePer100.leagueTotals = leagueWCBC

        let leagueWCHC = playerData.batting.changeupRunsAboveAveragePer100.leagueTotals;
        leagueWCHC.push(currentBatterData.overall.changeupRunsAboveAveragePer100)
        playerData.batting.changeupRunsAboveAveragePer100.leagueTotals = leagueWCHC

        let leagueWSFC = playerData.batting.splitFingerFastballRunsAboveAveragePer100.leagueTotals;
        leagueWSFC.push(currentBatterData.overall.splitFingerFastballRunsAboveAveragePer100)
        playerData.batting.splitFingerFastballRunsAboveAveragePer100.leagueTotals = leagueWSFC

        let leagueHHB = playerData.batting.hardHitBallPercentage.leagueTotals;
        leagueHHB.push(currentBatterData.overall.hardHitBallPercentage)
        playerData.batting.hardHitBallPercentage.leagueTotals = leagueHHB;
      };

      // calculate remaining league metric data 
      for (const metric in playerData.batting) {
        const metricKey = metric as keyof LeagueBattingStats;

        let leagueStatArray = playerData.batting[metricKey].leagueTotals;

        const leagueStatRanking = leagueStatArray.toSorted((a: number, b: number) => a - b);
        const average = getAverage(leagueStatArray.filter(val => val !== 0));

        playerData.batting[metricKey] = {
          ...playerData.batting[metricKey],
          rankedTotals: leagueStatRanking,
          leagueAverage: average,
          correlationToWins:  0,
          stdDeviation: 0,
        };

      }
  
      dispatch(setBattersCurrentOverallData(batters));
      dispatch(setBatterIds(batterIds));
      dispatch(setPlayerBattingCurrentOverallData(playerData.batting));
  
    };
    parseCsvData(buildMlbDataCsvFilePath('player-batting-full.csv'), processCurrentBatterOverallData);
  }
  
};

export default mlbDataSlice.reducer;


/**
 * consider using: https://statsapi.mlb.com/api/v1/schedule?sportId=1
 * to get mlb competitions, would need to store the gamePk
 * 
 * then use that in: https://statsapi.mlb.com//api/v1.1/game/746614/feed/live
 * gamePk = 746614
 * and that endpoint has access to:
 * gameData.weather: show's brief conditions
 * gameData.venue: includes address and fieldInfo
 * gameData.probablePitchers: { away: { fullName: 'foo bar' } }
 * 
 * gameData.players: is an object with all players (both teams) by player Id. so storing this to look up info would be necessary
 */


/**
 * league average
 * correlation coefficient
 * stat rank?
 */