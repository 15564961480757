import CircularProgress from '@mui/material/CircularProgress';
import Box from "@mui/material/Box";
// import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';

// const createStyles = makeStyles()(() => ({
//   circle: {
//     strokeLinecap: "round"
//   }
// }));

interface CircularPercentProgressProps {
  currentValue: number;
  size?: string;
};

const CircularPercentProgress = ({ currentValue, size = '35px' }: CircularPercentProgressProps) => {
  // const { classes } = createStyles();

  const displayValue = Math.round(currentValue * 100);
  
  let progressColor: string;
  if (displayValue < 55) {
   progressColor = 'red';
  } else if (displayValue >= 55 && displayValue < 65) {
    progressColor = 'orange';
  } else if (displayValue >= 65 && displayValue < 75) {
    progressColor = 'yellow';
  } else {
    progressColor="green"
  };

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        value={displayValue}
        style={{
          width: size,
          height: size,
          borderRadius: "100%",
          boxShadow: "inset 0 0 0px 1.5px lightGray",
          backgroundColor: "transparent",
        }}
        thickness={2}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${displayValue}%`}</Typography>
      </Box>
    </Box> 
  )
};

export default CircularPercentProgress;