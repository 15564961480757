import { HighOrLow } from "config/mlb";

/**
 * 
 * @param leagueRankArray an array of numbers to check index placement
 * @param metricValue the number value
 * @param highOrLow is it better if the metric has a lower value or higher
 * @returns 
 */
export const getLeagueRankForMetric = (
  leagueRankArray: number[],
  metricValue: number,
  // instead of using higher or lower here and having to spray that around the
  // app, we could add this to the METRIC_MAP as a config option, however, there
  // needs to be a user case for none since some metrics don't have a
  // "better is higher or lower"
  highOrLow: HighOrLow,
) => {
  if (highOrLow === 'higher') {
    let leagueRanksList = [...leagueRankArray]
    return leagueRanksList.reverse().indexOf(metricValue) + 1;
  } else {
    return leagueRankArray.indexOf(metricValue) + 1;
  }
  
};