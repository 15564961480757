export type OverOrUnder = 'over' | 'under';

export type OpenOrCurrent = 'open' | 'current';

export type PickGrade = 'A+' | 'A' | 'A-' | 'B+' | 'B' | 'B-' | 'C+' | 'C' | 'C-' | 'D+' | 'D' | 'D-' | 'F';

export enum PickType {
  MONEYLINE = 'moneyline',
  SPREAD = 'spread',
  OVERUNDER = 'over-under',
};

// make this entity a larger config object like assets
export enum PickEntity {
  SL = 'SL',
  ODDSMAKERS = 'OM',
  BET_WISER = 'BW',
  PUBLIC = 'PUB',
  MONEY_BET = 'BETS',
}

export enum CompetitionStatus {
  NOT_STARTED = 'Not Started',
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed',
  POSTPONED = 'Postponed',
  NONE = '',
};