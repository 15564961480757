import { useSelector } from 'react-redux';
import { selectLeagueCompetitionsForDate, selectBettingOddsByCompetitionIds, selectBettingPicksByCompetitionIds } from 'reducers/competitionsReducer';
import { BettingOdds, BettingPicks, CompetitionData } from 'reducers/types/competitionTypes';
import { LeagueCode } from 'reducers/types/sharedTypes';
import { formatCompetitionData } from 'components/helpers';

const useCompetitionDatasForLeagueAndDate = (selectedLeagueCode: LeagueCode, selectedDate: string): CompetitionData[] | null => {
  const competitionsForSelectedDate = useSelector(selectLeagueCompetitionsForDate(selectedLeagueCode, selectedDate));
  const competitionIdsForSelectedDate = Object.keys(competitionsForSelectedDate).map((k) => Number(k));

  const oddsForCompetitions = useSelector(selectBettingOddsByCompetitionIds(competitionIdsForSelectedDate));
  const picksForCompetitions = useSelector(selectBettingPicksByCompetitionIds(competitionIdsForSelectedDate));
  
  let competitionRows: CompetitionData[] = [];
  for (const compId of competitionIdsForSelectedDate) {
    let competition = competitionsForSelectedDate[compId];

    let bettingOdds: BettingOdds = {};
    for (const oddsId in oddsForCompetitions) {
      if (oddsForCompetitions[oddsId].competitionId === compId) {
        bettingOdds[oddsId] = oddsForCompetitions[oddsId];
      }
    };

    let bettingPicks: BettingPicks = {};
    for (const pickId in picksForCompetitions) {
      if (picksForCompetitions[pickId].competitionId === compId) {
        bettingPicks[pickId] = picksForCompetitions[pickId];
      }
    };

    let competitionData = formatCompetitionData(competition, bettingOdds, bettingPicks);
    competitionRows.push(competitionData);
  }

  return competitionRows;
}

export default useCompetitionDatasForLeagueAndDate;