import { LeagueCode } from "reducers/types/sharedTypes";
import SportsOutlinedIcon from '@mui/icons-material/SportsOutlined';
import SportsBaseballOutlinedIcon from '@mui/icons-material/SportsBaseballOutlined';
import SportsBasketballOutlinedIcon from '@mui/icons-material/SportsBasketballOutlined';
import SportsHockeyOutlinedIcon from '@mui/icons-material/SportsHockeyOutlined';
import SportsFootballOutlinedIcon from '@mui/icons-material/SportsFootballOutlined';

export const getLeagueIcon = (leagueCode: LeagueCode) => {
  switch(leagueCode) {
    case LeagueCode.MLB:
      return <SportsBaseballOutlinedIcon />;

    case LeagueCode.NCAAM:
      return <SportsBasketballOutlinedIcon />;

    case LeagueCode.NCAAF:
      return <SportsFootballOutlinedIcon />;

    case LeagueCode.NBA:
      return <SportsBasketballOutlinedIcon />;

    case LeagueCode.NHL:
      return <SportsHockeyOutlinedIcon />;

    case LeagueCode.NFL:
      return <SportsFootballOutlinedIcon />;

    default:
      return <SportsOutlinedIcon />;
  }
}