import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import { Scorecard } from "../scorePlacard/scorecard";
import Typography from "@mui/material/Typography";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import { CompetitionStatus } from "config/competitions";
import { determineSpreadWinner, gameWinner, openedAndRemainedSpreadFavorite, setGameStatus } from "components/helpers";
import { Competition as CompetitionType, CompetitionOddsByType, CompetitionPicksByType, HomeOrAway, SpreadOdds } from 'reducers/types/competitionTypes';
import './competition.css';
import { LeagueCode } from "reducers/types/sharedTypes";
import { DividerHeader } from "components/library";
import { CompetitionModal } from "../competitionModal";
import { useModalLauncher } from "context/ModalContext";
import useBestCompetitionPick from "../useBestCompetitionPick";
import useBestCompetitionOdds from "../useBestCompetitionOdds";
import { ScorePlacard } from "../scorePlacard";
import { OddOrPickPlacard } from "../oddOrPickPlacard";
import { isNull } from "lodash";

export interface CompetitionProps {
  selectedLeagueCode: LeagueCode;
  competition: CompetitionType;
  competitionOddsData: CompetitionOddsByType;
  competitionPicksData: CompetitionPicksByType;
};

const Competition = ({ selectedLeagueCode, competition, competitionOddsData, competitionPicksData }: CompetitionProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { openModal } = useModalLauncher()

  let awayFinalScore = competition.resultAwayScore;
  let homeFinalScore = competition.resultHomeScore;


  // let competitionStatus = setGameStatus(
  //   competition.date,
  //   competition.time,
  //   awayFinalScore,
  //   homeFinalScore
  // );

  let openAwaySpreadOdds = competitionOddsData.spreadOdds.find(o => o.openOrCurrent === 'open' && o.homeOrAway === 'away');
  let currentAwaySpreadOdds = competitionOddsData.spreadOdds.find(o => o.openOrCurrent === 'current' && o.homeOrAway === 'away');
  
  // let openHomeSpreadOdds = competitionOddsData.spreadOdds.find(o => o.openOrCurrent === 'open' && o.homeOrAway === 'home');
  let currentHomeSpreadOdds = competitionOddsData.spreadOdds.find(o => o.openOrCurrent === 'current' && o.homeOrAway === 'home');


  let isAwaySpreadOddsBestPick = false;
  let spreadWinner: HomeOrAway | undefined;
  let insightOne = '';

  if (openAwaySpreadOdds && currentAwaySpreadOdds && currentHomeSpreadOdds) {
    isAwaySpreadOddsBestPick = currentAwaySpreadOdds.percentage > currentHomeSpreadOdds.percentage;
    insightOne = openedAndRemainedSpreadFavorite(openAwaySpreadOdds, currentAwaySpreadOdds);
  }


  const compHasWinner = competition.resultAwayScore + competition.resultHomeScore !== 0 && competition.resultAwayScore !== competition.resultHomeScore;

  if (compHasWinner && openAwaySpreadOdds) {
    let isAwaySpreadWinner = determineSpreadWinner(openAwaySpreadOdds.homeOrAway, openAwaySpreadOdds.spreadValue, awayFinalScore, homeFinalScore);
    spreadWinner = isAwaySpreadWinner ? 'away' : 'home';
  }

  let bestOdd = useBestCompetitionOdds(competition.id);
  let bestPick = useBestCompetitionPick(competition.id);

  // console.log('comp away', competition.awayTeamCode)
  // console.log('comp home', competition.homeTeamCode)

  let isOddBestBet = bestOdd.percentage > (bestPick.percentage ? bestPick.percentage : 0)  

  /**
   * 
   * 3: think about the insights section some more.
   * 
   * - reminder, this 'Comp component can be plugged into analyzer view. Analyzer
   *   should really just turn into a competition view and be called that when clicked from
   *   a row, but maintain a standalone tool link
   * 
   * 4. sticky time blocks and sticky date
   * 
   * 5. log note for adding competition highlights for the day
   *    - it can show all the "Best" picks for current day and analysis of picks for the previous
   *      day like showing how many home teams were favorites and won kind of thing.
   * 
   * 6. consider a dynamic background fill behind the team names that looks like the header
   *    orange angly thing but overlapps similar to how odds shark does thie bars
   *    https://www.oddsshark.com/nfl/houston-chicago-odds-august-1-2024-2160374
   */

  return (
    <Grid
      container
      columnSpacing={1}
      sx={{ml: isMobileScreen ? 0 : 20, mr: isMobileScreen ? 0 : 20, boxShadow: 5, position: 'relative' }}
    >
      <Grid
        xs={12}
        sx={{
          borderBottom: '1px solid lightGrey',
          pt: 0.5,
          pb: 0.5,
          position: 'sticky',
          top: isMobileScreen ? 50 : 40,
          zIndex: 10,
          background: 'white'
        }}
      >
        <Typography variant="h5">{competition.time}</Typography>
      </Grid>

      <ScorePlacard selectedLeagueCode={selectedLeagueCode} competition={competition} />

      <Grid xs={12}>
        <DividerHeader textAlign="center" fullWidth={false}><p>HIGHLIGHTS</p></DividerHeader>
      </Grid>

      { !isNull(bestOdd.percentage) || !isNull(bestPick.percentage) && (<OddOrPickPlacard
        leftItem={bestOdd}
        leftItemType="odd"
        leftIsFeatured={isOddBestBet}
        leftItemHeader="BEST ODDS"
        rightItem={bestPick}
        rightItemType="pick"
        rightIsFeatured={!isOddBestBet}
        rightItemHeader="BEST PICK"
      />)}

      <Grid container xs={12} sx={{ mt: 2, mb: 2 }}>

        <Grid xs={12} sx={{m: 2}}>
          <Typography variant="body2">{insightOne}</Typography>
        </Grid>

        <Grid xs={12} sx={{m: 2}}>
          <Button
            disableRipple
            fullWidth
            sx={{
              minHeight: '64px',
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
            onClick={() =>
              openModal(
                <CompetitionModal
                  competitionId={competition.id}
                  selectedLeagueCode={selectedLeagueCode}
                />,
                { headerTitle: `${competition.date} - ${competition.time}` }
              )}
          >
            <Typography variant="button" sx={{mr: 0.5}}>{competition.awayTeamCode}</Typography>
            <Typography variant="button" sx={{fontSize: '0.5rem'}}>vs</Typography>
            <Typography variant="button" sx={{ml: 0.5}}>{`${competition.homeTeamCode} - Details`}</Typography>
          </Button>
        </Grid>

      </Grid>

    </Grid>
  )
};

export default Competition;