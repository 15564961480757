import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';

export interface NavLinkExternalProps {
  href: string;
  linkText: string;
  children: JSX.Element;
};

const NavLinkExternal = ({ href, linkText, children }: NavLinkExternalProps) => {

  return (
    <ListItem
      key="weather-link"
      component="a"
      href={href}
      rel='noopener noreferrer'
      target="_blank"
      disableGutters
      divider
      sx={{
        p: 2,
        '&: hover': {
          textDecoration: 'none',
           backgroundColor: 'rgba(0, 0, 0, 0.04)',
        }
      }}
    >
      <ListItemIcon>
        {children}
      </ListItemIcon>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ListItemText primary={linkText} />
        
        <ListItemIcon sx={{ ml: 1}}>
          <LaunchOutlinedIcon />
        </ListItemIcon>
      </Box>
    </ListItem>
  )
};

export default NavLinkExternal;