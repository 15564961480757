import { OverOrUnder, PickEntity, PickType } from 'config/competitions';
import { useSelector } from 'react-redux';
import { selectCurrentBettingPicksForCompetition } from 'reducers/competitionsReducer';
import { HomeOrAway, MoneylinePick, OverUnderPick, SpreadPick } from 'reducers/types/competitionTypes';

/**
 * picks are very subjective. Some entities believe in showing the greatest disparity in their sim vs consensus
 * and can often recommend "best picks" and the "best value pick", not necessarily the most likely to succeed pick
 * 
 * logic below will look at all pick options and try to determin the most likely to succeed pick
 */
const useBestCompetitionPick = (competitionId: number): SpreadPick | MoneylinePick | OverUnderPick => {
  const bettingPicksForCompetition = useSelector(selectCurrentBettingPicksForCompetition(competitionId));
  return [...bettingPicksForCompetition.spreadPicks, ...bettingPicksForCompetition.moneylinePicks, ...bettingPicksForCompetition.overUnderPicks]
  .filter((pick: SpreadPick | MoneylinePick | OverUnderPick) => pick.entity !== PickEntity.PUBLIC && pick.entity !== PickEntity.MONEY_BET)
  .reduce(
    ( max: SpreadPick | MoneylinePick | OverUnderPick,
      pick: SpreadPick | MoneylinePick | OverUnderPick
    ) => {
      if (pick.percentage && max.percentage) {
        
        // logic for the mosty likely to succeed pick
        // TODO: Refactor and clean up
        if (pick.percentage < 0.50) {
          if (pick.pickType === PickType.SPREAD) {
            let pickData = {
              ...pick,
              percentage: 1 - pick.percentage,
              homeOrAway: pick.homeOrAway === 'away' ? 'home' : 'away' as HomeOrAway,
              // get the inverse of the current spread value
              spreadValue: -pick.spreadValue
            }
            return max.percentage > Number(pickData.percentage) ? max : pickData;
          }

          if (pick.pickType === PickType.MONEYLINE) {
            let pickData = {
              ...pick,
              percentage: 1 - pick.percentage,
              homeOrAway: pick.homeOrAway === 'away' ? 'home' : 'away' as HomeOrAway,
            }
            return max.percentage > Number(pickData.percentage) ? max : pickData;
          }

          if (pick.pickType === PickType.OVERUNDER) {
            let pickData = {
              ...pick,
              percentage: 1 - pick.percentage,
              overOrUnder: pick.overOrUnder === 'over' ? 'under' : 'over' as OverOrUnder,
            }
            return max.percentage > Number(pickData.percentage) ? max : pickData;
          }

        };

        return max.percentage > Number(pick.percentage) ? max : pick;

      } else {
        return max;
      }
      
  });
}

export default useBestCompetitionPick;
