import { Dispatch, SetStateAction } from "react";
import { useSelector } from 'react-redux';
import { PitchingStats, LeaguePitchingStats, PitchingSplit, MLBTimePeriod } from 'reducers/types/mlbTypes';
import { getZScore, truncateMetric } from 'components/helpers';

import { HighOrLow, MLB_METRIC_MAP, MLB_TEAM_CODE } from 'config/mlb';

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Typography from '@mui/material/Typography';


import {
  selectTeamPitchingData,
  selectLeaguePitchingData,
} from 'reducers/mlbDataReducer';

import MetricRow from './MetricRow';

export interface PitchingMetricsProps {
  awayTeam: MLB_TEAM_CODE;
  homeTeam: MLB_TEAM_CODE;
  timePeriod: MLBTimePeriod;
  pitchingSplit: PitchingSplit;
  hideUncorrelatedData: boolean;
};

export const PitchingMetrics = ({
  homeTeam,
  awayTeam,
  timePeriod,
  pitchingSplit,
  hideUncorrelatedData,
}: PitchingMetricsProps) => {
  let leaguePitchingData = useSelector(selectLeaguePitchingData);
  let awayTeamCurrentPitchingOverallData = useSelector(selectTeamPitchingData(awayTeam, timePeriod, pitchingSplit));
  let homeTeamCurrentPitchingOverallData = useSelector(selectTeamPitchingData(homeTeam, timePeriod, pitchingSplit));

  let awayPitchingZScoreTotal = 0;
  let homePitchingZScoreTotal = 0;


  let metricRowData: any = [];
  let onlyCorrelatedMetricRows: any = [];
  for (const teamMetricKey in awayTeamCurrentPitchingOverallData) {
    const leagueMetricName = teamMetricKey as keyof LeaguePitchingStats;
    const teamMetricName = teamMetricKey as keyof PitchingStats;

    if (
      teamMetricName === 'pitches' || leagueMetricName === 'pitches' ||
      teamMetricName === 'wins' || leagueMetricName === 'wins' ||
      teamMetricName === 'losses' || leagueMetricName === 'losses' ||
      teamMetricName === 'runsAllowed' || leagueMetricName === 'runsAllowed'
    ) {
      continue
    }

    let highOrLowMetric = MLB_METRIC_MAP[teamMetricName].highOrLow as HighOrLow;

    const awayZScore = getZScore(
      awayTeamCurrentPitchingOverallData[teamMetricName],
      leaguePitchingData[leagueMetricName].leagueAverage,
      leaguePitchingData[leagueMetricName].stdDeviation,
      highOrLowMetric,
    );
    
    const homeZScore = getZScore(
      homeTeamCurrentPitchingOverallData[teamMetricName],
      leaguePitchingData[leagueMetricName].leagueAverage,
      leaguePitchingData[leagueMetricName].stdDeviation,
      highOrLowMetric,
    );

    const correlationToWins = leaguePitchingData[leagueMetricName].correlationToWins;

    let awayZScoreForTotals = 0;
    let homeZScoreForTotals = 0;

    if (correlationToWins > 0) {
      awayZScoreForTotals += awayZScore > 0 ? Math.abs(awayZScore) : -Math.abs(awayZScore);
      homeZScoreForTotals += homeZScore > 0 ? Math.abs(homeZScore) : -Math.abs(homeZScore);

      if (correlationToWins > 0.5) {
        awayPitchingZScoreTotal += awayZScoreForTotals;
        homePitchingZScoreTotal += homeZScoreForTotals;

        onlyCorrelatedMetricRows.push({
          statName: teamMetricKey,
          awayStat: awayTeamCurrentPitchingOverallData[teamMetricName],
          awayZScore: awayZScoreForTotals,
          homeZScore: homeZScoreForTotals,
          homeStat: homeTeamCurrentPitchingOverallData[teamMetricName],
          correlationToWins,
          leagueAverage: leaguePitchingData[leagueMetricName].leagueAverage,
        })
      };
    };

    // strong negative correlation should not negatively impact the total z-score, aka:
    // A pitcher with low era is a good thing not a negative thing so use the absolute value
    if (correlationToWins < 0) {
      awayZScoreForTotals += awayZScore < 0 ? Math.abs(awayZScore) : -Math.abs(awayZScore);
      homeZScoreForTotals += homeZScore < 0 ? Math.abs(homeZScore) : -Math.abs(homeZScore);

      if (correlationToWins < -0.5) {
        awayPitchingZScoreTotal += awayZScoreForTotals;
        homePitchingZScoreTotal += homeZScoreForTotals;

        onlyCorrelatedMetricRows.push({
          statName: teamMetricKey,
          awayStat: awayTeamCurrentPitchingOverallData[teamMetricName],
          awayZScore: awayZScoreForTotals,
          homeZScore: homeZScoreForTotals,
          homeStat: homeTeamCurrentPitchingOverallData[teamMetricName],
          correlationToWins,
          leagueAverage: leaguePitchingData[leagueMetricName].leagueAverage,
        })
      };
    };

    metricRowData.push({
      statName: teamMetricKey,
      awayStat: awayTeamCurrentPitchingOverallData[teamMetricName],
      awayZScore: awayZScoreForTotals,
      homeZScore: homeZScoreForTotals,
      homeStat: homeTeamCurrentPitchingOverallData[teamMetricName],
      correlationToWins,
      leagueAverage: leaguePitchingData[leagueMetricName].leagueAverage,
    })
  }

  let metricDataSet = hideUncorrelatedData ? onlyCorrelatedMetricRows : metricRowData;

  let metricRows = metricDataSet
    .sort((a: any, b: any) => Math.abs(b.correlationToWins) - Math.abs(a.correlationToWins))
    .map((data: any) => {
    const statName = data.statName as keyof PitchingStats;
    return (
      <MetricRow
        key={statName}
        awayTeamStat={data.awayStat}
        awayZScore={data.awayZScore}
        homeTeamStat={data.homeStat}
        homeZScore={data.homeZScore}
        statName={statName}
        statCorrelationToWins={data.correlationToWins}
        leagueAverageForStat={data.leagueAverage}
      />
    )
  })

  

  return (
    <div style={{ width: '100%' }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} textAlign="center">
        <Grid xs={4}>
          {truncateMetric(awayPitchingZScoreTotal)}
        </Grid>

        <Grid xs={4}>
          <Typography variant="h6" gutterBottom noWrap>Pitching Score</Typography>
        </Grid>
          
        <Grid xs={4}>
          {truncateMetric(homePitchingZScoreTotal)}
        </Grid>

        <Grid xs={12}>
          {metricRows}
        </Grid>
      </Grid>
      
    </div>
  )
};

export default PitchingMetrics;


/**
 * would be good to add things like (last 5 games or last 10 game to catch streaky teams)
 * 
 * consider adding in if last 5 games were wins/losses and against teams with winning/losing records
 */