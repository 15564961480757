import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { getNumberWithOrdinal } from 'components/helpers';

export interface StatCellProps {
  header?: string;
  metricRank?: number;
  metricValue: number | string;
};

const StatCell = ({header, metricRank, metricValue}: StatCellProps) => {
  return (
    <Grid xs={2}>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        {header && (<Typography variant="subtitle2" gutterBottom noWrap>{header}</Typography>)}
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {metricRank && (<Typography variant="caption" gutterBottom noWrap sx={{marginRight: '4px'}}>{`(${getNumberWithOrdinal(metricRank)})`}</Typography>)}
          <Typography variant="caption" gutterBottom noWrap>{metricValue}</Typography>
        </div>
          
      </div>
      
    </Grid>
  )
};

export default StatCell;