import { Dispatch, SetStateAction } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";

interface NavLinkInternalProps {
  route: string;
  routeText: string;
  onClickCallback: Dispatch<SetStateAction<boolean>>;
  children: JSX.Element;
};

const NavLinkInternal = ({route, routeText, onClickCallback, children}: NavLinkInternalProps) => {  
  const navigate = useNavigate();

  return (
    <ListItem key="analyzer-tool" disableGutters divider>
      <ListItemButton
        onClick={() => {
          navigate(route)
          onClickCallback(false)
        }}
      >
        {children}
        <ListItemText primary={routeText} />
      </ListItemButton>
    </ListItem>
  )
};

export default NavLinkInternal;