import { Dispatch, SetStateAction } from "react";
import { makeStyles } from 'tss-react/mui';
import { useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from '@mui/material/ListItemText';
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { LeagueCode } from "reducers/types/sharedTypes";

const createStyles = makeStyles()(({ spacing, palette, breakpoints }) => ({
  leagueSelect: {
    borderRadius: '4px',
    marginLeft: '20px',
    marginTop: spacing(1),
    marginBottom: spacing(1),
    [breakpoints.down('md')]: {
      marginLeft: '0px',
    },
  },
  leagueSelectOrange: {
    background: palette.primary.light,
    border: `3px solid ${palette.primary.main}`,
  },
  leagueSelectWhite: {
    background: palette.white.main,
    border: `3px solid ${palette.white.main}`,
  },
  leagueSelectLong: {
    marginLeft: '0px'
  },
}));

export interface LeagueSelectProps {
  selectedLeagueCode: LeagueCode;
  setSelectedLeagueCode: Dispatch<SetStateAction<LeagueCode>>;
  fullLength?: boolean;
  background?: 'primary' | 'white';
};

const LeagueSelect = ({
  selectedLeagueCode,
  setSelectedLeagueCode,
  fullLength = false,
  background = 'primary',
}: LeagueSelectProps) => {
  const { classes } = createStyles();
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedLeagueCode(event.target.value as LeagueCode);
  };

  const leagueCodes = Object.values(LeagueCode).filter(value => typeof value === 'string');
  let optionElements: JSX.Element[] = leagueCodes.sort().map((option) => 
    <MenuItem key={option} value={option}>
      <ListItemText>{option}</ListItemText>
    </MenuItem>);

  optionElements.unshift(
    <MenuItem key="none" value="">
      <em>None</em>
    </MenuItem>
  )

  // TODO: Remove this gross mess that will suffice for now.
  let containerClasses = classes.leagueSelect;
  if (fullLength) { containerClasses += ` ${classes.leagueSelectLong}`; }
  containerClasses += background === 'white' ? ` ${classes.leagueSelectWhite}` : ` ${classes.leagueSelectOrange}`;

  let minWidth = isTabletOrMobile ? 230 : fullLength ? 175 : 100;

  return (
    <Box className={containerClasses}>
      <FormControl sx={{ w: "100%", m: fullLength ? 0.5 : 1, minWidth }} size="small">
        <InputLabel id="league-selector" sx={{ "&.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" } }}>League</InputLabel>
        <Select
          labelId="league-selector"
          id="select-league-code"
          value={selectedLeagueCode}
          label="League"
          onChange={handleChange}
          sx={{
            minHeight: fullLength ? 56 : 32,
            "&.MuiOutlinedInput-root": {
              // TODO: keep for focus fixing down the road
              // "&:hover fieldset": {
              //   borderColor: "rgba(0, 0, 0, 0.6)"
              // },
              // "&:hover:not(:focus)": {
              //   borderColor: "rgba(0, 0, 0, 0.0)"
              // },
              // "&:focus:not(:hover)": {
              //   borderColor: "rgba(0, 0, 0, 0.0)"
              // },
              "&.Mui-focused fieldset": {
                borderColor: "rgba(0, 0, 0, 0.6)",
              },   
            },
          }}
          
        >
          {optionElements}
        </Select>
      </FormControl>
    </Box>
  )
};

export default LeagueSelect;