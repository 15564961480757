import { Dispatch, SetStateAction } from "react";
import { Moment } from 'moment-timezone';
import { LeagueCode } from "reducers/types/sharedTypes";
import { LeagueSelect } from 'components/navigation/leagueSelect';
import { NavbarBackground } from 'images';
import { DateSelector } from "./date";
import { useTheme, Typography, Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { HamburgerNavigation } from "./hamburgerNavigation";
import { useLocation } from "react-router-dom";
import { formatPageTitle } from "components/helpers";

export interface NavigationProps {
  selectedLeagueCode: LeagueCode;
  setSelectedLeagueCode: Dispatch<SetStateAction<LeagueCode>>;
  selectedDate: Moment | null;
  setSelectedDate: Dispatch<SetStateAction<Moment | null>>;
};

const Navigation = ({ selectedLeagueCode, setSelectedLeagueCode, selectedDate, setSelectedDate }: NavigationProps) => {
  const theme = useTheme();
  const location = useLocation();
  const pageTitle = formatPageTitle(location.pathname);

  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        height: '80px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: theme.palette.accentTwo.main,
        backgroundImage: `url(${NavbarBackground})`,
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Box sx={{display: 'flex', alignItems: 'center' }}>
        <HamburgerNavigation
          selectedLeagueCode={selectedLeagueCode}
          setSelectedLeagueCode={setSelectedLeagueCode}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />

        {isTabletOrMobile && (
          <Typography variant="h6" sx={{ color: theme.palette.white.main, ml: 3 }}>{selectedLeagueCode}</Typography>
        )}

        {!isTabletOrMobile && (
          <LeagueSelect
            selectedLeagueCode={selectedLeagueCode}
            setSelectedLeagueCode={setSelectedLeagueCode}
          />
        )}
      </Box>

      {!isTabletOrMobile && (
        <Box sx={{ ml: 5, background: theme.palette.white.main, }}>
          <DateSelector
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        </Box>
      )}

      <Box sx={{display: 'flex', alignItems: 'center', mr: 2 }}>
        { !isTabletOrMobile && (
          <Typography variant="overline">{pageTitle}</Typography>
        )}
      </Box>
    </Box>
  )
};

export default Navigation;