import Accordion from "@mui/material/Accordion";
import { MLB_METRIC_MAP, MLB_TEAM_CODE } from "config/mlb";
import { useSelector } from "react-redux";
import { selectLeagueBattingMetricInfo, selectTeamBattingMetricValue } from "reducers/mlbDataReducer";
import Grid from '@mui/material/Unstable_Grid2';
import AccordionSummary from "@mui/material/AccordionSummary";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AccordionDetails from "@mui/material/AccordionDetails";
import { truncateMetric } from "components/helpers";
import { useTheme } from "@mui/material";
import MetricRow from "../metricRow/MetricRow";


export interface BattingMetricProps {
  awayTeamCode: MLB_TEAM_CODE;
  homeTeamCode: MLB_TEAM_CODE;
  metricName: string;
};

const BattingMetric = ({awayTeamCode, homeTeamCode, metricName}: BattingMetricProps) => {
  const theme = useTheme();
  
  let leagueBattingDataForMetric = useSelector(selectLeagueBattingMetricInfo(metricName))

  let awayTeamCurrentBattingOverallData = useSelector(selectTeamBattingMetricValue(awayTeamCode, 'current', 'overall', metricName));
  let awayTeamLast14BattingOverallData = useSelector(selectTeamBattingMetricValue(awayTeamCode, 'last14', 'overall', metricName));
  let awayTeamLast30BattingOverallData = useSelector(selectTeamBattingMetricValue(awayTeamCode, 'last30', 'overall', metricName));

  let homeTeamCurrentBattingOverallData = useSelector(selectTeamBattingMetricValue(homeTeamCode, 'current', 'overall', metricName));
  let homeTeamLast14BattingOverallData = useSelector(selectTeamBattingMetricValue(homeTeamCode, 'last14', 'overall', metricName));
  let homeTeamLast30BattingOverallData = useSelector(selectTeamBattingMetricValue(homeTeamCode, 'last30', 'overall', metricName));

  return (
    <Grid container xs={12}>

      <Grid xs={12} sx={{ p: 0.5, borderBottom: `1px solid ${theme.palette.grey[200]}`, borderTop: `1px solid ${theme.palette.grey[200]}` }}>
        <Accordion sx={{boxShadow: 'none'}}>
          <AccordionSummary
            expandIcon={<InfoOutlinedIcon />}
            aria-controls={`${metricName}-details-panel`}
            id={`${metricName}-details-panel`}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', pl: 3}}>
              <Typography align="center" variant="overline" sx={{ lineHeight: 1 }}>{MLB_METRIC_MAP[metricName].longName}</Typography>
            </Box>
            
          </AccordionSummary>
          <AccordionDetails>

            <Grid container>
              <Grid xs={12} sx={{mb: 2}}>
                <Typography variant="overline" sx={{ lineHeight: 1, fontSize: '10px' }}>{MLB_METRIC_MAP[metricName].description}</Typography>
              </Grid>
              <Grid xs={6}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                  <Typography align="center" variant="overline" sx={{ lineHeight: 1, mt: 1, fontSize: '10px' }}>CORRELATION TO WINS</Typography>
                  <Typography align="center" variant="overline" sx={{ lineHeight: 1, mt: 1, fontSize: '10px' }}>{truncateMetric(leagueBattingDataForMetric.correlationToWins, 3)}</Typography>
                </Box>
              </Grid>

              <Grid xs={6}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                  <Typography align="center" variant="overline" sx={{ lineHeight: 1, mt: 1, fontSize: '10px' }}>LEAGUE AVERAGE</Typography>
                  <Typography align="center" variant="overline" sx={{ lineHeight: 1, mt: 1, fontSize: '10px' }}>{truncateMetric(leagueBattingDataForMetric.leagueAverage, MLB_METRIC_MAP[metricName].truncationDigits)}</Typography>
                </Box>
              </Grid>
            </Grid>

            
          </AccordionDetails>
        </Accordion>
        
      </Grid>

      <MetricRow
        metricName={metricName}
        metricSplit="OVR"
        leftMetricValue={awayTeamCurrentBattingOverallData}
        // leftMetricLeagueRank={}
        rightMetricValue={homeTeamCurrentBattingOverallData}
        // rightMetricLeagueRank={}
      />

      <MetricRow
        metricName={metricName}
        metricSplit="Last 14d"
        leftMetricValue={awayTeamLast14BattingOverallData}
        rightMetricValue={homeTeamLast14BattingOverallData}
      />

      <MetricRow
        metricName={metricName}
        metricSplit="Last 30d"
        leftMetricValue={awayTeamLast30BattingOverallData}
        rightMetricValue={homeTeamLast30BattingOverallData}
      />


    </Grid>
  )
};

export default BattingMetric;