import React, {useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { signInWithEmailAndPassword   } from 'firebase/auth';
import { auth } from 'firebaseSetup';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AuthContext } from 'context/AuthContext';

const Login = () => {
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState<string | null>(null);

  const user = useContext(AuthContext);

  useEffect(() => {
    if (user) {  navigate("/competitions") }
  }, [user, navigate]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let dataEmail = data.get('email') as string;
    let dataPassword = data.get('password') as string;

    signInWithEmailAndPassword(auth, dataEmail, dataPassword)
      .then(() => {
        navigate("/competitions")
      })
      .catch((error) => {
        // TODO: Build a real error reducer for these messages
        // example: https://www.pluralsight.com/resources/blog/guides/centralized-error-handing-with-react-and-redux
        let errorMessage = error.message.split('Firebase: ')[1].split(' (auth/')[0];
        setErrorText(errorMessage)
      });
  };

    return (
      <Container component="main">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">Log In</Typography>

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>

            { errorText && (
              <Alert variant="filled" severity="error">
                {errorText}
              </Alert>
            )}
            
          </Box>
        </Box>
      </Container>
    );
}
 
export default Login