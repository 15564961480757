import { useSelector } from 'react-redux';
import {
  LeaguePitchingStats,
  MLBTimePeriod,
  PitchingSplit,
  PitchInfos,
} from 'reducers/types/mlbTypes';

import { Pitches, PitchingGroup } from 'config/mlb';
import { selectLeaguePitchingData } from 'reducers/mlbDataReducer';
import { MetricInfo, MetricInfos } from 'reducers/types/sharedTypes';

export interface UseLeaguePitchingMetrics {
  leaguePitchingStats: MetricInfos;
  leaguePitchSpecificStats: PitchInfos;
};

const useLeaguePitchingMetrics = (
  // pitchingGroup: PitchingGroup,
  timePeriod: MLBTimePeriod,
  pitchingSplit: PitchingSplit,
): UseLeaguePitchingMetrics | null => {
  
  const leaguePitchingData = useSelector(selectLeaguePitchingData);

  let leaguePitchingStats: MetricInfos = {};
  let leaguePitchSpecificStats: PitchInfos = {};

  for (const metric of Object.keys(leaguePitchingData)) {
    const leagueMetricKey = metric as keyof LeaguePitchingStats;

    // league based metrics
    let leagueAverageForMetric = 0;
    let leagueMinimumForMetric = 0;
    let leagueMaximumForMetric = 0;
    let leagueStdDeviationForMetric = 0;
    let correlationToWins = 0;
    
    // handle pitch related metrics
    if (leagueMetricKey === 'pitches') {
      for(const pitchType of Object.keys(leaguePitchingData[leagueMetricKey])) {
        const pitchKey = 'pitches';
        const pitch = pitchType as Pitches;

        const leaguePitchInfo = leaguePitchingData[pitchKey][pitch];

        // percentageThrow
        leagueMinimumForMetric = Math.min(...leaguePitchInfo.percentageThrow.rankedTotals);
        leagueMaximumForMetric = Math.max(...leaguePitchInfo.percentageThrow.rankedTotals);
        leagueAverageForMetric = leaguePitchInfo.percentageThrow.leagueAverage;
        leagueStdDeviationForMetric = leaguePitchInfo.percentageThrow.stdDeviation;
        correlationToWins = leaguePitchInfo.percentageThrow.correlationToWins;

        const percentageThrowMetricInfo: MetricInfo = {
          leagueAverage: leagueAverageForMetric,
          leagueMinimum: leagueMinimumForMetric,
          leagueMaximum: leagueMaximumForMetric,
          leagueRankArray: leaguePitchInfo.percentageThrow.rankedTotals,
          leagueStdDeviation: leagueStdDeviationForMetric,
          metricCorrelationToWins: correlationToWins,
        };

        // averageVelocity
        leagueMinimumForMetric = Math.min(...leaguePitchInfo.averageVelocity.rankedTotals);
        leagueMaximumForMetric = Math.max(...leaguePitchInfo.averageVelocity.rankedTotals);
        leagueAverageForMetric = leaguePitchInfo.averageVelocity.leagueAverage;
        leagueStdDeviationForMetric = leaguePitchInfo.averageVelocity.stdDeviation;
        correlationToWins = leaguePitchInfo.percentageThrow.correlationToWins;

        const averageVelocityInfo: MetricInfo = {
          leagueAverage: leagueAverageForMetric,
          leagueMinimum: leagueMinimumForMetric,
          leagueMaximum: leagueMaximumForMetric,
          leagueRankArray: leaguePitchInfo.averageVelocity.rankedTotals,
          leagueStdDeviation: leagueStdDeviationForMetric,
          metricCorrelationToWins: correlationToWins,
        };

        leaguePitchSpecificStats[pitch] = {
          percentageThrow: percentageThrowMetricInfo,
          averageVelocity: averageVelocityInfo,
        };

      }
    
    } else {
      leagueMinimumForMetric = Math.min(...leaguePitchingData[leagueMetricKey].rankedTotals);
      leagueMaximumForMetric = Math.max(...leaguePitchingData[leagueMetricKey].rankedTotals);
      leagueAverageForMetric = leaguePitchingData[leagueMetricKey].leagueAverage;
      leagueStdDeviationForMetric = leaguePitchingData[leagueMetricKey].stdDeviation;
      correlationToWins = leaguePitchingData[leagueMetricKey].correlationToWins;

      leaguePitchingStats[metric] = {
        leagueAverage: leagueAverageForMetric,
        leagueMinimum: leagueMinimumForMetric,
        leagueMaximum: leagueMaximumForMetric,
        leagueRankArray: leaguePitchingData[leagueMetricKey].rankedTotals,
        leagueStdDeviation: leagueStdDeviationForMetric,
        metricCorrelationToWins: correlationToWins,
      }
    }
  };
  
  return {
    leaguePitchingStats,
    leaguePitchSpecificStats,
  }
}

export default useLeaguePitchingMetrics;