import { useSelector } from 'react-redux';
import { selectCurrentBettingOddsForCompetition } from 'reducers/competitionsReducer';
import { MoneylineOdds, OverUnderOdds, SpreadOdds } from 'reducers/types/competitionTypes';

const useBestCompetitionOdds = (competitionId: number): SpreadOdds | MoneylineOdds | OverUnderOdds => {
  const bettingOddsForCompetition = useSelector(selectCurrentBettingOddsForCompetition(competitionId));
  
  return [...bettingOddsForCompetition.spreadOdds, ...bettingOddsForCompetition.moneylineOdds, ...bettingOddsForCompetition.overUnderOdds]
  .filter((odd: SpreadOdds | MoneylineOdds | OverUnderOdds) => odd.openOrCurrent === 'current')
  .reduce(
    ( max: SpreadOdds | MoneylineOdds | OverUnderOdds,
      odd: SpreadOdds | MoneylineOdds | OverUnderOdds
    ) => {
      return max.percentage > odd.percentage ? max : odd
    });
}

export default useBestCompetitionOdds;
