import { Dispatch, SetStateAction } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { Moment } from "moment-timezone";

import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export interface DateSelectorProps {
  selectedDate: Moment | null;
  setSelectedDate: Dispatch<SetStateAction<Moment | null>>;
};

const DateSelector = ({ selectedDate, setSelectedDate }: DateSelectorProps) => {
  const theme = useTheme();
  const location = useLocation();
  const isOnAnalzyerRoute = location.pathname.includes('analyzer');

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: theme.palette.white.main,
          paddingTop: '7px',
          paddingBottom: '7px',
          borderRadius: '4px',
        }}
      >
        <Box>
          <IconButton
            size="small"
            aria-label="Previous Day"
            disabled={isOnAnalzyerRoute}
            onClick={() => {
              const dayBefore = moment(selectedDate).subtract(1, 'd');
              setSelectedDate(dayBefore);
            }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          
        </Box>

        <Box sx={{ ml: 0.5, mr: 0.5 }}>
          <DatePicker
            label="Competition Date"
            disabled={isOnAnalzyerRoute}
            value={moment(selectedDate)}
            minDate={moment(new Date("07-19-2024"))}
            onChange={(newValue) => {
              setSelectedDate(newValue);
            }}
          />
        </Box>

        <Box>
          <IconButton
            size="small"
            aria-label="Next Day"
            disabled={isOnAnalzyerRoute}
            onClick={() => {
              const dayBefore = moment(selectedDate).add(1, 'd');
              setSelectedDate(dayBefore);
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
      </Box>
    )
};

export default DateSelector;