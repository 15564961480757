import { Dispatch, SetStateAction, useState } from "react";
import { makeStyles } from 'tss-react/mui';
import { Moment } from "moment-timezone";
import { isNull } from "lodash";
import { useTheme, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from "@mui/icons-material/Menu";
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';

import { getLeagueIcon } from "components/helpers";
import { CloseIcon } from "../closeIcon";
import { DateSelector } from "../date";
import { LeagueSelect } from "../leagueSelect";
import { LoginLogoutButton } from "../loginLogoutButton";
import { NavLinkInternal } from "../navLinkInternal";
import { NavLinkHeader } from "../navLinkHeader";
import { NavLinkExternal } from "../navLinkExternal";

import { isLoggedIn } from "context/AuthProvider";
import { LeagueCode } from "reducers/types/sharedTypes";
import { LEAGUES } from "config/leagues";

const createStyles = makeStyles()(({ palette }) => ({
  headerContainer: {
    borderTop: `1px solid ${palette.accentTwo.light}`,
  },
}));

interface HamburgerNavigationProps {
  selectedLeagueCode: LeagueCode;
  setSelectedLeagueCode: Dispatch<SetStateAction<LeagueCode>>;
  selectedDate: Moment | null;
  setSelectedDate: Dispatch<SetStateAction<Moment | null>>;
};

const HamburgerNavigation = ({ selectedLeagueCode, setSelectedLeagueCode, selectedDate, setSelectedDate }: HamburgerNavigationProps) => {
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  const { classes } = createStyles();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box role="presentation">

      <List
        disablePadding
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: theme.palette.primary.main,
        }}
      >
        <ListItem key="bet-wiser-header" sx={{ justifyContent: 'center', height: '81.5px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                marginRight: theme.spacing(1),
                color: theme.palette.black.main,
              }}
            >
              <EmojiEventsOutlinedIcon />
            </Box>

            <ListItemText primary="Bet Wiser" primaryTypographyProps={{ variant: "h5", color: theme.palette.black.main }} />
          </Box>

          { isTabletOrMobile && (<CloseIcon onCloseCallback={setOpen} />)}
  
        </ListItem>
      </List>

      <List>
        <ListItem key="action-button" disableGutters sx={{ display: 'flex', justifyContent: 'center' }}>
          <LoginLogoutButton isLoggedIn={!isNull(isLoggedIn())} closeNavCallback={setOpen} />
        </ListItem>
      </List>

      <List disablePadding className={classes.headerContainer}>
        
        <NavLinkHeader headerText="Competitions">
          <EmojiEventsOutlinedIcon />
        </NavLinkHeader>

        <ListItem key="competitions-tool" disableGutters divider>

          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <LeagueSelect
              selectedLeagueCode={selectedLeagueCode}
              setSelectedLeagueCode={setSelectedLeagueCode}
              fullLength
              background="white"
            />
          </Box>
        </ListItem>

        <ListItem
          key="date-select-tool"
          disableGutters
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 1,
            mb: 1,
          }}
        >
          <DateSelector
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        </ListItem>
      </List>

      <Divider />

      <List disablePadding className={classes.headerContainer}>

        <NavLinkHeader headerText="Tools">
          <HandymanOutlinedIcon />
        </NavLinkHeader>

        <NavLinkInternal
          key="competitions-tool"
          route="competitions"
          routeText={`${selectedLeagueCode} Competitions`}
          onClickCallback={setOpen}
        >
          <ListItemIcon>
            {getLeagueIcon(selectedLeagueCode)}
          </ListItemIcon>
        </NavLinkInternal>

        <NavLinkInternal
          key="analyzer-tool"
          route="analyzer"
          routeText={`${selectedLeagueCode} Analyzer Tool`}
          onClickCallback={setOpen}
        >
          <ListItemIcon>
            <QueryStatsOutlinedIcon />
          </ListItemIcon>
        </NavLinkInternal>

        <NavLinkExternal
          href={LEAGUES[selectedLeagueCode].weatherLink}
          linkText={`${selectedLeagueCode} Weather`}
        >
          <CloudOutlinedIcon />
        </NavLinkExternal>

      </List>
    </Box>
  );

  return (
    <Box sx={{marginLeft: theme.spacing(5),  border: `4px solid ${theme.palette.white.main}`, borderRadius: "50px" }}>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ ml: 1, mr: 1, color: theme.palette.white.main }}
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>

      <Drawer
        open={open}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: { width: isTabletOrMobile ? '100%' : 250 },
        }}
      >
        {DrawerList}
      </Drawer>
    </Box>
  );
};
export default HamburgerNavigation;