import ListItemButton from "@mui/material/ListItemButton/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Dispatch, SetStateAction } from "react";

interface CloseIconProps {
  onCloseCallback: Dispatch<SetStateAction<boolean>>;
}

const CloseIcon = ({ onCloseCallback}: CloseIconProps) => {
  return (
    <ListItemButton
      onClick={() => { onCloseCallback(false) }}
      sx={{ position: 'absolute', right: -30 }}
    >
      <ListItemIcon>
        <CloseOutlinedIcon />
      </ListItemIcon>
    </ListItemButton>
  )
};

export default CloseIcon;