import { Typography } from "@mui/material";

const Page404 = () => {

  return (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ height: "500px", width: "500px" }}>
        <Typography variant="h1">Page Not Found</Typography>
      </div>
    </div>
  );
};

export default Page404;