import { MLB_TEAM_CODE, MLB_TEAM_CONFERENCE, MLB_TEAM_DIVISION } from 'config/mlb';
import {
  MLBLeagueData,
  MLBTeams, MLBTeamIds,
  MLBTeamDataByTimeframe,
} from './mlbTypes';

export type MustHaveKeys<V, S extends Record<keyof V, any>> = S;

export enum Sport {
  BASKETBALL = 'Basketball',
  FOOTBALL = 'Football',
  HOCKEY = 'Hockey',
  BASEBALL = 'Baseball',
};

// TODO: setup a config object for each league similar to webapp assets
// update icon rendering in HambugerNav as well to simplify
export enum LeagueCode {
  NBA = 'NBA',
  NFL = 'NFL',
  NHL = 'NHL',
  NCAAM = 'NCAAM',
  MLB = 'MLB',
  NCAAF = 'NCAAF',
}

export interface League {
  code: LeagueCode;
  // these should grow as new leagues/teams get added
  teams: MLBTeams;
  teamIds: MLBTeamIds;
  data: MLBLeagueData;
}

export interface LeagueStat {
  leagueTotals: number[];
  rankedTotals: number[];
  leagueAverage: number;
  correlationToWins:  number;
  stdDeviation: number;
}

export interface MetricInfo {
  leagueAverage: number;
  leagueMinimum: number;
  leagueMaximum: number;
  leagueRankArray: number[];
  leagueStdDeviation: number;
  metricCorrelationToWins: number;
};

export interface MetricInfos {
  [key : string]: MetricInfo; 
};

export interface IndividualMetricInfo {
  leagueRank: number;
  metricValue: number;
  metricZScore: number;
};

export interface IndividualMetricInfos {
  [key : string]: IndividualMetricInfo
};

export interface TeamPitchInfo {
    percentageThrow: IndividualMetricInfo;
    averageVelocity: IndividualMetricInfo;
};

export interface TeamPitchInfos {
  [key : string]: TeamPitchInfo
};

export interface Team {
  // these should grow as new leagues/teams get added
  abbr: MLB_TEAM_CODE; 
  conference:  MLB_TEAM_CONFERENCE;
  division: MLB_TEAM_DIVISION;
  teamData: MLBTeamDataByTimeframe; // comes from the league rollup
  // venue
}

export interface Player {
  league: LeagueCode.MLB; // this should grow
  teamCode: MLB_TEAM_CODE;// this should grow
  name: string;
}