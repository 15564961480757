import { MLB_TEAM_CODE } from 'config/mlb';
import { buildBatter, buildPitcher } from 'reducers/builders/mlbBuilders';
import {
  MLBTimePeriod,
  MLBPlayer,
  PitchingStats,
  PitchingSplit,
  PitchingSplits,
  BattingSplit,
  BattingStats,
  BattingSplits,
} from 'reducers/types/mlbTypes';

// import Papa, { ParseResult } from 'papaparse';
// import { CsvTeamBatting, CsvPitcher } from './types';
// import { TeamBattingData, Pitchers, Pitcher } from 'reducers/types';
// import { parseString } from 'models/api';
// import { parseCsvData, buildMlbDataCsvFilePath, parseOptions } from './csvService';
// import { MLB_TEAM_CODE } from 'config/mlb';

/**
 * This should work as is, but papa parse needs the callback todo anything
 * with the returned data and because it's not async/await we just get an 
 * empty teamBattingData object returned from this function even though inside
 * retrieveTeamBattingData we actually get the outcome we want set to teamBattingData
 * TODO: explore into this or other options, maybe just do this manually
 */
// export const getTeamBattingData = (): TeamBattingData => {
//   // let csvTeamBattingData: CsvTeamBatting[] = [];
//   let teamBattingData: TeamBattingData = {};

//   // const retrieveTeamBattingData = (papaData: any) => {
//   //   for (const teamData of papaData) {
//   //     const teamNameShort = parseString(teamData.Team) as MLB_TEAM_CODE;
//   //     teamBattingData[teamNameShort] = {
//   //       rbi: teamData.RBI,
//   //       woba: teamData.wOBA,
//   //       runsScored: teamData.R,
//   //       avg: teamData.AVG,
//   //       slg: teamData.SLG,
//   //       hr: teamData.HR,
//   //     };
//   //   };
//   // }

//   // parseCsvData(buildMlbDataCsvFilePath('team-batting-06-14-24.csv'), retrieveTeamBattingData)
//   return teamBattingData;
// };

// export const getPitcherData = async (): Promise<Pitchers> => {
//   const csvPitcherData: ParseResult<CsvPitcher> = Papa.parse(await fetchCsvData('pitcher-06-14-24.csv'), parseOptions);

//   const pitcherDataByTeam: Pitchers = {};

//   for ( const pitcher of csvPitcherData.data) {
//     const pitcherData: Pitcher = {
//       name: pitcher.Name,
//       teamShortName: pitcher.Team,
//       position: PitcherPosition;
//       gamesPlayed: number;
//       wins: number;
//       losses: number;
//       saves: number
//       inningsPitched: number;
//       war: number;
//       xfip: number;
//       era: number;
//       leftOnBasePercentage: number;
//       totalBattersFaced: number;
//       runsAllowed: number;
//       whip: number;
//     };

//     pitcherDataByTeam
//   };

//   Name,Team,G,W,L,SV,IP,WAR,xFIP,ERA,LOB%,TBF,R,WHIP,NameASCII,PlayerId,MLBAMID
// };




// going to add some data processing functions here since they should be getting used in the 
// service calls, but for now just need to be extracted out of the reducer calls
export const formatPapaPitchingStats = (data: any): PitchingStats => {
  const gamesPlayedCalc = data.W + data.L;
  return {
    gamesPlayed: gamesPlayedCalc,
    wins: data.W,
    losses: data.L,
    saves: data.SV,
    inningsPitched: data.IP,
    war: data.WAR,
    xfip: data.xFIP,
    era: data.ERA,
    leftOnBasePercentage: data['LOB%'],
    totalBattersFaced: data.TBF,
    homerunsAllowed: data.HR,
    runsAllowed: data.R,
    whip: data.WHIP,
    battingAverageAgainst: data.AVG,
    pitches: {
      fastball: {
        percentageThrow: data['FB%'],
        averageVelocity: data.FBv,
      },
      slider: {
        percentageThrow: data['SL%'],
        averageVelocity: data.SLv,
      },
      cutter: {
        percentageThrow: data['CT%'],
        averageVelocity: data.CTv,
      },
      curveball: {
        percentageThrow: data['CB%'],
        averageVelocity: data.CBv,
      },
      changeup: {
        percentageThrow: data['CH%'],
        averageVelocity: data.CHv,
      },

    },
    clutch: data.Clutch,
  };
};

export const formatPapaPitcher = (
  playerName: string,
  teamCode: MLB_TEAM_CODE,
  timePeriod: MLBTimePeriod,
  split: PitchingSplit,
  splitData: PitchingStats,
  existingPlayerData?: MLBPlayer
) => {
  let pitcher = buildPitcher(teamCode, playerName);

  if (existingPlayerData) {
    pitcher = existingPlayerData;
  };

  let pitchingSplits = pitcher.playerData[timePeriod] as PitchingSplits;
  pitchingSplits[split] = splitData;

  return pitcher;
};


export const formatPapaBattingStats = (data: any, runsAllowed = 0) => {
  return {
    atBats: data.AB,
    plateAttempts: data.PA,
    hits: data.H,
    homeruns: data.HR,
    runsScored: data.R,
    rbis: data.RBI,
    walks: data.BB,
    intentionalWalks: data.IBB,
    strikeouts: data.SO,
    groundIntoDoublePlay: data.GDP,
    stolenBases: data.SB,
    caughtStealing: data.CS,
    battingAverage: data.AVG,
    onBasePercentage: data.OBP,
    sluggingPercentage: data.SLG,
    onBasePlusSluggingPercentage: data.OPS,
    isolatedPower: data.ISO,
    battingAverageOnBallsInPlay: data.BABIP,
    weightedOnBaseAverage: data.wOBA,
    weightedRunsAboveAverage: data.wRAA,
    // runs per plate attempt scaled where average is 100
    // and is league and park adjusted
    wRCPlus: data['wRC+'],
    fastballRunsAboveAveragePer100: data['wFB/C'],
    sliderRunsAboveAveragePer100: data['wSL/C'],
    cutterRunsAboveAveragePer100: data['wCT/C'],
    curveballRunsAboveAveragePer100: data['wCB/C'],
    changeupRunsAboveAveragePer100: data['wCH/C'],
    splitFingerFastballRunsAboveAveragePer100: data['wSF/C'],
    hardHitBallPercentage: data['HardHit%'],

    // this metric for individual batters doesn't make sense unless we factor in
    // runs they've allowed by errors maybe? Would be best just to toss this, but
    // doing so now will split the symmetry between teamBatting and playerBatting
    // stats which I want to do at some point after the inital project mvp and before
    // the next league is added. The concept of "Player" with name, number, etc
    runDifferential: data.R - runsAllowed,
  }
};

export const formatPapaBatter = (
  playerName: string,
  teamCode: MLB_TEAM_CODE,
  timePeriod: MLBTimePeriod,
  split: BattingSplit,
  splitData: BattingStats,
  existingPlayerData?: MLBPlayer
) => {
  let batter = buildBatter(teamCode, playerName);

  if (existingPlayerData) {
    batter = existingPlayerData;
  };

  let battingSplits = batter.playerData[timePeriod] as BattingSplits;
  battingSplits[split] = splitData;

  return batter;
};


const fangraphsMLBDataService = {
  // getTeamBattingData,
  // getPitcherData,
};

export default fangraphsMLBDataService;


// add remaining data imports
// update view to just show all the data like sheets does