import { Competition } from "reducers/types/competitionTypes";
import Grid from '@mui/material/Unstable_Grid2';
import { selectLeagueBattingData, selectLeaguePitchingData, selectTeamPitchers, selectTeamWinsAndLossesSelector } from "reducers/mlbDataReducer";
import { useSelector } from "react-redux";
import useLeaguePitchingMetrics from "components/helpers/useLeaguePitchingMetrics";
import useAllPitchersMetrics from "components/helpers/useAllPitchersMetrics";
import useTeamPitchingMetrics from "components/helpers/useTeamPitchingMetrics";
import usePitcherMetrics from "components/helpers/usePitcherMetrics";
import { BattingSplit, MLBTimePeriod, PitchingSplit, BattingStats, PitchingStats } from 'reducers/types/mlbTypes';
import { useState } from "react";
import { Box, CardContent, Typography, useTheme } from "@mui/material";
import { Standing } from "./standing";
import { TeamMetric } from "./teamMetric";
import { BattingMetric } from "./battingMetric";
import { PitchingMetric } from "./pitchingMetric";

interface MlbAnalyzerProps {
  competition: Competition;
};

const MlbAnalyzer = ({competition}: MlbAnalyzerProps) => {
  const theme = useTheme();

  let current: MLBTimePeriod = 'current';
  let last14: MLBTimePeriod = 'last14';
  let last30: MLBTimePeriod = 'last30';
  
  // these are for selections in case the users wants to change the split
  const [timePeriod, setTimePeriod] = useState<MLBTimePeriod>('current');
  const [battingSplit, setBattingSplit] = useState<BattingSplit>('overall');
  const [pitchingSplit, setPitchingSplit] = useState<PitchingSplit>('overall');

  const [awayStartingPitcher, setAwayStartingPitcher] = useState<string>('');
  const [homeStartingPitcher, setHomeStartingPitcher] = useState<string>('');

  let awayTeamPitchers = useSelector(selectTeamPitchers(competition.awayTeamCode));
  let homeTeamPitchers = useSelector(selectTeamPitchers(competition.homeTeamCode));

  const leaguePitchingMetrics = useLeaguePitchingMetrics(timePeriod, pitchingSplit);
  const allPitchersMetrics = useAllPitchersMetrics(timePeriod, pitchingSplit);

  const awayTeamPitchingStats = useTeamPitchingMetrics(
    competition.awayTeamCode,
    leaguePitchingMetrics?.leaguePitchingStats,
    leaguePitchingMetrics?.leaguePitchSpecificStats,
    timePeriod,
    pitchingSplit
  );
  const homeTeamPitchingStats = useTeamPitchingMetrics(
    competition.homeTeamCode,
    leaguePitchingMetrics?.leaguePitchingStats,
    leaguePitchingMetrics?.leaguePitchSpecificStats,
    timePeriod,
    pitchingSplit
  );

  const awayPitcherData = usePitcherMetrics(
    awayStartingPitcher,
    competition.awayTeamCode,
    allPitchersMetrics?.allPitchersStats,
    allPitchersMetrics?.allPitchersPitchSpecificStats,
    timePeriod,
    pitchingSplit
  );
  const homePitcherData = usePitcherMetrics(
    homeStartingPitcher,
    competition.homeTeamCode,
    allPitchersMetrics?.allPitchersStats,
    allPitchersMetrics?.allPitchersPitchSpecificStats,
    timePeriod,
    pitchingSplit
  );

  let awayTeamCurrentWinLossData = useSelector(selectTeamWinsAndLossesSelector(competition.awayTeamCode, current, pitchingSplit));
  let homeTeamCurrentWinLossData = useSelector(selectTeamWinsAndLossesSelector(competition.homeTeamCode, current, pitchingSplit)); 
  let awayTeamOverallStanding = awayTeamPitchingStats ? awayTeamPitchingStats?.teamPitchingStats.wins.leagueRank : undefined;
  let homeTeamOverallStanding = homeTeamPitchingStats ? homeTeamPitchingStats?.teamPitchingStats.wins.leagueRank : undefined;

  let awayTeamLast14WinLossData = useSelector(selectTeamWinsAndLossesSelector(competition.awayTeamCode, last14, pitchingSplit));
  let homeTeamLast14WinLossData = useSelector(selectTeamWinsAndLossesSelector(competition.homeTeamCode, last14, pitchingSplit)); 

  let awayTeamLast30WinLossData = useSelector(selectTeamWinsAndLossesSelector(competition.awayTeamCode, last30, pitchingSplit));
  let homeTeamLast30WinLossData = useSelector(selectTeamWinsAndLossesSelector(competition.homeTeamCode, last30, pitchingSplit));



  // TODO: This is code smelly and could be properly re-written recursively
  //       might be better to move this sort logic to a selector so the data
  //       comes back from the selector sorted.
  let leagueBattingData = useSelector(selectLeagueBattingData);
  let battingStatKeysWithCorrelation: any = [];
  for (const battingStatName in leagueBattingData) {
    let battingStatKey = battingStatName as keyof BattingStats;
    battingStatKeysWithCorrelation.push({ metricName: battingStatKey, correlationToWins: leagueBattingData[battingStatKey].correlationToWins })
  }
  let sortedBattingKeys = battingStatKeysWithCorrelation.sort((a: any, b: any) => Math.abs(b.correlationToWins) - Math.abs(a.correlationToWins))

  let highlyCorrelatedBattingStatComponents: JSX.Element[] = [];
  let lowlyCorrelatedBattingStatComponents: JSX.Element[] = [];
  sortedBattingKeys.map((sortedKeyInfo: any) => {
    let statComponent = <BattingMetric key={sortedKeyInfo.metricName} awayTeamCode={competition.awayTeamCode} homeTeamCode={competition.homeTeamCode} metricName={sortedKeyInfo.metricName}/>;
    if (Math.abs(sortedKeyInfo.correlationToWins) > 0.7) {
      highlyCorrelatedBattingStatComponents.push(statComponent)
    } else {
      lowlyCorrelatedBattingStatComponents.push(statComponent)
    }
  })
  
  // TODO: This is code smelly and could be properly re-written recursively
  //       might be better to move this sort logic to a selector so the data
  //       comes back from the selector sorted.
  let leaguePitchingData = useSelector(selectLeaguePitchingData);
  let pitchingStatKeysWithCorrelation: any = [];
  for (const pitchingStatName in leaguePitchingData) {
    let pitchingStatKey = pitchingStatName as keyof PitchingStats;
    pitchingStatKeysWithCorrelation.push({ metricName: pitchingStatKey, correlationToWins: leaguePitchingData[pitchingStatKey].correlationToWins })
  }
  let sortedPitchingKeys = pitchingStatKeysWithCorrelation.sort((a: any, b: any) => Math.abs(b.correlationToWins) - Math.abs(a.correlationToWins))

  let highlyCorrelatedPitchingStatComponents: JSX.Element[] = [];
  let lowlyCorrelatedPitchingStatComponents: JSX.Element[] = [];
  sortedPitchingKeys.map((sortedKeyInfo: any) => {
    if(sortedKeyInfo.metricName === 'wins' || sortedKeyInfo.metricName === 'losses') return;

    let statComponent = <PitchingMetric key={sortedKeyInfo.metricName} awayTeamCode={competition.awayTeamCode} homeTeamCode={competition.homeTeamCode} metricName={sortedKeyInfo.metricName}/>;
    if (Math.abs(sortedKeyInfo.correlationToWins) > 0.6) {
      highlyCorrelatedPitchingStatComponents.push(statComponent)
    } else {
      lowlyCorrelatedPitchingStatComponents.push(statComponent)
    }
  })

  return (
    <Grid container sx={{textAlign: "center"}} justifyContent="center" alignItems="center">
      <Grid xs={12}>
        <CardContent
          sx={{
            m: 2,
            p: 0.5,
            '&:last-child': { pb: 0.5 },
            background: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            minHeight: theme.spacing(2),
          }}
        >
          <Typography variant="overline" sx={{ lineHeight: 1 }}>Standings</Typography>
        </CardContent>
      </Grid>

      <Standing
        standingHeader="OVR"
        awayTeamWins={awayTeamCurrentWinLossData.wins}
        awayTeamLosses={awayTeamCurrentWinLossData.losses}
        awayTeamLeagueRank={awayTeamOverallStanding}
        homeTeamWins={homeTeamCurrentWinLossData.wins}
        homeTeamLosses={homeTeamCurrentWinLossData.losses}
        homeTeamLeagueRank={homeTeamOverallStanding}
      />

      <Standing
        standingHeader="Last 14d"
        awayTeamWins={awayTeamLast14WinLossData.wins}
        awayTeamLosses={awayTeamLast14WinLossData.losses}
        homeTeamWins={homeTeamLast14WinLossData.wins}
        homeTeamLosses={homeTeamLast14WinLossData.losses}
      />

      <Standing
        standingHeader="Last 30d"
        awayTeamWins={awayTeamLast30WinLossData.wins}
        awayTeamLosses={awayTeamLast30WinLossData.losses}
        homeTeamWins={homeTeamLast30WinLossData.wins}
        homeTeamLosses={homeTeamLast30WinLossData.losses}
      />
      
      <Grid xs={12}>
        <CardContent
          sx={{
            m: 2,
            mb: 1,
            p: 0.5,
            '&:last-child': { pb: 0.5 },
            background: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            minHeight: theme.spacing(2),
          }}
        >
          <Typography variant="overline" sx={{ lineHeight: 1 }}>Team Batting</Typography>
        </CardContent>
      </Grid>

      {highlyCorrelatedBattingStatComponents}

      <Grid xs={12}>
        <CardContent
          sx={{
            m: 2,
            mb: 1,
            p: 0.5,
            '&:last-child': { pb: 0.5 },
            background: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            minHeight: theme.spacing(2),
          }}
        >
          <Typography variant="overline" sx={{ lineHeight: 1 }}>Team Pitching</Typography>
        </CardContent>
      </Grid>

      {highlyCorrelatedPitchingStatComponents}

    </Grid>
  )
};

export default MlbAnalyzer;