import * as correlStats from 'statistics.js';
import stats from 'statistics';
import { HighOrLow } from 'config/mlb';

export const getAverage = (arr: number[] | null) => {
  if (arr && arr.length > 0) return arr.reduce((a, b) => a + b) / arr.length;
  return 0;
};

export const getCorrelation = (x: number[], y:number[]) => {
  if (x.length !== y.length) {
    console.warn(`Cannot calculate correlation, x: ${x.length} | y: ${y.length} must be same length`)
    return 0
  };

  const correlationData = x.map((dataPoint, index) => {
    return { x: dataPoint, y: y[index] };
  });

  // interval: when you know a higher batting average is better,
  // but a player batting .100 isn't 3x worse than one batting .300
  const correlationHeader = { x: 'interval', y: 'interval'};

  const stats = new correlStats(correlationData, correlationHeader);
  return stats.correlationCoefficient('x', 'y').correlationCoefficient;
};

export const getZScore = (
  dataPoint: number,
  leagueAverage: number,
  standardDeviation: number,
  highOrLow?: HighOrLow,
) => {
  if (!highOrLow) { return 0 }
  return (dataPoint - leagueAverage) / standardDeviation;
};

export const sumZScore = (
  correlationToWins: number,
  existingZScore: number,
  newZscore: number
) => {
  let zScoreTotal = existingZScore;
  // only include the zScore in the total roll up if there is a strong correlation
  if (correlationToWins > 0.5) {
    zScoreTotal += newZscore;
  };

  // strong negative correlation should not negatively impact the total z-score, aka:
  // A team with low era is a good thing not a negative thing so use the absolute value
  if (correlationToWins < -0.5) {
    zScoreTotal += Math.abs(newZscore);
  };
  return zScoreTotal;
};

export const getStandardDeviation = (metricArray: number[]): number => {
  //@ts-ignore-next-line
  return metricArray.reduce(stats).stdev;
};

export const analyzeLeagueData = (
  metricKey: string,
  metricArray: number[],
  leagueWins: number[]
) => { 
  const average = getAverage(metricArray);
  const correlation = getCorrelation(metricArray, leagueWins);
  const stdDeviation = getStandardDeviation(metricArray);

  return {
    average,
    correlation,
    stdDeviation,
  };
};