import { getCorrelationColor, getZScoreWinnerForStat, truncateMetric } from 'components/helpers';
import { MLB_METRIC_MAP } from 'config/mlb';
import { BattingStats, PitchingStats } from 'reducers/types/mlbTypes';

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { ImpactScore } from 'components/library';
import { HomeOrAway } from 'reducers/types/competitionTypes';


export interface BattingMetricsProps {
  awayTeamStat: number;
  awayZScore: number;
  homeTeamStat: number;
  homeZScore: number;
  statName: keyof BattingStats | keyof PitchingStats;
  statCorrelationToWins: number;
  leagueAverageForStat: number;
};

export const MetricRow = ({
  awayTeamStat,
  homeTeamStat,
  statName,
  statCorrelationToWins,
  leagueAverageForStat,
  homeZScore,
  awayZScore,
}:BattingMetricsProps) => {
  let borderColor = getCorrelationColor(statCorrelationToWins);

  if (statName === 'pitches') {
    return <></>
  }
  const metricInfo = MLB_METRIC_MAP[statName];  
  const truncationDigits = metricInfo.truncationDigits;
  const statWinner: HomeOrAway | 'split' = awayTeamStat && homeTeamStat ? getZScoreWinnerForStat(statCorrelationToWins, awayTeamStat, homeTeamStat) : 'split';
  
  return (
    <div style={{ width: '100%'}}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} textAlign="center">
        <Grid xs={9} xsOffset={1.5}>
          <Paper sx={{  width: "100%", border: `4px solid ${borderColor}`}}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 12, md: 12 }} textAlign="center">
              
              {/* first card row */}
              <Grid xs={4}>
                <ImpactScore statValue={awayZScore} team="away" statWinner={statWinner} />
              </Grid>

              <Grid xs={4}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h6" gutterBottom noWrap>{metricInfo.abbreviation}&nbsp;&nbsp;</Typography>
                    <Tooltip title={`${metricInfo.longName} (${metricInfo.abbreviation}) - ${metricInfo.description}`} placement="top">
                      <InfoOutlinedIcon sx={{ fontSize: 'inherit' }} />
                    </Tooltip>
                  </div>

                  <Typography variant="overline" gutterBottom noWrap aria-label="Correlation To Wins">Correlation To Wins</Typography>
                  <Typography variant="subtitle2"  gutterBottom noWrap>{truncateMetric(statCorrelationToWins)}</Typography>
                </div>
              </Grid>
              
              <Grid xs={4}>
                <ImpactScore statValue={homeZScore} team="home" statWinner={statWinner} />
              </Grid>



              {/* second card row */}
              <Grid xs={4}>
                <Typography variant="overline" gutterBottom noWrap aria-label="Away Current">&nbsp;</Typography>
                <Typography variant="subtitle2"  gutterBottom noWrap>{awayTeamStat ? truncateMetric(awayTeamStat, truncationDigits): 'N/A'}</Typography>
              </Grid>

              <Grid xs={4}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                  <Typography variant="overline" gutterBottom noWrap aria-label="Current League Average">Current League Average</Typography>
                  <Typography variant="subtitle2"  gutterBottom noWrap>{truncateMetric(leagueAverageForStat, truncationDigits)}</Typography>
                </div>
              </Grid>
              
              <Grid xs={4}>
                <Typography variant="overline" gutterBottom noWrap aria-label="Home Current">&nbsp;</Typography>
                <Typography variant="subtitle2"  gutterBottom noWrap>{homeTeamStat ? truncateMetric(homeTeamStat, truncationDigits): 'N/A'}</Typography>
              </Grid>



              {/* third card row */}
              <Grid xs={4}>
                <Typography variant="overline" gutterBottom noWrap aria-label="Last 5 Away">&nbsp;</Typography>
                <Typography variant="subtitle2"  gutterBottom noWrap>{0}</Typography>
              </Grid>

              <Grid xs={4}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                  <Typography variant="overline" gutterBottom noWrap aria-label="Last 5">Last 5</Typography>
                  <Typography variant="subtitle2"  gutterBottom noWrap>N/A</Typography>
                </div>
              </Grid>
              
              <Grid xs={4}>
              <Typography variant="overline" gutterBottom noWrap aria-label="Last 5 Home">&nbsp;</Typography>
                <Typography variant="subtitle2"  gutterBottom noWrap>{0}</Typography>
              </Grid>


              {/* fourth card row */}
              <Grid xs={4}>
                <Typography variant="overline" gutterBottom noWrap aria-label="Last 10 Away">&nbsp;</Typography>
                <Typography variant="subtitle2"  gutterBottom noWrap>{0}</Typography>
              </Grid>

              <Grid xs={4}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                  <Typography variant="overline" gutterBottom noWrap aria-label="Last 10">Last 10</Typography>
                  <Typography variant="subtitle2"  gutterBottom noWrap>N/A</Typography>
                </div>
              </Grid>
              
              <Grid xs={4}>
                <Typography variant="overline" gutterBottom noWrap aria-label="Last 10 Home">&nbsp;</Typography>
                <Typography variant="subtitle2"  gutterBottom noWrap>{0}</Typography>
              </Grid>






            </Grid>
          </Paper>
        </Grid>
      </Grid>
      
    </div>
  )
};

export default MetricRow;


/**
 * would be good to add things like (last 5 games or last 10 game to catch streaky teams)
 * 
 * consider adding in if last 5 games were wins/losses and against teams with winning/losing records
 */