import { useTheme } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export interface OddsBadgeProps {
  badgeTitle?: string;
  isFeatured?: boolean;
};

const OddsBadge = ({ badgeTitle, isFeatured }: OddsBadgeProps) => {
  const theme = useTheme();

  let badgeBackgroundColor = isFeatured ? 'goldenRod' : theme.palette.accentTwo.main;
  let badgeTitleComponent = badgeTitle ? (<strong>{badgeTitle}</strong>) : (<p></p>);

  return (
    <CardContent
      sx={{
        p: 0.5,
        background: badgeBackgroundColor,
        borderColor: badgeBackgroundColor,
        minHeight: theme.spacing(2),
      }}
    >
      <Typography variant="overline" sx={{ lineHeight: 1 }}>
        {badgeTitleComponent}
      </Typography>
    </CardContent>
  )
};

export default OddsBadge;