import { useSelector } from "react-redux";
import Grid from '@mui/material/Unstable_Grid2';
import Typography from "@mui/material/Typography";

import { MLB_METRIC_MAP, MLB_TEAM_CODE, MLB_TEAM_NAME_LONG } from "config/mlb";
import { PitchingSplit, MLBTimePeriod, LeaguePitchingStats, LeagueBattingStats, BattingSplit } from "reducers/types/mlbTypes";
import { selectTeamIds, selectTeamWinsAndLossesSelector } from "reducers/mlbDataReducer";

import { getLeagueRankForMetric, getNumberWithOrdinal, truncateMetric } from "components/helpers";
import { FieldSelect } from "components/library";
import FilterMetric from "./FilterMetric";
import StatCell from './StatCell';
import useTeamPitchingMetrics from "components/helpers/useTeamPitchingMetrics";
import useLeaguePitchingMetrics from "components/helpers/useLeaguePitchingMetrics";
import useTeamBattingMetrics from "components/helpers/useTeamBattingMetrics";
import useLeagueBattingMetrics from "components/helpers/useLeagueBattingMetrics";

// styles;
// const genericPaperStyles = { height: '50px', borderRadius: '0px', boxShadow: 0, padding: '12.5px' };
// const leftPaperStyles = { borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' };
// const rightPaperStyles = { borderTopRightRadius: '4px', borderBottomRightRadius: '4px' };

export interface TeamFilterProps {
  team: MLB_TEAM_CODE;
  teamHandler: (teamCode: MLB_TEAM_CODE) => void;
  pitchingSplit: PitchingSplit;
  battingSplit: BattingSplit;
};

const TeamFilter = ({
  team,
  teamHandler,
  pitchingSplit,
  battingSplit,
}: TeamFilterProps) => {
  const currentTimePeriod: MLBTimePeriod = 'current';

  const teamIds = useSelector(selectTeamIds);
  const currentLeaguePitchingMetrics = useLeaguePitchingMetrics(currentTimePeriod, pitchingSplit);
  const currentLeagueBattingMetrics = useLeagueBattingMetrics(currentTimePeriod, battingSplit);
  const teamName = MLB_TEAM_NAME_LONG[team];

  const currentTeamWinLossData = useSelector(selectTeamWinsAndLossesSelector(team, currentTimePeriod, pitchingSplit));
  const last14dTeamWinLossData = useSelector(selectTeamWinsAndLossesSelector(team, currentTimePeriod, pitchingSplit));
  const last30dTeamWinLossData = useSelector(selectTeamWinsAndLossesSelector(team, currentTimePeriod, pitchingSplit));


  const currentTeamPitchingStats = useTeamPitchingMetrics(
    team,
    currentLeaguePitchingMetrics?.leaguePitchingStats,
    currentLeaguePitchingMetrics?.leaguePitchSpecificStats,
    currentTimePeriod,
    pitchingSplit
  );
  
  const currentTeamBattingStats = useTeamBattingMetrics(
    team,
    currentLeagueBattingMetrics?.leagueBattingStats,
    currentTimePeriod,
    battingSplit
  );

  const teamOverallStanding = currentTeamPitchingStats ? currentTeamPitchingStats?.teamPitchingStats.wins.leagueRank : undefined;

  const pitchingMetricMapKeys = ['era', 'whip', 'saves', 'leftOnBasePercentage', 'battingAverageAgainst', 'runDifferential'];
  const battingMetricMapKeys = ['wRCPlus', 'rbis', 'weightedOnBaseAverage', 'weightedRunsAboveAverage', 'onBasePlusSluggingPercentage', 'onBasePercentage'];

  const pitchingHeaderCellData = pitchingMetricMapKeys.map((statName) => {
    let leagueStatKey = statName as keyof LeaguePitchingStats;
    
    // run differential is stored on batter data because we load pitcher data first and need both
    // pitching data and batting data to calculate it
    if (statName === 'runDifferential') {
      let leagueStatKey = statName as keyof LeagueBattingStats;
      let leagueAverage = currentLeagueBattingMetrics ? currentLeagueBattingMetrics.leagueBattingStats[leagueStatKey].leagueAverage : 0;
      return (
        <StatCell
        key={leagueStatKey}
          header={MLB_METRIC_MAP[statName].abbreviation}
          metricValue={truncateMetric(leagueAverage, MLB_METRIC_MAP[statName].truncationDigits)}
        />
      )
    } else {
      leagueStatKey = statName as keyof LeaguePitchingStats;
      if (leagueStatKey !== 'pitches') {
        let leagueAverage = currentLeaguePitchingMetrics ? currentLeaguePitchingMetrics.leaguePitchingStats[leagueStatKey].leagueAverage : 0;

        return (
          <StatCell
            key={leagueStatKey}
            header={MLB_METRIC_MAP[statName].abbreviation}
            metricValue={`(${truncateMetric(leagueAverage, MLB_METRIC_MAP[statName].truncationDigits)})`}
          />
        )
      };
    };
  })

  const pitchingBodyCellData = pitchingMetricMapKeys.map((statName) => {
    let leagueStatKey = statName as keyof LeaguePitchingStats;
    
    // run differential is stored on batter data because we load pitcher data first and need both
    // pitching data and batting data to calculate it
    if (statName === 'runDifferential') {
      let leagueStatKey = statName as keyof LeagueBattingStats;
      let leagueRank = currentTeamBattingStats ? currentTeamBattingStats.teamBattingStats[leagueStatKey].leagueRank : 0;
      let metricValue = currentTeamBattingStats ? currentTeamBattingStats.teamBattingStats[leagueStatKey].metricValue : 0;
      
      return (
        <StatCell
          key={leagueStatKey}
          metricRank={leagueRank}
          metricValue={truncateMetric(metricValue, MLB_METRIC_MAP[statName].truncationDigits)}
        />
      )
    } else {
      leagueStatKey = statName as keyof LeaguePitchingStats;
      let leagueRank = currentTeamPitchingStats ? currentTeamPitchingStats.teamPitchingStats[leagueStatKey].leagueRank : 0;
      let metricValue = currentTeamPitchingStats ? currentTeamPitchingStats.teamPitchingStats[leagueStatKey].metricValue : 0;
      if (leagueStatKey !== 'pitches') {
        return (
          <StatCell
            key={leagueStatKey}
            metricRank={leagueRank}
            metricValue={truncateMetric(metricValue, MLB_METRIC_MAP[statName].truncationDigits)}
          />
        )
      };
    };
  })

  const battingHeaderCellData = battingMetricMapKeys.map((statName) => {
    let leagueStatKey = statName as keyof LeagueBattingStats;
    let leagueAverage = currentLeagueBattingMetrics ? currentLeagueBattingMetrics.leagueBattingStats[leagueStatKey].leagueAverage : 0;

    return (
      <StatCell
        key={leagueStatKey}
        header={MLB_METRIC_MAP[statName].abbreviation}
        metricValue={`(${truncateMetric(leagueAverage, MLB_METRIC_MAP[statName].truncationDigits)})`}
      />
    )
  })

  const battingBodyCellData = battingMetricMapKeys.map((statName) => {
    let leagueStatKey = statName as keyof LeagueBattingStats;
    let leagueRank = currentTeamBattingStats ? currentTeamBattingStats.teamBattingStats[leagueStatKey].leagueRank : 0;
    let metricValue = currentTeamBattingStats ? currentTeamBattingStats.teamBattingStats[leagueStatKey].metricValue : 0;

    return (
      <StatCell
        key={leagueStatKey}
        metricRank={leagueRank}
        metricValue={truncateMetric(metricValue, MLB_METRIC_MAP[statName].truncationDigits)}
      />
    );
  })

  return (
    <Grid container >
      
      <Grid xs={12} sx={{ marginBottom: '16px' }}>
        <Typography variant="h5" gutterBottom noWrap>{teamName}</Typography>
      </Grid>

      <Grid container xsOffset={0.5} xs={5} sx={{ alignItems: 'center'}}>
        <Grid xs={12}>
          <FieldSelect
            label={team}
            selectedOption={team}
            options={teamIds}
            callbackFunc={(teamCode: MLB_TEAM_CODE) => { teamHandler(teamCode) }}
          />
        </Grid>
      </Grid>
        
      <Grid container xs={5}>
        <Grid xs={4}>
          <FilterMetric
            label="Record"
            leagueRank={teamOverallStanding}
            metricOne={currentTeamWinLossData.wins}
            metricTwo={currentTeamWinLossData.losses}
          />
        </Grid>

        <Grid xs={4}>
          <FilterMetric
            label="Last 14d"
            // leagueRank={teamOverallStanding}
            // metricOne={last14dTeamWinLossData.wins}
            // metricTwo={last14dTeamWinLossData.losses}
          />
        </Grid>

        <Grid xs={4}>
          <FilterMetric
            label="Last 30d"
            // leagueRank={teamOverallStanding}
            // metricOne={last30dTeamWinLossData.wins}
            // metricTwo={last30dTeamWinLossData.losses}
          />
        </Grid>
      </Grid>


      <Grid container xs={12} sx={{ marginTop: '16px' }}>
        <Grid xs={11} xsOffset={0.5} sx={{ border: '1px solid black'}}>
          <Typography variant="subtitle1" gutterBottom noWrap>Team Pitching</Typography>
        </Grid>
        <Grid container xs={11} xsOffset={0.5} sx={{ border: '1px solid black'}}>
          {pitchingHeaderCellData}
        </Grid>
        <Grid container xs={11} xsOffset={0.5} sx={{ border: '1px solid black'}}>
          {pitchingBodyCellData}
        </Grid>


      </Grid>

      <Grid container xs={12} sx={{ marginTop: '40px' }}>
        <Grid xs={11} xsOffset={0.5} sx={{ border: '1px solid black'}}>
          <Typography variant="subtitle1" gutterBottom noWrap>Team Batting</Typography>
        </Grid>
        <Grid container xs={11} xsOffset={0.5} sx={{ border: '1px solid black'}}>
          {battingHeaderCellData}
        </Grid>
        <Grid container xs={11} xsOffset={0.5} sx={{ border: '1px solid black'}}>
          {battingBodyCellData}
        </Grid>


      </Grid>


    </Grid>
  )
};

export default TeamFilter;