import Typography from "@mui/material/Typography";
import { getNumberWithOrdinal } from "components/helpers";

export interface FilterMetricProps {
  label?: string;
  leagueRank?: number;
  metricOne?: number;
  metricTwo?: number;
};

const FilterMetric = ({
  label,
  leagueRank,
  metricOne,
  metricTwo,
}: FilterMetricProps) => {
  const ordinalRank = leagueRank ? getNumberWithOrdinal(leagueRank): "--";

  const metricLabel = label ? <Typography variant="subtitle2">{label}</Typography> : <></>

    const metricOneRankOrNA = (<Typography variant="caption">{`(${ordinalRank})`}</Typography>)

    const metricValueRow = metricTwo
    ? (<Typography variant="caption">{`${metricOne} - ${metricTwo}`}</Typography>)
    : metricOne
      ?(<Typography variant="caption">{metricOne}</Typography>)
      :(<Typography variant="caption">N/A</Typography>);
  
  return (
    <div style={{display: 'flex', flexDirection: 'column', padding: '4px', alignItems: 'center', justifyContent: 'center'}}>
      {metricLabel}
      {metricValueRow}
      {metricOneRankOrNA}
    </div>
  )
};

export default FilterMetric;