import { useSelector } from 'react-redux';
import {
  LeaguePitchingStats,
  MLBTimePeriod,
  PitchingSplit,
  PitchInfos,
} from 'reducers/types/mlbTypes';

import { Pitches } from 'config/mlb';
import { selectLeaguePlayerPitchingData } from 'reducers/mlbDataReducer';
import { MetricInfo, MetricInfos } from 'reducers/types/sharedTypes';

export interface UseAllPitchersMetrics {
  allPitchersStats: MetricInfos;
  allPitchersPitchSpecificStats: PitchInfos;
};

const useAllPitchersMetrics = (
  // pitchingGroup: PitchingGroup,
  timePeriod: MLBTimePeriod,
  pitchingSplit: PitchingSplit,
): UseAllPitchersMetrics | null => {
  
  const allPitchersData = useSelector(selectLeaguePlayerPitchingData);

  let allPitchersStats: MetricInfos = {};
  let allPitchersPitchSpecificStats: PitchInfos = {};

  for (const metric of Object.keys(allPitchersData)) {
    const allPitchersMetricKey = metric as keyof LeaguePitchingStats;

    // league based metrics
    let leagueAverageForMetric = 0;
    let leagueMinimumForMetric = 0;
    let leagueMaximumForMetric = 0;
    let leagueStdDeviationForMetric = 0;
    let correlationToWins = 0;
    
    // handle pitch related metrics
    if (allPitchersMetricKey === 'pitches') {
      for(const pitchType of Object.keys(allPitchersData[allPitchersMetricKey])) {
        const pitchKey = 'pitches';
        const pitch = pitchType as Pitches;

        const leaguePitchInfo = allPitchersData[pitchKey][pitch];

        // percentageThrow
        leagueMinimumForMetric = Math.min(...leaguePitchInfo.percentageThrow.rankedTotals);
        leagueMaximumForMetric = Math.max(...leaguePitchInfo.percentageThrow.rankedTotals);
        leagueAverageForMetric = leaguePitchInfo.percentageThrow.leagueAverage;
        leagueStdDeviationForMetric = leaguePitchInfo.percentageThrow.stdDeviation;
        correlationToWins = leaguePitchInfo.percentageThrow.correlationToWins;

        const percentageThrowMetricInfo: MetricInfo = {
          leagueAverage: leagueAverageForMetric,
          leagueMinimum: leagueMinimumForMetric,
          leagueMaximum: leagueMaximumForMetric,
          leagueRankArray: leaguePitchInfo.percentageThrow.rankedTotals,
          leagueStdDeviation: leagueStdDeviationForMetric,
          metricCorrelationToWins: correlationToWins,
        };

        // averageVelocity
        leagueMinimumForMetric = Math.min(...leaguePitchInfo.averageVelocity.rankedTotals);
        leagueMaximumForMetric = Math.max(...leaguePitchInfo.averageVelocity.rankedTotals);
        leagueAverageForMetric = leaguePitchInfo.averageVelocity.leagueAverage;
        leagueStdDeviationForMetric = leaguePitchInfo.averageVelocity.stdDeviation;
        correlationToWins = leaguePitchInfo.percentageThrow.correlationToWins;

        const averageVelocityInfo: MetricInfo = {
          leagueAverage: leagueAverageForMetric,
          leagueMinimum: leagueMinimumForMetric,
          leagueMaximum: leagueMaximumForMetric,
          leagueRankArray: leaguePitchInfo.averageVelocity.rankedTotals,
          leagueStdDeviation: leagueStdDeviationForMetric,
          metricCorrelationToWins: correlationToWins,
        };

        allPitchersPitchSpecificStats[pitch] = {
          percentageThrow: percentageThrowMetricInfo,
          averageVelocity: averageVelocityInfo,
        };

      }
    
    } else {
      leagueMinimumForMetric = Math.min(...allPitchersData[allPitchersMetricKey].rankedTotals);
      leagueMaximumForMetric = Math.max(...allPitchersData[allPitchersMetricKey].rankedTotals);
      leagueAverageForMetric = allPitchersData[allPitchersMetricKey].leagueAverage;
      leagueStdDeviationForMetric = allPitchersData[allPitchersMetricKey].stdDeviation;
      correlationToWins = allPitchersData[allPitchersMetricKey].correlationToWins;

      allPitchersStats[metric] = {
        leagueAverage: leagueAverageForMetric,
        leagueMinimum: leagueMinimumForMetric,
        leagueMaximum: leagueMaximumForMetric,
        leagueRankArray: allPitchersData[allPitchersMetricKey].rankedTotals,
        leagueStdDeviation: leagueStdDeviationForMetric,
        metricCorrelationToWins: correlationToWins,
      }
    }
  };
  
  return {
    allPitchersStats,
    allPitchersPitchSpecificStats,
  }
}

export default useAllPitchersMetrics;