import { useSelector } from 'react-redux';
import { selectCompetitionById, selectBettingOddsByCompetitionId, selectBettingPicksByCompetitionId } from 'reducers/competitionsReducer';
import { CompetitionData } from 'reducers/types/competitionTypes';
import { formatCompetitionData } from 'components/helpers';

const useCompetitionData = (competitionId: number): CompetitionData | null => {
  const competition = useSelector(selectCompetitionById(competitionId));
  const competitionBettingOdds = useSelector(selectBettingOddsByCompetitionId(competitionId));
  const competitionBettingPicks = useSelector(selectBettingPicksByCompetitionId(competitionId));

  if (!competition || !competitionBettingOdds || !competitionBettingPicks) return null;

  return formatCompetitionData(competition, competitionBettingOdds, competitionBettingPicks);
}

export default useCompetitionData;