import { useSelector } from 'react-redux';
import {
  PitchingStats,
  MLBTimePeriod,
  PitchingSplit,
  PitchInfos,
} from 'reducers/types/mlbTypes';

import { MLB_METRIC_MAP, MLB_TEAM_CODE, Pitches, PitchingGroup } from 'config/mlb';
import { selectTeamPitchingData } from 'reducers/mlbDataReducer';
import { getLeagueRankForMetric, getZScore, sumZScore } from 'components/helpers';
import {
  MetricInfos,
  IndividualMetricInfo,
  IndividualMetricInfos,
  TeamPitchInfos,
} from 'reducers/types/sharedTypes';
import { isEmpty } from 'lodash';

export interface UseTeamPitchingMetrics {
  teamPitchingStats: IndividualMetricInfos;
  teamPitchSpecificStats: TeamPitchInfos;
  teamPitchingZscore: number;
};

const useTeamPitchingMetrics = (
  teamCode: MLB_TEAM_CODE,
  // pitchingGroup: PitchingGroup,
  leaguePitchingStats: MetricInfos | undefined,
  leaguePitchSpecificStats: PitchInfos | undefined,
  timePeriod: MLBTimePeriod,
  pitchingSplit: PitchingSplit
): UseTeamPitchingMetrics | null => {
  const teamPitchingData = useSelector(selectTeamPitchingData(teamCode, timePeriod, pitchingSplit));
  
  if ((!leaguePitchingStats || isEmpty(leaguePitchingStats)) || (!leaguePitchSpecificStats || isEmpty(leaguePitchSpecificStats))) {
    return null;
  };

  

  let zScoreForCorrelatedMetrics = 0;
  let teamPitchingStats: IndividualMetricInfos = {};
  let teamPitchSpecificStats: TeamPitchInfos = {};

  for (const metric of Object.keys(teamPitchingData)) {
    const teamPitchingMetricKey = metric as keyof PitchingStats;

    let leagueMetricInfo = leaguePitchingStats[metric];
    
    // team based metrics
    let teamPitchingValueForMetric = 0;
    let teamRankForMetric = 0;
    let metricZScore = 0;

    // handle pitch related metrics
    if (teamPitchingMetricKey === 'pitches') {

      for(const pitchType of Object.keys(leaguePitchSpecificStats)) {
        const pitchKey = 'pitches';
        const pitch = pitchType as Pitches;

        const leaguePitchPercentageThrowInfo = leaguePitchSpecificStats[pitch].percentageThrow
        const teamPitchingPitchInfo = teamPitchingData[pitchKey][pitch];

        teamPitchingValueForMetric = teamPitchingPitchInfo.percentageThrow;

        metricZScore = getZScore(
          teamPitchingValueForMetric,
          leaguePitchPercentageThrowInfo.leagueAverage,
          leaguePitchPercentageThrowInfo.leagueStdDeviation,
        );

        zScoreForCorrelatedMetrics = sumZScore(leaguePitchPercentageThrowInfo.metricCorrelationToWins, zScoreForCorrelatedMetrics, metricZScore);

        const teamPercentageThrowMetricInfo: IndividualMetricInfo = {
          leagueRank: teamRankForMetric,
          metricValue: teamPitchingValueForMetric,
          metricZScore,
        }

        // averageVelocity
        const leaguePitchAverageVelocityInfo = leaguePitchSpecificStats[pitch].averageVelocity;
        teamPitchingValueForMetric = teamPitchingPitchInfo.averageVelocity;

        metricZScore = getZScore(
          teamPitchingValueForMetric,
          leaguePitchAverageVelocityInfo.leagueAverage,
          leaguePitchAverageVelocityInfo.leagueStdDeviation,
        );

        zScoreForCorrelatedMetrics = sumZScore(leaguePitchAverageVelocityInfo.metricCorrelationToWins, zScoreForCorrelatedMetrics, metricZScore);

        const teamAverageVelocityMetricInfo: IndividualMetricInfo = {
          leagueRank: teamRankForMetric,
          metricValue: teamPitchingValueForMetric,
          metricZScore,
        };

        teamPitchSpecificStats[pitch] = {
          percentageThrow: teamPercentageThrowMetricInfo,
          averageVelocity: teamAverageVelocityMetricInfo,
        };
      }
    
    } else {
      teamPitchingValueForMetric = teamPitchingData[teamPitchingMetricKey];
      let highOrLow = MLB_METRIC_MAP[teamPitchingMetricKey].highOrLow;

      teamRankForMetric = getLeagueRankForMetric(leagueMetricInfo.leagueRankArray, teamPitchingValueForMetric, highOrLow);

      metricZScore = getZScore(
        teamPitchingValueForMetric,
        leagueMetricInfo.leagueAverage,
        leagueMetricInfo.leagueStdDeviation,
        highOrLow,
      );

      zScoreForCorrelatedMetrics = sumZScore(leagueMetricInfo.metricCorrelationToWins, zScoreForCorrelatedMetrics, metricZScore);

      teamPitchingStats[metric] = {
        leagueRank: teamRankForMetric,
        metricValue: teamPitchingValueForMetric,
        metricZScore
      }
    }
  };
  
  return {
    teamPitchingStats,
    teamPitchSpecificStats,
    teamPitchingZscore: zScoreForCorrelatedMetrics,
  }
}

export default useTeamPitchingMetrics;
