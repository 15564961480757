import Grid from '@mui/material/Unstable_Grid2';
import { TeamPanel } from '../teamPanel';
import { Competition } from 'reducers/types/competitionTypes';
import { LeagueCode } from 'reducers/types/sharedTypes';
import { getTeamColors, getTeamName, setGameStatus } from 'components/helpers';
import { Scorecard } from './scorecard';

export interface ScorePlacardProps {
  selectedLeagueCode: LeagueCode;
  competition: Competition;
};

const ScorePlacard = ({ selectedLeagueCode, competition }: ScorePlacardProps) => {
  let awayTeamName = getTeamName(selectedLeagueCode, competition.awayTeamCode);
  let homeTeamName = getTeamName(selectedLeagueCode, competition.homeTeamCode);

  let awayFinalScore = competition.resultAwayScore;
  let homeFinalScore = competition.resultHomeScore;

  let competitionStatus = setGameStatus(
    competition.date,
    competition.time,
    awayFinalScore,
    homeFinalScore
  );


  return (
    <Grid container sx={{width: '100%'}}>
      <TeamPanel
        backgroundColor={getTeamColors(selectedLeagueCode, competition.awayTeamCode).primary}
        teamNameLong={awayTeamName}
        leftOrRight='left'
      />

      <TeamPanel
        backgroundColor={getTeamColors(selectedLeagueCode, competition.homeTeamCode).primary}
        teamNameLong={homeTeamName}
        leftOrRight='right'
      />

      <Scorecard
        competitionStatus={competitionStatus}
        awayProjectedScore={competition.projectedAwayScore}
        homeProjectedScore={competition.projectedHomeScore}
        awayFinalScore={awayFinalScore}
        homeFinalScore={homeFinalScore}
        homeOrAway="away"
      />

      <Scorecard
        competitionStatus={competitionStatus}
        awayProjectedScore={competition.projectedAwayScore}
        homeProjectedScore={competition.projectedHomeScore}
        awayFinalScore={awayFinalScore}
        homeFinalScore={homeFinalScore}
        homeOrAway="home"
      />
    </Grid>
  )
};

export default ScorePlacard;