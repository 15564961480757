import { useContext, useEffect, useState } from 'react';

import { MLB_TEAM_CODE } from 'config/mlb';
import {
  MLBTimePeriod,
  PitchingSplit,
  BattingSplit,
 } from 'reducers/types/mlbTypes';

import Highlights from './Highlights';
import BattingMetrics from './BattingMetrics';

import { PreAnalysisFilters } from 'components/mlbAnalyzer/preAnalysisFilters';
import PitchingMetrics from './PitchingMetrics';
import { setDefaultAnalyzerTeams } from 'components/helpers';
import { LeagueCode } from 'reducers/types/sharedTypes';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetGameInfoByGamePkQuery } from 'services/mlbStatsApiService';
import { AuthContext } from 'context/AuthContext';

export interface AnalyzerProps {
  selectedLeagueCode: LeagueCode;
}

export const Analyzer = ({ selectedLeagueCode }: AnalyzerProps) => {
  const location = useLocation()
  
  const user = useContext(AuthContext);
  
  let navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate("/")
    }
  }, []);

  const [hideUncorrelatedData, setHideUncorrelatedData] = useState<boolean>(false);

  const [timePeriod, setTimePeriod] = useState<MLBTimePeriod>('current');
  const [battingSplit, setBattingSplit] = useState<BattingSplit>('overall');
  const [pitchingSplit, setPitchingSplit] = useState<PitchingSplit>('overall');

  let awayTeamCodeParam = location?.state?.awayTeamCode;
  let homeTeamCodeParam = location?.state?.homeTeamCode;
  let awayTeamCode = awayTeamCodeParam ? awayTeamCodeParam as MLB_TEAM_CODE : null;
  let homeTeamCode = homeTeamCodeParam ? homeTeamCodeParam as MLB_TEAM_CODE : null;
  let { awayTeamCode: defaultAwayTeamCode, homeTeamCode: defaultHomeTeamCode } = setDefaultAnalyzerTeams(selectedLeagueCode);
  
  const [awayTeam, setAwayTeam] = useState<MLB_TEAM_CODE>(awayTeamCode || defaultAwayTeamCode);
  const [homeTeam, setHomeTeam] = useState<MLB_TEAM_CODE>(homeTeamCode || defaultHomeTeamCode);

  
  

  let mlbGameLink = location?.state?.mlbGameLink || '';

  // const { data: mlbPlayerData, error, isLoading } = useGetGameInfoByGamePkQuery(mlbGameLink)
  // console.log(mlbPlayerData)
  // const [awayStartingPitcher, setAwayStartingPitcher] = useState<string>(mlbPlayerData?.awayProbableStartingPitcherName || '');
  // const [homeStartingPitcher, setHomeStartingPitcher] = useState<string>(mlbPlayerData?.homeProbableStartingPitcherName || '');

  const [awayStartingPitcher, setAwayStartingPitcher] = useState<string>('');
  const [homeStartingPitcher, setHomeStartingPitcher] = useState<string>('');

  const handleAwayTeamSelection = (teamCode: MLB_TEAM_CODE) => {
    setAwayStartingPitcher('');
    setAwayTeam(teamCode);
  };

  const handleHomeTeamSelection = (teamCode: MLB_TEAM_CODE) => {
    setHomeStartingPitcher('');
    setHomeTeam(teamCode);
  };

  /**
   * When building competition render on the analyzer page
   * consider using the card views from this: https://www.si.com/mlb/schedule
   * could be used on mobile too
   */

  return (
    <>
      {!user && (<></>)}

      { user && (
        <div style={{ width: '100%' }}>
          <PreAnalysisFilters
            awayTeam={awayTeam}
            awayTeamHandler={handleAwayTeamSelection}
            homeTeam={homeTeam}
            homeTeamHandler={handleHomeTeamSelection}
            awayStartingPitcher={awayStartingPitcher}
            homeStartingPitcher={homeStartingPitcher}
            awayStartingPitcherHandler={setAwayStartingPitcher}
            homeStartingPitcherHandler={setHomeStartingPitcher}
            timePeriod={timePeriod}
            battingSplit={battingSplit}
            pitchingSplit={pitchingSplit}
          />



          {/* <Highlights
            awayTeam={awayTeam}
            homeTeam={homeTeam}
            awayStartingPitcher={awayStartingPitcher}
            homeStartingPitcher={homeStartingPitcher}
            timePeriod={timePeriod}
            battingSplit={battingSplit}
            pitchingSplit={pitchingSplit}
            hideUncorrelatedData={hideUncorrelatedData}
            setHideUncorrelatedData={setHideUncorrelatedData}
          /> */}

          <br />

          <PitchingMetrics
            awayTeam={awayTeam}
            homeTeam={homeTeam}
            timePeriod={timePeriod}
            pitchingSplit={pitchingSplit}
            hideUncorrelatedData={hideUncorrelatedData}
            // setHideUncorrelatedData={setHideUncorrelatedData}
          />

          <br />

          <BattingMetrics
            awayTeam={awayTeam}
            homeTeam={homeTeam}
            timePeriod={timePeriod}
            battingSplit={battingSplit}
            hideUncorrelatedData={hideUncorrelatedData}
            // setHideUncorrelatedData={setHideUncorrelatedData}
          />
          
        </div>
      )}
    </>
  )
};

export default Analyzer;


/**
 * would be good to add things like (last 5 games or last 10 game to catch streaky teams)
 * 
 * consider adding in if last 5 games were wins/losses and against teams with winning/losing records
 
 * get mlb average runs scored per game.  Use that against the pitchers stats per game
 * to determine their "true record". Takes the crappy offensive days out of the equation
 * when pitcher may still pitch well

*/