import Grid from '@mui/material/Unstable_Grid2';
import { Competition } from 'reducers/types/competitionTypes';
import { LeagueCode } from 'reducers/types/sharedTypes';
import { MlbAnalyzer } from './mlbAnalyzer';

interface AnalyzerProps {
  selectedLeagueCode: LeagueCode;
  competition: Competition;
};

const Analyzer = ({selectedLeagueCode, competition}: AnalyzerProps) => {

  // switch(selectedLeagueCode) {
  //   case LeagueCode.MLB:

  // }

  return (
    <Grid container>
      <Grid xs={12}>
        { selectedLeagueCode === LeagueCode.MLB && (<MlbAnalyzer competition={competition} />)}
      </Grid>
      
    </Grid>
  )
};

export default Analyzer;