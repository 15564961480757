import Box from '@mui/material/Box';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { IconButton, Modal, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { NavbarBackground } from 'images';

interface BaseModalProps {
  children: JSX.Element;
  open: boolean;
  closeModal: () => void;
  headerTitle?: string;
}

const BaseModal = ({ children, closeModal, open, headerTitle }: BaseModalProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  
  return (
    <Box sx={{}}>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby={headerTitle}
        aria-describedby={`${headerTitle} description`}
      >
        <Box 
          sx={{
            position: 'absolute',
            top: isMobileScreen ? 0 : '5vh',
            left: isMobileScreen ? 0 : '10vw',
            width: isMobileScreen ?'100vw' : '80vw',
            height: isMobileScreen ? '100%' : '90vh',
            bgcolor: 'background.paper',
            border: isMobileScreen ? 'none' : '2px solid #000',
            boxShadow: 24,
            overflowY: 'scroll',
          }}
         
        >
          <Grid container
            sx={{
              background: isMobileScreen ? theme.palette.accentTwo.main : undefined,
              backgroundImage: isMobileScreen ?  `url(${NavbarBackground})` : undefined,
              backgroundRepeat: isMobileScreen ? 'no-repeat' : 'repeat',
              height: '80px',
            }}
          >
            <Grid xs={10} sx={{ textAlign: 'center' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1.5, ml: isMobileScreen ? 0 : 20 }}>
                <Typography variant="h5" sx={{color: "rgba(0, 0, 0, 0.87)" }}>{headerTitle}</Typography>
              </Box>
              
            </Grid>

            <Grid xs={2} sx={{textAlign: 'right'}}>
              <IconButton aria-label="close" size='large' onClick={closeModal} sx={{ background: 'white', borderRadius: 0}}>
                <CloseOutlinedIcon sx={{color: "rgba(0, 0, 0, 0.87)" }} fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
          
          {children}
        </Box>
      </Modal>
    </Box>
  );
}


export default BaseModal;