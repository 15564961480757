import { MLB_COLOR_CODES, MLB_SL_TEAM_CODE, MLB_TEAM_CODE, MLB_TEAM_NAME_LONG } from "config/mlb";
import { LeagueCode } from "reducers/types/sharedTypes";
import * as oddslib from 'oddslib';
import { formatNumberForDisplay } from "./formatters";
import { CompetitionStatus, PickEntity } from "config/competitions";
import { BettingOdds, BettingPicks, Competition, CompetitionData, CompetitionOddsByType, CompetitionPicksByType, HomeOrAway, MoneylineOdds, MoneylinePick, OverUnderOdds, OverUnderPick, SpreadOdds, SpreadPick } from "reducers/types/competitionTypes";
import moment from "moment";
import { NCAA_COLOR_CODES, NCAA_TEAM_CODE, NCAA_TEAM_NAME_LONG } from '../../config/ncaa';
import { NFL_COLOR_CODES, NFL_TEAM_CODE, NFL_TEAM_NAME_LONG } from "config/nfl";

export const americanOddsToPercentage = (americanOdds: number): number => {
  // const isUnderdog = Number(americanOdds) > 0;
  // const oddsOffset = Number(Math.abs(americanOdds)) + 100;
  // return isUnderdog ? 100 / oddsOffset : Number(Math.abs(americanOdds)) / oddsOffset;
  const odds = oddslib.from('moneyline', americanOdds);
  return odds.to('impliedProbability')
};

export const percentageToAmericanOdds = (percentage: number) => {
  // const isUnderdog = percentage < 0.50;
  // return isUnderdog ? (100 / percentage) - 100 : ((60 * 100) / (1 - (percentage))) * -1;
  const odds = oddslib.from('impliedProbability', percentage);
  return formatNumberForDisplay(Math.round(odds.to('moneyline')));
};

export const standardizeTeamCode = (
  leagueCode: LeagueCode,
  teamCode: string,
): MLB_TEAM_CODE => {
  // there is no other use case for now so stash the conditional
  // if (LeagueCode.MLB) {
  //   return MLB_SL_TEAM_CODE[teamCode];
  // }

  return MLB_SL_TEAM_CODE[teamCode];
};

export const isPublicOrMoneyBetEntity = (pickEntity: PickEntity) => {
  return pickEntity === PickEntity.PUBLIC || pickEntity === PickEntity.MONEY_BET;
};

export const isOddsmakersOrSLEntity = (pickEntity: PickEntity) => {
  return pickEntity === PickEntity.ODDSMAKERS || pickEntity === PickEntity.SL;
};

export const gameWinner = (competitionStatus: CompetitionStatus, awayTeamTotalPoints: number, homeTeamTotalPoints: number): HomeOrAway | 'split' => {
  if (competitionStatus !== CompetitionStatus.COMPLETED) return 'split';
  
  let awayMargin = awayTeamTotalPoints - homeTeamTotalPoints
  if (awayMargin > 0) {
    return 'away';
  } else if (awayMargin < 0) {
    return 'home';
  } else {
    return 'split';
  }
};

export const determineSpreadWinner = (
  spreadPick: HomeOrAway | 'split',
  spreadPoints: number,
  awayTeamTotalPoints: number,
  homeTeamTotalPoints: number,
) => {
  let pickIsFavorite = spreadPoints < 0;
  let isSuccess = false;

  if (spreadPick === 'split') {
   return isSuccess; 
  }

  if (pickIsFavorite) {
    if (spreadPick === 'home') {
      isSuccess = homeTeamTotalPoints - awayTeamTotalPoints > Math.abs(spreadPoints);
    }

    if (spreadPick === 'away') {
      isSuccess = awayTeamTotalPoints - homeTeamTotalPoints > Math.abs(spreadPoints);
    }
  } else {
    if (spreadPick === 'home') {
      isSuccess = awayTeamTotalPoints - homeTeamTotalPoints < spreadPoints;
    }

    if (spreadPick === 'away') {
      isSuccess = homeTeamTotalPoints - awayTeamTotalPoints < spreadPoints;
    }
  }

  return isSuccess;
}

export const setDefaultAnalyzerTeams = (leagueCode: LeagueCode) => {
  // if (leagueCode === LeagueCode.MLB) {
  //   return { awayTeamCode: MLB_TEAM_CODE.HOU, homeTeamCode: MLB_TEAM_CODE.COL };
  // }

  return { awayTeamCode: MLB_TEAM_CODE.HOU, homeTeamCode: MLB_TEAM_CODE.COL };
};

/**
 * This could be simplify to just having home and away scores
 * if the date proves messy. 
 * @param competitionDate "08-03-2024"
 * @param competitionTime "14:05"
 * @param awayFinalScore
 * @param homeFinalScore 
 * @returns 
 */
export const setGameStatus = (
  competitionDate: string,
  competitionTime: string,
  awayFinalScore = 0,
  homeFinalScore = 0,
): CompetitionStatus => {
  let denverTimezone = "America/Denver";
  const momentCurrentDate = moment().tz(denverTimezone);
  const builtCompetitionDate = new Date(competitionDate + ' ' + competitionTime);
  const momentCompetitionDate = moment.tz(builtCompetitionDate, denverTimezone);

  // TODO: When more status are added, remove this because it's not
  // a tride and true method for determining completed games. What happens
  // when a game ends in a 0 - 0 tie.
  if ((awayFinalScore + homeFinalScore !== 0)) {
    return CompetitionStatus.COMPLETED;
  } else if (momentCompetitionDate < momentCurrentDate) {
    return CompetitionStatus.NOT_STARTED;
  }

  // TODO: eventually more statuses can be built in like in-progress, but
  //       that requires more effort than needed for now so just return 'before'
  return CompetitionStatus.NOT_STARTED;
};

export const getTeamName = (
  selectedLeagueCode: LeagueCode,
  teamCode: MLB_TEAM_CODE | NCAA_TEAM_CODE | NFL_TEAM_CODE,
) => {
  if (selectedLeagueCode === LeagueCode.MLB) {
    let mlbTeamCode = teamCode as MLB_TEAM_CODE;
    return MLB_TEAM_NAME_LONG[mlbTeamCode];

  } else if (selectedLeagueCode === LeagueCode.NFL) {
    let nflTeamCode = teamCode as NFL_TEAM_CODE;
    return NFL_TEAM_NAME_LONG[nflTeamCode];

  } else if (selectedLeagueCode === LeagueCode.NCAAF || selectedLeagueCode === LeagueCode.NCAAM) {
    let ncaaTeamCode = teamCode as NCAA_TEAM_CODE;
    return NCAA_TEAM_NAME_LONG[ncaaTeamCode];

  } else {
    return teamCode;
  }
};

export const getTeamColors = (
  selectedLeagueCode: LeagueCode,
  teamCode: MLB_TEAM_CODE | NCAA_TEAM_CODE | NFL_TEAM_CODE,
) => {
  if (selectedLeagueCode === LeagueCode.MLB) {
    let mlbTeamCode = teamCode as MLB_TEAM_CODE;
    return MLB_COLOR_CODES[mlbTeamCode];

  } else if (selectedLeagueCode === LeagueCode.NFL) {
    let nflTeamCode = teamCode as NFL_TEAM_CODE;
    return NFL_COLOR_CODES[nflTeamCode];

  } else if (selectedLeagueCode === LeagueCode.NCAAF || selectedLeagueCode === LeagueCode.NCAAM) {
    let ncaaTeamCode = teamCode as NCAA_TEAM_CODE;
    return NCAA_COLOR_CODES[ncaaTeamCode];

  } else {
    return { primary: '#FF7F00', secondary: '#FF7F00'};
  }
};



export const formatCompetitionData = (
  competition: Competition,
  bettingOdds: BettingOdds,
  bettingPicks: BettingPicks,
): CompetitionData => {

  // betting odds
  let competitionOddsByType: CompetitionOddsByType = {
    spreadOdds: [],
    moneylineOdds: [],
    overUnderOdds: [],
  };

  for (const odd in bettingOdds) {
    if (bettingOdds[odd].oddsType === 'spread') {
      competitionOddsByType.spreadOdds.push(bettingOdds[odd] as SpreadOdds);
    }

    if (bettingOdds[odd].oddsType === 'moneyline') {
      competitionOddsByType.moneylineOdds.push(bettingOdds[odd] as MoneylineOdds);
    }

    if (bettingOdds[odd].oddsType === 'over-under') {
      competitionOddsByType.overUnderOdds.push(bettingOdds[odd] as OverUnderOdds);
    }
  };


    // betting picks
  let competitionPicksByType: CompetitionPicksByType = {
    spreadPicks: [],
    moneylinePicks: [],
    overUnderPicks: [],
  };

  for(const odd in bettingPicks) {
    if (bettingPicks[odd].pickType === 'spread') {
      competitionPicksByType.spreadPicks.push(bettingPicks[odd] as SpreadPick);
    }

    if (bettingPicks[odd].pickType === 'moneyline') {
      competitionPicksByType.moneylinePicks.push(bettingPicks[odd] as MoneylinePick);
    }

    if (bettingPicks[odd].pickType === 'over-under') {
      competitionPicksByType.overUnderPicks.push(bettingPicks[odd] as OverUnderPick);
    }
  }

  return {
    competition: competition,
    competitionOddsData: competitionOddsByType,
    competitionPicksData: competitionPicksByType,
  };
};

// move this to a pick entity config object and merge it with PickEntity
export const pickEntityColor = (pickEntity: PickEntity) => {
  switch(pickEntity) {
    case PickEntity.SL:
      return 'green';
    case PickEntity.ODDSMAKERS:
      return 'black';
    case PickEntity.BET_WISER:
      return 'orange';
    case PickEntity.PUBLIC:
      return 'magenta';
    case PickEntity.MONEY_BET:
      return 'cyan';
    default:
      return 'maroon';
  }
};