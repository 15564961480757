import { Dispatch, SetStateAction } from "react";
import { useSelector } from 'react-redux';

import { MLB_METRIC_MAP, MLB_TEAM_CODE, MLB_TEAM_NAME_LONG } from 'config/mlb';
import {
  MLBTimePeriod,
  PitchingSplit,
  BattingSplit,
 } from 'reducers/types/mlbTypes';
import {
  selectTeamIds,
  selectTeamPitchers,
  selectTeamWinsAndLossesSelector,
} from 'reducers/mlbDataReducer';

import { getLeagueRankForMetric, getNumberWithOrdinal, getPitcherNames } from 'components/helpers';

import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { FieldSelect } from 'components/library';
import { FilterMetric, TeamFilter } from "components/mlbAnalyzer/preAnalysisFilters";
import usePitcherMetrics from "components/helpers/usePitcherMetrics";
import useTeamPitchingMetrics from "components/helpers/useTeamPitchingMetrics";
import useLeaguePitchingMetrics from "components/helpers/useLeaguePitchingMetrics";
import useAllPitchersMetrics from "components/helpers/useAllPitchersMetrics";

// styles;
const genericPaperStyles = { height: '50px', borderRadius: '0px', boxShadow: 0, padding: '12.5px' };
const leftPaperStyles = { borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' };
const rightPaperStyles = { borderTopRightRadius: '4px', borderBottomRightRadius: '4px' };

export interface PreAnalysisFiltersProps {
  homeTeam: MLB_TEAM_CODE;
  awayTeam: MLB_TEAM_CODE;
  homeTeamHandler: (teamCode: MLB_TEAM_CODE) => void;
  awayTeamHandler: (teamCode: MLB_TEAM_CODE) => void;
  awayStartingPitcher: string;
  homeStartingPitcher: string;
  awayStartingPitcherHandler: Dispatch<SetStateAction<string>>;
  homeStartingPitcherHandler: Dispatch<SetStateAction<string>>;
  timePeriod: MLBTimePeriod;
  battingSplit: BattingSplit;
  pitchingSplit: PitchingSplit;
};

const PreAnalysisFilters = ({
  homeTeam,
  awayTeam,
  homeTeamHandler,
  awayTeamHandler,
  awayStartingPitcher,
  homeStartingPitcher,
  awayStartingPitcherHandler,
  homeStartingPitcherHandler,
  timePeriod,
  battingSplit,
  pitchingSplit,
}: PreAnalysisFiltersProps) => {
  let teamIds = useSelector(selectTeamIds);
  let awayTeamPitchers = useSelector(selectTeamPitchers(awayTeam));
  let homeTeamPitchers = useSelector(selectTeamPitchers(homeTeam));

  const leaguePitchingMetrics = useLeaguePitchingMetrics(timePeriod, pitchingSplit);
  const allPitchersMetrics = useAllPitchersMetrics(timePeriod, pitchingSplit);

  const awayTeamPitchingStats = useTeamPitchingMetrics(
    awayTeam,
    leaguePitchingMetrics?.leaguePitchingStats,
    leaguePitchingMetrics?.leaguePitchSpecificStats,
    timePeriod,
    pitchingSplit
  );
  const homeTeamPitchingStats = useTeamPitchingMetrics(
    homeTeam,
    leaguePitchingMetrics?.leaguePitchingStats,
    leaguePitchingMetrics?.leaguePitchSpecificStats,
    timePeriod,
    pitchingSplit
  );

  const awayPitcherData = usePitcherMetrics(
    awayStartingPitcher,
    awayTeam,
    allPitchersMetrics?.allPitchersStats,
    allPitchersMetrics?.allPitchersPitchSpecificStats,
    timePeriod,
    pitchingSplit
  );
  const homePitcherData = usePitcherMetrics(
    homeStartingPitcher,
    homeTeam,
    allPitchersMetrics?.allPitchersStats,
    allPitchersMetrics?.allPitchersPitchSpecificStats,
    timePeriod,
    pitchingSplit
  );

  let awayPitcherWinLossData = { wins: awayPitcherData ? awayPitcherData.pitchingStats.wins.metricValue : 0, losses: awayPitcherData ? awayPitcherData.pitchingStats.losses.metricValue : 0 };
  let homePitcherWinLossData = { wins: homePitcherData ? homePitcherData.pitchingStats.wins.metricValue : 0, losses: homePitcherData ? homePitcherData.pitchingStats.losses.metricValue : 0 };

  let awayTeamName = MLB_TEAM_NAME_LONG[awayTeam];
  let homeTeamName = MLB_TEAM_NAME_LONG[homeTeam];

  let awayTeamWinLossData = useSelector(selectTeamWinsAndLossesSelector(awayTeam, timePeriod, pitchingSplit));
  let homeTeamWinLossData = useSelector(selectTeamWinsAndLossesSelector(homeTeam, timePeriod, pitchingSplit)); 
  let awayTeamOverallStanding = awayTeamPitchingStats ? awayTeamPitchingStats?.teamPitchingStats.wins.leagueRank : undefined;
  let homeTeamOverallStanding = homeTeamPitchingStats ? homeTeamPitchingStats?.teamPitchingStats.wins.leagueRank : undefined;

    // leave this in here for now as a short stop gap until UI can be rebuilt
  if (awayPitcherData && homePitcherData) {
    console.log('a')
    for (const pitch in awayPitcherData.pitchSpecificStats) {
      console.log(pitch, awayPitcherData.pitchSpecificStats[pitch].percentageThrow.metricValue)
    }

    console.log('h')
    for (const pitch in homePitcherData.pitchSpecificStats) {
      console.log(pitch, homePitcherData.pitchSpecificStats[pitch].percentageThrow.metricValue)
    }

    for (const stat in awayPitcherData.pitchingStats) {
      if (
        stat === 'wins' ||
        stat === 'era' ||
        stat === 'whip' ||
        stat === 'leftOnBasePercentage' ||
        stat === 'xfip' ||
        stat === 'battingAverageAgainst'
      ) {
        console.log(`${stat}:`, 'a', awayPitcherData.pitchingStats[stat].metricValue, "|", 'h', homePitcherData.pitchingStats[stat].metricValue)
      } 
    }
  }


  return (

    <Grid container textAlign="center" data-testid="pre-analysis-filters">
      <Grid container xs={4.5} xsOffset={1.45}>
        <TeamFilter
          team={awayTeam}
          teamHandler={awayTeamHandler}
          pitchingSplit={pitchingSplit}
          battingSplit={battingSplit}
        />
      </Grid>
      <Grid container xs={4.5} xsOffset={0.1}>
        <TeamFilter
          team={homeTeam}
          teamHandler={homeTeamHandler}
          pitchingSplit={pitchingSplit}
          battingSplit={battingSplit}
        />
      </Grid>

      <Grid container xs={4.5} xsOffset={1.5} sx={{...genericPaperStyles, ...leftPaperStyles, height: '200px',  }}>

        <>
          <Grid xs={6}>
            <FieldSelect
              label="Away Starting Pitcher"
              selectedOption={awayStartingPitcher}
              options={getPitcherNames(awayTeamPitchers)}
              disabled={!awayTeam}
              callbackFunc={awayStartingPitcherHandler}
            />
          </Grid>

          <Grid container xs={6}>
            <Grid xs={4}>
              <FilterMetric
                // leagueRank={}
                metricOne={awayPitcherWinLossData.wins}
                metricTwo={awayPitcherWinLossData.losses}
              />
            </Grid>

            <Grid xs={4}>
              <FilterMetric
                // leagueRank={}
                // metricOne={awayPitcherWinLossData.wins}
                // metricTwo={awayPitcherWinLossData.losses}
              />
            </Grid>

            <Grid xs={4}>
              <FilterMetric
                // leagueRank={}
                // metricOne={awayPitcherWinLossData.wins}
                // metricTwo={awayPitcherWinLossData.losses}
              />
            </Grid>
          </Grid>
        </>
      </Grid>

      <Grid container xs={4.5} sx={{...genericPaperStyles, ...rightPaperStyles, height: '200px' }}>
        <>
          <Grid xs={6}>
            <FieldSelect
              label="Home Starting Pitcher"
              selectedOption={homeStartingPitcher}
              options={getPitcherNames(homeTeamPitchers)}
              disabled={!homeTeam}
              callbackFunc={homeStartingPitcherHandler}
            />
          </Grid>

          <Grid container xs={6}>
            <Grid xs={4}>
              <FilterMetric
                // leagueRank={}
                metricOne={homePitcherWinLossData.wins}
                metricTwo={homePitcherWinLossData.losses}
              />
            </Grid>

            <Grid xs={4}>
              <FilterMetric
                // leagueRank={}
                // metricOne={homePitcherWinLossData.wins}
                // metricTwo={homePitcherWinLossData.losses}
              />
            </Grid>

            <Grid xs={4}>
              <FilterMetric
                // leagueRank={}
                // metricOne={homePitcherWinLossData.wins}
                // metricTwo={homePitcherWinLossData.losses}
              />
            </Grid>
          </Grid>
        </>
      </Grid>
    </Grid>
  )
};

export default PreAnalysisFilters;