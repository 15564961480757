import { Box, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { getNumberWithOrdinal } from "components/helpers";

export interface StandingProps {
  standingHeader: string;
  awayTeamWins?: number;
  awayTeamLosses?: number;
  awayTeamLeagueRank?: number;
  homeTeamWins?: number;
  homeTeamLosses?: number;
  homeTeamLeagueRank?: number;
};

const Standing = ({
  standingHeader,
  awayTeamWins = 0,
  awayTeamLosses = 0,
  awayTeamLeagueRank = 0,
  homeTeamWins = 0,
  homeTeamLosses = 0,
  homeTeamLeagueRank = 0,
}: StandingProps) => {
  let theme = useTheme();

  let awayRecordText = `${awayTeamWins} - ${awayTeamLosses}`;
  if (awayTeamWins === 0 && awayTeamLosses === 0) {
    awayRecordText = 'N/A'
  };

  let awayLeagueRankText = getNumberWithOrdinal(awayTeamLeagueRank);
  if (awayTeamLeagueRank === 0) {
    awayLeagueRankText = 'N/A'
  }

  let homeRecordText = `${homeTeamWins} - ${homeTeamLosses}`;
  if (homeTeamWins === 0 && homeTeamLosses === 0) {
    homeRecordText = 'N/A'
  };

  let homeLeagueRankText = getNumberWithOrdinal(homeTeamLeagueRank);
  if (homeTeamLeagueRank === 0) {
    homeLeagueRankText = 'N/A'
  }

  let leftColor = theme.palette.primary.main;
  let rightColor = theme.palette.primary.main;
  let awayWinPercentage = awayTeamWins / (awayTeamWins + awayTeamLosses);
  let homeWinPercentage = homeTeamWins / (homeTeamWins + homeTeamLosses);
  if (awayWinPercentage > homeWinPercentage) {
    rightColor = theme.palette.grey[500];
  };

  if (homeWinPercentage > awayWinPercentage) {
    leftColor = theme.palette.grey[500];
  };

  return (
    <Grid container xs={12} sx={{p: 2}}>
      <Grid xs={4.5} sx={{ border: homeWinPercentage > awayWinPercentage ? 'none' : `1px solid ${leftColor}` }}>
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="caption" color="primary"><strong>{awayRecordText}</strong></Typography>
          <Typography variant="caption">{`(${awayLeagueRankText})`}</Typography>
        </Box>
      </Grid>

      <Grid xs={3}>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Box>
            <ArrowLeftIcon fontSize="large" sx={{ color: leftColor }} />
          </Box>
          <Typography variant="caption"><strong>{standingHeader.toUpperCase()}</strong></Typography>
          <Box>
            <ArrowRightIcon fontSize="large" sx={{ color: rightColor }} />
          </Box>

        </Box>
      </Grid>

      <Grid xs={4.5} sx={{ border: awayWinPercentage > homeWinPercentage ? 'none' : `1px solid ${rightColor}` }}>
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="caption" color="primary"><strong>{homeRecordText}</strong></Typography>
          <Typography variant="caption">{`(${homeLeagueRankText})`}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Standing;