import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { truncateMetric } from 'components/helpers';
import { HomeOrAway } from 'reducers/types/competitionTypes';

export interface ImpactScoreProps {
  statValue: number | string;
  team: HomeOrAway;
  statWinner: HomeOrAway | 'split';
}

const ImpactScore = ({statValue, statWinner, team}: ImpactScoreProps) => {
  const formattedStat = typeof statValue === 'string' ? statValue : truncateMetric(statValue);

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

      { team === 'home' && (
        <div style={{ display: 'flex', justifyContent: 'center', flexGrow: 1, alignItems: 'center' }}>
          {statWinner === team && (<CheckCircleIcon sx={{ fontSize: 'xlarge', color: 'green' }} />)}
        </div>
      )}

      <div style={{ display: 'flex', justifyContent: 'center', flexGrow: 2, alignItems: 'center' }}>

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flexGrow: 2, alignItems: 'center' }}>
          <Typography variant="overline" gutterBottom noWrap aria-label="Impact Score">Impact Score&nbsp;&nbsp;</Typography>
          <Typography variant="subtitle2" gutterBottom>{formattedStat}</Typography>
        </div>

      </div>

      { team === 'away' && (
        <div style={{ display: 'flex', justifyContent: 'center', flexGrow: 1, alignItems: 'center' }}>
          {statWinner === team && (<CheckCircleIcon sx={{ fontSize: 'xlarge', color: 'green' }} />)}
        </div>
      )}

    </div>
    )
};

export default ImpactScore;