import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import { getFirstName, getLastName } from "components/helpers";

export interface TeamPanelProps {
  backgroundColor: string; // team primary color
  textColor?: string; // white or black preferred
  teamNameLong: string;
  leftOrRight: 'left' | 'right';
};

const TeamPanel = ({ backgroundColor, textColor = '#fff', teamNameLong, leftOrRight }: TeamPanelProps) => {
  let sideBorderClasses = leftOrRight === 'left' ? { borderRight: '0.5px solid #fff' } : { borderLeft: '0.5px solid #fff' };

  return (
    <Grid
      xs={6}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        mb: 2,
        background: backgroundColor,
        border: '1px solid #fff',
        ...sideBorderClasses,
      }}
    >
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 0, mb: 0 }}>
        <Typography
          variant="h5"
          sx={{
            fontFamily: 'College Regular',
            mt: 1,
            letterSpacing: '0.5px',
            lineHeight: '1',
            color: textColor,
            fontSize: "1.4rem",
            // fontWeight: 1000,
            // "-webkit-text-stroke-width": "0.2px",
            // "-webkit-text-stroke-color": "black",
          }}
          // noWrap
        >
          {getFirstName(teamNameLong).toUpperCase()}
        </Typography>
        <Typography variant="subtitle1" sx={{fontFamily: 'College Regular', mb: 1, letterSpacing: '0.5px' , color: textColor}}>{getLastName(teamNameLong).toUpperCase()}</Typography>
      </Box>
    </Grid>
  )
};

export default TeamPanel;