import { Box, Divider, Typography, useMediaQuery, useTheme } from "@mui/material";
import { formatNumberForDisplay, formatOverUnderForDisplay, getBoldTeamColor, getTeamColors, getTeamName, percentageToAmericanOdds } from "components/helpers";
import { OverOrUnder } from "config/competitions";
import { MLB_TEAM_NAME_LONG } from "config/mlb";
import { Competition, SpreadPick, MoneylinePick, OverUnderPick, MoneylineOdds, SpreadOdds, OverUnderOdds } from "reducers/types/competitionTypes";
import { LeagueCode } from "reducers/types/sharedTypes";
import { PickPlotBarHeader } from "./pickPlotBarHeader";
import { PickEntityDisplay } from "./pickEntityDisplay";
import { PickDisplay } from "./pickDisplay";

export interface PickPlotBar {
  selectedLeagueCode: LeagueCode;
  competition: Competition;
  competitionPicks: SpreadPick[] | MoneylinePick[] | OverUnderPick[];
  leftOdd: MoneylineOdds | SpreadOdds | OverUnderOdds;
  rightOdd: MoneylineOdds | SpreadOdds | OverUnderOdds;
};

const PickPlotBar = ({selectedLeagueCode, competition, competitionPicks, leftOdd, rightOdd}: PickPlotBar) => {
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'));
  const awayTeamColor = getTeamColors(selectedLeagueCode, competition.awayTeamCode).secondary;
  const homeTeamColor = getTeamColors(selectedLeagueCode, competition.homeTeamCode).primary;

  const pickDisplays: JSX.Element[] = [];
  const pickEntityDisplay: JSX.Element[] = [];

  competitionPicks.map((pick, index) => {
    if (pick.percentage) {
      pickDisplays.push(
        <PickDisplay key={`${pick.entity}-pick-display-${index}`} pick={pick} />
      )

      pickEntityDisplay.push(<PickEntityDisplay key={`${pick.entity}-entity-display${index}`} pick={pick} />)
    }
  })

  let leftSubCaptionBetInfo;
  let leftSubCaptionOdds = percentageToAmericanOdds(leftOdd.percentage);
  let rightSubCaptionBetInfo;
  let rightSubCaptionOdds  = percentageToAmericanOdds(rightOdd.percentage);
  if (leftOdd.oddsType === 'spread' && rightOdd.oddsType === 'spread') {
    leftSubCaptionBetInfo = formatNumberForDisplay(leftOdd.spreadValue);
    rightSubCaptionBetInfo = formatNumberForDisplay(rightOdd.spreadValue);
  } else if (leftOdd.oddsType === 'moneyline' && rightOdd.oddsType === 'moneyline') {
    leftSubCaptionBetInfo =  '';
    rightSubCaptionBetInfo = '';
  } else if (leftOdd.oddsType === 'over-under' && rightOdd.oddsType === 'over-under') {
    leftSubCaptionBetInfo = formatOverUnderForDisplay(leftOdd.overOrUnder as OverOrUnder, leftOdd.totalPoints)
    rightSubCaptionBetInfo = formatOverUnderForDisplay(rightOdd.overOrUnder as OverOrUnder, rightOdd.totalPoints)
  }

  return (
    <Box sx={{ mt: 5, ml: 2, mr: 2, mb: 15 }}>
      
      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <PickPlotBarHeader
          leftOrRight="left"
          teamName={getTeamName(selectedLeagueCode, competition.awayTeamCode)}
          subCaptionBetInfo={leftSubCaptionBetInfo}
          subCaptionOdds={leftSubCaptionOdds}
        />

        <PickPlotBarHeader
          leftOrRight="right"
          teamName={getTeamName(selectedLeagueCode, competition.homeTeamCode)}
          subCaptionBetInfo={rightSubCaptionBetInfo}
          subCaptionOdds={rightSubCaptionOdds}
        />
      </Box>
      
      
      
      <Box sx={{position: 'relative'}}>

        {/* left end */}
        <Box sx={{ width: '4px', height: '25px', position: 'absolute', left: 0, top: 20 }}>
          <Divider orientation="vertical" sx={{ border: `2px solid ${getBoldTeamColor(awayTeamColor)}`}} />
        </Box>

        {/* right end */}
        <Box sx={{ width: '4px', height: '25px', position: 'absolute', right: 0, top: 20 }}>
          <Divider orientation="vertical" sx={{ border: `2px solid ${getBoldTeamColor(homeTeamColor)}`}} />
        </Box>

        <Box sx={{
          position: 'absolute',
          width: '100%',
          height: '10px',
          // TODO: make this linear gradient adjust based on the percentage favorite?
          //       this code could also basically be used for showing stats favorites
          //       and the angled placard work overlaps with team panel work
          //       use https://jsfiddle.net/diegoh/mXLgF/1154/ to make slanted bar at 50%
          background: `linear-gradient(0.25turn, ${awayTeamColor}, #ebf8e1, ${homeTeamColor})`,
          top: 30,
        }}>

          {/* center */}
          <Box
            sx={{ 
              position: 'absolute', 
              left: isTabletOrMobile ? '46.5%' : '49%',
              top: -26.0,
              zIndex: 10 
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="caption">50%</Typography>
              <Divider orientation="vertical" sx={{ height: '20px', border: '1px solid lightGray'}} />
            </Box>
          </Box>

          {pickDisplays}

        </Box>
      </Box>
      
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'space-evenly',
          width: '100%',
          mt: 10,
          height: '10px',

        }}
      >
        {pickEntityDisplay}
      </Box>

    </Box>
  )
};

export default PickPlotBar;