export enum NFL_TEAM_CODE {
  ARI = 'ARI',
  ATL = 'ATL',
  BAL = 'BAL',
  BUF = 'BUF',
  CAR = 'CAR',
  CHI = 'CHI',
  CIN = 'CIN',
  CLE = 'CLE',
  DAL = 'DAL',
  DEN = 'DEN',
  DET = 'DET',
  GB = 'GB',
  HOU = 'HOU',
  IND = 'IND',
  JAC = 'JAC',
  KC = 'KC',
  LV = 'LV',
  LAC = 'LAC',
  LAR = 'LAR',
  MIA = 'MIA',
  MIN = 'MIN',
  NE = 'NE',
  NO = 'NO',
  NYG = 'NYG',
  NYJ = 'NYJ',
  PHI = 'PHI',
  PIT = 'PIT',
  SF = 'SF',
  SEA = 'SEA',
  TB = 'TB',
  TEN = 'TEN',
  WAS = 'WAS'
};

export enum NFL_TEAM_NAME_LONG {
  ARI = 'Arizona Cardinals',
  ATL = 'Atlanta Falcons',
  BAL = 'Baltimore Ravens',
  BUF = 'Buffalo Bills',
  CAR = 'Carolina Panthers',
  CHI = 'Chicago Bears',
  CIN = 'Cincinnati Bengals',
  CLE = 'Cleveland Browns',
  DAL = 'Dallas Cowboys',
  DEN = 'Denver Broncos',
  DET = 'Detriot Lions',
  GB = 'Green Bay Packers',
  HOU = 'Houston Texans',
  IND = 'Indianapolis Colts',
  JAC = 'Jacksonville Jaguars',
  KC = 'Kansas City Chiefs',
  LV = 'Las Vegas Raiders',
  LAC = 'Los Angeles Chargers',
  LAR = 'Los Angeles Rams',
  MIA = 'Miami Dolphins',
  MIN = 'Minnesota Vikings',
  NE = 'New England Patriots',
  NO = 'New Orleans Saints',
  NYG = 'New York Giants',
  NYJ = 'New York Jets',
  PHI = 'Philadelphia Eagles',
  PIT = 'Pittsburgh Steelers',
  SF = 'San Francisco 49ers',
  SEA = 'Seattle Seahawks',
  TB = 'Tampa Bay Buccaneers',
  TEN = 'Tennessee Titans',
  WAS = 'Washington Commanders'
};

export const NFL_COLOR_CODES: {  [key in NFL_TEAM_CODE]: { primary: string, secondary: string } } = {
  ARI: { primary: 'rgb(151, 35, 63, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  ATL: { primary: 'rgb(167, 25, 48, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  BAL: { primary: 'rgb(26, 25, 95, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  BUF: { primary: 'rgb(0, 51, 141, 0.75)', secondary: 'rgb(198, 12, 48, 0.75)' },
  CAR: { primary: 'rgb(0, 133, 202, 0.75)', secondary: 'rgb(16, 24, 32, 0.75)' },
  CHI: { primary: 'rgb(11, 22, 42, 0.75)', secondary: 'rgb(200, 56, 3, 0.75)' },
  CIN: { primary: 'rgb(251, 79, 20, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  CLE: { primary: 'rgb(49, 29, 0, 0.75)', secondary: 'rgb(255, 60, 0, 0.75)' },
  DAL: { primary: 'rgb(0, 34, 68, 0.75)', secondary: 'rgb(134, 147, 151, 0.75)' },
  DEN: { primary: 'rgb(251, 79, 20, 0.75)', secondary: 'rgb(0, 34, 68, 0.75)' },
  DET: { primary: 'rgb(0, 118, 182, 0.75)', secondary: 'rgb(176, 183, 188, 0.75)' },
  GB: { primary: 'rgb(24, 48, 40, 0.75)', secondary: 'rgb(255, 184, 28, 0.75)' },
  HOU: { primary: 'rgb(3, 32, 47, 0.75)', secondary: 'rgb(167, 25, 48, 0.75)' },
  IND: { primary: 'rgb(0, 44, 95, 0.75)', secondary: 'rgb(162, 170, 173, 0.75)' },
  JAC: { primary: 'rgb(0, 103, 120, 0.75)', secondary: 'rgb(16, 24, 32, 0.75)' },
  KC: { primary: 'rgb(227, 24, 55, 0.75)', secondary: 'rgb(255, 184, 28, 0.75)' },
  LV: { primary: 'rgb(0, 0, 0, 0.75)', secondary: 'rgb(165, 172, 175, 0.75)' },
  LAC: { primary: 'rgb(0, 128, 198, 0.75)', secondary: 'rgb(255, 194, 14, 0.75)' },
  LAR: { primary: 'rgb(0, 53, 148, 0.75)', secondary: 'rgb(255, 163, 0, 0.75)' },
  MIA: { primary: 'rgb(0, 142, 151, 0.75)', secondary: 'rgb(252, 76, 2, 0.75)' },
  MIN: { primary: 'rgb(79, 38, 131, 0.75)', secondary: 'rgb(255, 198, 47, 0.75)' },
  NE: { primary: 'rgb(0, 34, 68, 0.75)', secondary: 'rgb(198, 12, 48, 0.75)' },
  NO: { primary: 'rgb(211, 188, 141, 0.75)', secondary: 'rgb(16, 24, 31, 0.75)' },
  NYG: { primary: 'rgb(1, 35, 82, 0.75)', secondary: 'rgb(163, 13, 45, 0.75)' },
  NYJ: { primary: 'rgb(18, 87, 64, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  PHI: { primary: 'rgb(0, 76, 84, 0.75)', secondary: 'rgb(165, 172, 175, 0.75)' },
  PIT: { primary: 'rgb(255, 182, 18, 0.75)', secondary: 'rgb(16, 24, 32, 0.75)' },
  SF: { primary: 'rgb(170, 0, 0, 0.75)', secondary: 'rgb(173, 153, 93, 0.75)' },
  SEA: { primary: 'rgb(0, 34, 68, 0.75)', secondary: 'rgb(105, 190, 40, 0.75)' },
  TB: { primary: 'rgb(213, 10, 10, 0.75)', secondary: 'rgb(52, 48, 43, 0.75)' },
  TEN: { primary: 'rgb(12, 35, 64, 0.75)', secondary: 'rgb(75, 146, 219, 0.75)' },
  WAS: { primary: 'rgb(90, 20, 20, 0.75)', secondary: 'rgb(255, 182, 18, 0.75)' },
};