import { LeagueCode } from "reducers/types/sharedTypes";
import { getLeagueIcon } from "components/helpers";

export type League = {
  leagueCode: LeagueCode;
  weatherLink: string;
  sportIcon: JSX.Element;
};

export const LEAGUES: { [key in LeagueCode]: League } = {
  [LeagueCode.MLB]: {
    leagueCode: LeagueCode.MLB,
    weatherLink: "https://swishanalytics.com/mlb/weather",
    sportIcon: getLeagueIcon(LeagueCode.MLB),
  },
  [LeagueCode.NFL]: {
    leagueCode: LeagueCode.NFL,
    weatherLink: "https://www.nflweather.com/",
    sportIcon: getLeagueIcon(LeagueCode.NFL),
  },
  [LeagueCode.NBA]: {
    leagueCode: LeagueCode.NBA,
    weatherLink: "",
    sportIcon: getLeagueIcon(LeagueCode.NBA),
  },
  [LeagueCode.NHL]: {
    leagueCode: LeagueCode.NHL,
    weatherLink: "",
    sportIcon: getLeagueIcon(LeagueCode.NHL),
  },
  [LeagueCode.NCAAM]: {
    leagueCode: LeagueCode.NCAAM,
    weatherLink: "",
    sportIcon: getLeagueIcon(LeagueCode.NCAAM),
  },
  [LeagueCode.NCAAF]: {
    leagueCode: LeagueCode.NCAAF,
    weatherLink: "",
    sportIcon: getLeagueIcon(LeagueCode.NCAAF),
  }
};