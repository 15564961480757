import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { OddsCard } from '../oddsCard';
import { formatNumberForDisplay, formatOverUnderForDisplay, formattOddsOrPickType } from 'components/helpers';
import { MoneylineOdds, MoneylinePick, OverUnderOdds, OverUnderPick, SpreadOdds, SpreadPick } from 'reducers/types/competitionTypes';
import { PickType } from 'config/competitions';


export interface OddOrPickPlacardProps {
  leftItemType: 'pick' | 'odd';
  leftItem: SpreadOdds | MoneylineOdds | OverUnderOdds | SpreadPick | MoneylinePick | OverUnderPick;
  leftIsFeatured: boolean;
  leftItemBadgeTitle?: string;
  leftItemHeader?: string;

  rightItemType: 'pick' | 'odd';
  rightItem: SpreadOdds | MoneylineOdds | OverUnderOdds | SpreadPick | MoneylinePick | OverUnderPick;
  rightIsFeatured: boolean;
  rightItemBadgeTitle?: string;
  rightItemHeader?: string;
};

// leaving this formatted here for now since it's specific
// to this component. may be worth refactoring ods card
// to hand the nuances/switch statement.
// TODO: refactor this junky switch statement
const formatOddForDisplay = (odd: SpreadOdds | MoneylineOdds | OverUnderOdds) => {
  let percentage;
  let value;
  let badgeTitle;

  if (odd) {
    percentage = odd.percentage;

    switch(odd.oddsType) {
      case PickType.SPREAD:
        value = formatNumberForDisplay(odd.spreadValue);
        badgeTitle = formattOddsOrPickType(odd.homeOrAway, odd.oddsType)
        break;

      case PickType.MONEYLINE:
        badgeTitle = formattOddsOrPickType(odd.homeOrAway, odd.oddsType)
        break;

      case PickType.OVERUNDER:
        value = formatOverUnderForDisplay(odd.overOrUnder, odd.totalPoints);
        badgeTitle = formattOddsOrPickType(odd.overOrUnder, odd.oddsType);
        break;
    }
  }

  return { percentage, value, badgeTitle }
}

const formatPickForDisplay = (pick: SpreadPick | MoneylinePick | OverUnderPick) => {
  let percentage;
  let value;
  let badgeTitle;

  if (pick) {
    percentage = pick.percentage;

    switch(pick.pickType) {
      case PickType.SPREAD:
        value = formatNumberForDisplay(pick.spreadValue);
        badgeTitle = formattOddsOrPickType(pick.homeOrAway, pick.pickType)
        break;

      case PickType.MONEYLINE:
        badgeTitle = formattOddsOrPickType(pick.homeOrAway, pick.pickType)
        break;

      case PickType.OVERUNDER:
        value = pick.overOrUnder === 'split' ? `Split ${pick.totalPoints}` : formatOverUnderForDisplay(pick.overOrUnder, pick.totalPoints);
        badgeTitle = formattOddsOrPickType(pick.overOrUnder, pick.pickType);
        break;
    }
  }
  
  return { percentage, value, badgeTitle }
}

const OddOrPickPlacard = ({
  leftItem,
  leftItemType,
  leftIsFeatured = false,
  leftItemBadgeTitle,
  leftItemHeader,
  rightItem,
  rightItemType,
  rightIsFeatured = false,
  rightItemBadgeTitle,
  rightItemHeader,
}: OddOrPickPlacardProps) => {
  let leftItemIsPick = leftItemType === 'pick';
  let left = leftItemIsPick ? formatPickForDisplay(leftItem as SpreadPick | MoneylinePick | OverUnderPick) : formatOddForDisplay(leftItem as SpreadOdds | MoneylineOdds | OverUnderOdds)

  let rightItemIsPick = rightItemType === 'pick';
  let right = rightItemIsPick ? formatPickForDisplay(rightItem as SpreadPick | MoneylinePick | OverUnderPick) : formatOddForDisplay(rightItem as SpreadOdds | MoneylineOdds | OverUnderOdds)

  return (
    <Grid container xs={12}>

      <Grid xs={6} sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
        <Typography
          variant="body2"
          sx={{
            mb: 1,
            height: leftItemHeader ? 'auto' : '20px',
          }}
        >
          {leftItemHeader ? leftItemHeader : ''}
        </Typography>

        <OddsCard
          percentage={left.percentage}
          value={left.value}
          badgeTitle={leftItemBadgeTitle ? leftItemBadgeTitle : left.badgeTitle}
          isFeatured={leftIsFeatured} 
        />
      </Grid>

      <Grid xs={6} sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
      <Typography
          variant="body2"
          sx={{
            mb: 1,
            height: rightItemHeader ? 'auto' : '20px',
          }}
        >
          {rightItemHeader ? rightItemHeader : ''}
        </Typography>
        <OddsCard
          percentage={right.percentage}
          value={right.value}
          badgeTitle={rightItemBadgeTitle ? rightItemBadgeTitle : right.badgeTitle}
          isFeatured={rightIsFeatured}
        />
      </Grid>

    </Grid>
  )
};

export default OddOrPickPlacard;