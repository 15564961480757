import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

interface NavLinkHeaderProps {
  headerText: string;
  children: JSX.Element;
};

const NavLinkHeader = ({ headerText, children }: NavLinkHeaderProps) => {
  const theme = useTheme();

  return (
    <ListItem
      key={`${headerText}-header`}
      sx={{
        justifyContent: 'center',
        backgroundColor: theme.palette.accentTwo.main,
        borderBottom: `1px solid ${theme.palette.accentTwo.light}`,
      }}
      disableGutters
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            marginRight: theme.spacing(1),
            color: theme.palette.accentTwo.light,
          }}
        >
          {children}
        </Box>

        <ListItemText primary={headerText} primaryTypographyProps={{ variant: "h5", color: theme.palette.accentTwo.light }} />
      </Box>
    </ListItem>
  )
};

export default NavLinkHeader;