import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Divider from '@mui/material/Divider';
import { ReactNode } from "react";
import Box from '@mui/material/Box';

export interface DividerHeaderProps {
  children: ReactNode;
  textAlign: 'left' | 'center' | 'right';
  fullWidth?: boolean;
};

const DividerHeader = ({ children, textAlign, fullWidth = true }: DividerHeaderProps) => {
  let letM = { pl: fullWidth ? 0 : 2, pr: fullWidth ? 0 : 2 };

  if (fullWidth) {
    return (
      <Grid container alignItems="center" spacing={3}>
        <Grid xs={textAlign === 'left' ? 2 : true}>
          <Box sx={{pl: fullWidth ? 0 : 10}}>
            <Divider />
          </Box>
        </Grid>
        <Grid>{children}</Grid>
        <Grid xs={textAlign === 'right' ? 2 : true}>
          <Box sx={{pr: fullWidth ? 0 : 10}}>
            <Divider />
          </Box>
          
        </Grid>
      </Grid>
    )
  } else {
    return (
      <Grid container alignItems="center" spacing={3}>
        <Grid xs={12} sx={{p: 0}}>
          <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{width: '50px', mr: 2 }}>
              <Divider />
            </Box>
            <Grid>{children}</Grid>
            <Box sx={{width: '50px', ml: 2 }}>
              <Divider />
            </Box>
          </Box>
        </Grid>
      </Grid>
    )
  };
};

export default DividerHeader;