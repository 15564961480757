import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export interface FieldSelectProps {
  label: string;
  selectedOption: string;
  options: any[];
  disabled?: boolean;
  callbackFunc: any;
};

const FieldSelect = ({ label, selectedOption, options, disabled = false, callbackFunc, ...props }:FieldSelectProps) => {
  // build menu items and add an empty item at the top
  let optionElements: JSX.Element[] = options.map((option) => <MenuItem key={option} value={option}>{option}</MenuItem>);

  // TODO: uncomment me when null dropdowns work with enums
  optionElements.unshift(
    <MenuItem key="none" value="">
      <em>None</em>
    </MenuItem>
  )

  const handleChange = (event: SelectChangeEvent) => {
    callbackFunc(event.target.value);
  };

  const cssFriendlyLabel = label.split(" ").join("-").toLowerCase() + "-select";

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id={cssFriendlyLabel}>{label}</InputLabel>
      <Select
        labelId={cssFriendlyLabel}
        id={`select-${cssFriendlyLabel}`}
        value={selectedOption}
        label={label}
        disabled={disabled}
        onChange={handleChange}
        {...props}
      >
        {optionElements}
      </Select>
    </FormControl>
  );
}

export default FieldSelect;