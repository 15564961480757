import { useSelector } from 'react-redux';
import {
  PitchingStats,
  MLBTimePeriod,
  PitchingSplit,
  PitchInfos,
  MLBPlayers,
  PitchingSplits,
} from 'reducers/types/mlbTypes';

import { MLB_METRIC_MAP, MLB_TEAM_CODE, Pitches } from 'config/mlb';
import { selectTeamPitchers } from 'reducers/mlbDataReducer';
import { getLeagueRankForMetric, getZScore, sumZScore } from 'components/helpers';
import {
  MetricInfos,
  IndividualMetricInfo,
  IndividualMetricInfos,
  TeamPitchInfos,
} from 'reducers/types/sharedTypes';
import { isEmpty } from 'lodash';
import { buildPitchingStats } from 'reducers/builders';

export interface UsePitcherMetrics {
  pitchingStats: IndividualMetricInfos;
  pitchSpecificStats: TeamPitchInfos;
  pitcherZScore: number;
};

const usePitcherMetrics = (
  pitcherName: string,
  teamCode: MLB_TEAM_CODE,
  allPitchersStats: MetricInfos | undefined,
  allPitchersPitchSpecificStats: PitchInfos | undefined,
  timePeriod: MLBTimePeriod,
  pitchingSplit: PitchingSplit
): UsePitcherMetrics | null => {

  let emptyPitchingStats = buildPitchingStats();
  const teamPitchers = useSelector(selectTeamPitchers(teamCode));
  const pitcher = teamPitchers[getPitcherId(pitcherName, teamPitchers)];
  if (!pitcher) { return null };

  const pitcherSplits = pitcher.playerData[timePeriod] as PitchingSplits;
  const pitcherStats = pitcherSplits ? pitcherSplits[pitchingSplit] as PitchingStats: emptyPitchingStats;
  if (!pitcherStats) { return pitcherStats };

  if (
    (!allPitchersStats || isEmpty(allPitchersStats)) ||
    (!allPitchersPitchSpecificStats || isEmpty(allPitchersPitchSpecificStats)) ||
    (!pitcherName || isEmpty(pitcherName))
  ) {
    return null;
  };

  let zScoreForCorrelatedMetrics = 0;
  let pitchingStats: IndividualMetricInfos = {};
  let pitchSpecificStats: TeamPitchInfos = {};

  for (const metric of Object.keys(pitcherStats)) {
    const teamPitchingMetricKey = metric as keyof PitchingStats;

    let leagueMetricInfo = allPitchersStats[metric];
    
    // team based metrics
    let playerPitchingValueForMetric = 0;
    let playerRankForMetric = 0;
    let metricZScore = 0;

    // handle pitch related metrics
    if (teamPitchingMetricKey === 'pitches') {

      for(const pitchType of Object.keys(allPitchersPitchSpecificStats)) {
        const pitchKey = 'pitches';
        const pitch = pitchType as Pitches;

        const leaguePitchPercentageThrowInfo = allPitchersPitchSpecificStats[pitch].percentageThrow
        const playerPitchingPitchInfo = pitcherStats[pitchKey][pitch];

        playerPitchingValueForMetric = playerPitchingPitchInfo.percentageThrow;

        metricZScore = getZScore(
          playerPitchingValueForMetric,
          leaguePitchPercentageThrowInfo.leagueAverage,
          leaguePitchPercentageThrowInfo.leagueStdDeviation,
        );

        zScoreForCorrelatedMetrics = sumZScore(leaguePitchPercentageThrowInfo.metricCorrelationToWins, zScoreForCorrelatedMetrics, metricZScore);

        const playerPercentageThrowMetricInfo: IndividualMetricInfo = {
          leagueRank: playerRankForMetric,
          metricValue: playerPitchingValueForMetric,
          metricZScore,
        }

        // averageVelocity
        const leaguePitchAverageVelocityInfo = allPitchersPitchSpecificStats[pitch].averageVelocity;
        playerPitchingValueForMetric = playerPitchingPitchInfo.averageVelocity;

        metricZScore = getZScore(
          playerPitchingValueForMetric,
          leaguePitchAverageVelocityInfo.leagueAverage,
          leaguePitchAverageVelocityInfo.leagueStdDeviation,
        );

        zScoreForCorrelatedMetrics = sumZScore(leaguePitchAverageVelocityInfo.metricCorrelationToWins, zScoreForCorrelatedMetrics, metricZScore);

        const playerAverageVelocityMetricInfo: IndividualMetricInfo = {
          leagueRank: playerRankForMetric,
          metricValue: playerPitchingValueForMetric,
          metricZScore,
        };

        pitchSpecificStats[pitch] = {
          percentageThrow: playerPercentageThrowMetricInfo,
          averageVelocity: playerAverageVelocityMetricInfo,
        };
      }
    
    } else {
      playerPitchingValueForMetric = pitcherStats[teamPitchingMetricKey];
      let highOrLow = MLB_METRIC_MAP[teamPitchingMetricKey].highOrLow;

      playerRankForMetric = getLeagueRankForMetric(leagueMetricInfo.leagueRankArray, playerPitchingValueForMetric, highOrLow);

      metricZScore = getZScore(
        playerPitchingValueForMetric,
        leagueMetricInfo.leagueAverage,
        leagueMetricInfo.leagueStdDeviation,
        highOrLow,
      );

      zScoreForCorrelatedMetrics = sumZScore(leagueMetricInfo.metricCorrelationToWins, zScoreForCorrelatedMetrics, metricZScore);

      pitchingStats[metric] = {
        leagueRank: playerRankForMetric,
        metricValue: playerPitchingValueForMetric,
        metricZScore
      }
    }
  };
  
  return {
    pitchingStats,
    pitchSpecificStats,
    pitcherZScore: zScoreForCorrelatedMetrics,
  }
}

export default usePitcherMetrics;


const getPitcherId = (pitcherName: string, teamPitchers: MLBPlayers) => {
  let pitcherId = '0';
  for (const pitcher in teamPitchers) {
    if (teamPitchers[pitcher].name === pitcherName) {
      pitcherId = pitcher
    }
  }
  return pitcherId;
};