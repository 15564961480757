import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseApiUrl = "https://statsapi.mlb.com";

// TODO: This is gross and really should be just deconstucted from
//       MLB_TEAM_LONG_NAME but for now this is quicker to get the
//       feature working.
// const MLB_API_TEAM_TO_TEAM_CODE = {
//   'Arizona Diamondbacks': MLB_TEAM_CODE.ARI,
//   'Atlanta Braves': MLB_TEAM_CODE.ATL,
//   'Baltimore Orioles': MLB_TEAM_CODE.BAL,
//   'Boston Red Sox': MLB_TEAM_CODE.BOS,
//   'Chicago Cubs': MLB_TEAM_CODE.CHC,
//   'Chicago White Sox': MLB_TEAM_CODE.CHW,
//   'Cincinnati Reds': MLB_TEAM_CODE.CIN,
//   'Cleveland Guardians': MLB_TEAM_CODE.CLE,
//   'Colorado Rockies': MLB_TEAM_CODE.COL,
//   'Detroit Tigers': MLB_TEAM_CODE.DET,
//   'Houston Astros': MLB_TEAM_CODE.HOU,
//   'Kansas City Royals': MLB_TEAM_CODE.KCR,
//   'Los Angeles Angels': MLB_TEAM_CODE.LAA,
//   'Los Angeles Dodgers': MLB_TEAM_CODE.LAD,
//   'Miami Marlins': MLB_TEAM_CODE.MIA,
//   'Milwaukee Brewers': MLB_TEAM_CODE.MIL,
//   'Minnesota Twins': MLB_TEAM_CODE.MIN,
//   'New York Mets': MLB_TEAM_CODE.NYM,
//   'New York Yankees': MLB_TEAM_CODE.NYY,
//   'Oakland Athletics': MLB_TEAM_CODE.OAK,
//   'Philadelphia Phillies': MLB_TEAM_CODE.PHI,
//   'Pittsburgh Pirates': MLB_TEAM_CODE.PIT,
//   'San Diego Padres': MLB_TEAM_CODE.SDP,
//   'San Francisco Giants': MLB_TEAM_CODE.SFG,
//   'Seattle Mariners': MLB_TEAM_CODE.SEA,
//   'St. Louis Cardinals': MLB_TEAM_CODE.STL,
//   'Tampa Bay Rays': MLB_TEAM_CODE.TBR,
//   'Texas Rangers': MLB_TEAM_CODE.TEX,
//   'Toronto Blue Jays': MLB_TEAM_CODE.TOR,
//   'Washington Nationals': MLB_TEAM_CODE.WSN,
// }

/**
 * this does not work due to CORS blocking issues on the server side
 * which cannot be controlled.  Since we can't make this call at the
 * time of loading a component, we should try and make this fetch happen
 * in the scraper and instead of outputting that in the csv data, build
 * a json object/file that gets put directly into the codebase somewhere
 * maybe the public dir.
 */
export const mlbGameInfoApi = createApi({
  reducerPath: 'mlbGameInfoApi',
  baseQuery: fetchBaseQuery({
    baseUrl: "https://justcors.com/tl_52cb560/" + baseApiUrl,
    prepareHeaders: (headers, { getState }) => {
      headers.set('Access-Control-Allow-Origin', '*')
      headers.set('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE, OPTIONS')
      headers.set('Access-Control-Allow-Headers', 'Content-Type,X-Requested-With, accept,Origin, Access-Control-Request-Method, Access-Control-Request-Headers, Authorization')
      return headers
  }
  }),
  endpoints: (builder) => ({
    getGameInfoByGamePk: builder.query<MLBGameLineup, string>({
      query: (url) => {
        console.log(url)
        return url
      },
      transformResponse: (response: { data: any }) => getPlayerData(response),
    }),
  }),
})

export const {
  useGetGameInfoByGamePkQuery
} = mlbGameInfoApi;

interface MLBApiPlayer {
  name: string;
  primaryPosition: string;
  primaryNumber: string;
}

interface MLBApiPlayers {
  [key: string]: MLBApiPlayer
}

export interface MLBGameLineup {
  awayProbableStartingPitcherName: string;
  homeProbableStartingPitcherName: string;
  players: MLBApiPlayers;
};


export const getPlayerData = (jsonData: any): MLBGameLineup => {
  const probablePitchers = jsonData.probablePitchers;

  let players: MLBApiPlayers = {};

  for (const player of jsonData.players) {
    let playerInfo = {
      name: jsonData.players[player].firstLastName,
      primaryNumber: jsonData.players[player].primaryNumber,
      primaryPosition: jsonData.players[player].primaryPosition.abbreviation,
    };

    // TODO: This wont work when two players have the same name
    players[playerInfo.name] = playerInfo;
  };

  return {
    awayProbableStartingPitcherName: probablePitchers.away.fullName,
    homeProbableStartingPitcherName: probablePitchers.home.fullName,
    players
  };
};