import { useMediaQuery, useTheme } from '@mui/material';
import { BaseModal } from 'components/library';
import React, {
  useState,
  ReactNode,
  createContext,
  useContext,
} from 'react';

export interface ModalContextData {
  openModal: (
    modal: JSX.Element,
    opts?: { headerTitle: string }
  ) => void;
  closeModal: () => void;
}

export const modalContextInitialValue: ModalContextData = {
  openModal: () => null,
  closeModal: () => null,
};

export const ModalContext = createContext<ModalContextData>(
  modalContextInitialValue
);

interface ModalContextProviderProps {
  children: ReactNode;
}

export const ModalContextProvider: React.FC<ModalContextProviderProps> = ({ children }) => {
  const [currentModal, setCurrentModal] = useState<JSX.Element | null>(null);

  const openModal = (
    bodyComponent: JSX.Element,
    opts?: { headerTitle: string },
  ) => {

    const component = (
      <BaseModal
        open={true}
        closeModal={closeModal}
        headerTitle={opts && opts.headerTitle ? opts.headerTitle : undefined }
      >
        {bodyComponent}
      </BaseModal>
    )



    setCurrentModal(component);
  };

  const closeModal = () => {
    setCurrentModal(null);
  };

  return (
    <ModalContext.Provider
      value={{ openModal, closeModal }}
    >
      {currentModal ? currentModal : null}
      {children}
    </ModalContext.Provider>
  );
};

// Context Hook
export function useModalLauncher<T = null>() {
  return {
    ...useContext(ModalContext),
  };
}