import { BreakpointsOptions, PaletteOptions, createTheme } from '@mui/material/styles';

declare module "@mui/material/styles" {
  // interface BreakpointOverrides {
  //   xs: true;
  //   sm: true;
  //   md: true;
  //   lg: true;
  //   xl: true;
  //   mobile: true;
  //   tablet: true;
  //   laptop: true;
  //   desktop: true;
  // }
  interface Palette {
    black: Palette["primary"];
    white: Palette["primary"];
    accentTwo: Palette["primary"]
  }
  interface PaletteOptions {
    black?: PaletteOptions["primary"];
    white?: PaletteOptions["primary"];
    accentTwo?: PaletteOptions["primary"]
  }
  // interface PaletteColor {
  //   lighter?: string;
  //   darker?: string;
  // }
  // interface SimplePaletteColorOptions {
  //   lighter?: string;
  //   darker?: string;
  // }
}

const colors = {
  black: "#000000",
  darkBlue: '#000080',
  darkGray: '#0E0E0E',
  electricBlack: '#292929',
  neonOrange: '#FF7F00',
  darkNeon: '#dd6d00',
  lightGray: '#D3D3D3',
  teal: '#00BFFF',
  white: '#FFFFFF',
};

export const themeColors = {
  primary: colors.neonOrange,
  secondary: colors.darkBlue,
  tertiary: colors.electricBlack,
  accentOne: colors.teal,
  accentTwo: colors.lightGray,
  white: colors.white,
  black: colors.black,
}

const palette: PaletteOptions = {
  primary: {
    light: colors.neonOrange,
    main: colors.neonOrange,
    dark: colors.darkNeon,
  },
  secondary: {
    light: '#E5FFF8',
    main: '#06D6A0',
    dark: '#07A87E',
  },
  black: {
    light: colors.black,
    main: colors.black,
    dark: colors.black,
  },
  white: {
    light: colors.white,
    main: colors.white,
    dark: colors.white,
  },
  accentTwo: {
    light: "rgba(0, 0, 0, 0.60)",
    main: colors.lightGray,
    dark: "rgba(0, 0, 0, 0.80)",
  },
  // error: {
  //   light: '#FFEBEF',
  //   main: '#BF0A30',
  //   dark: '#87000A',
  // },
  // warning: {
  //   light: '#FFF4E5',
  //   main: '#FFA329',
  //   dark: '#F08900',
  // },
  // background: {
  //   default: '#FAF9F8',
  //   paper: '#FFFFFF',
  // },
  // text: {
  //   primary: '#1E1E1E',
  //   secondary: '#7A8386',
  //   disabled: '#B4B9BB',
  // },
};

const breakpoints: BreakpointsOptions = {
  values: { xs: 0, sm: 600, md: 900, lg: 1185, xl: 1536 },
};

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          marginBottom: '0px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          background: themeColors.secondary,
          color: themeColors.white,
        },
      },
    },
  },
  palette,
  breakpoints,
});

export default theme;