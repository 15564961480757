import { useSelector } from 'react-redux';
import {
  LeagueBattingStats,
  MLBTimePeriod,
  BattingSplit,
} from 'reducers/types/mlbTypes';

import { selectLeagueBattingData } from 'reducers/mlbDataReducer';
import { MetricInfos } from 'reducers/types/sharedTypes';

export interface UseLeagueBattingMetrics {
  leagueBattingStats: MetricInfos;
};

const useLeagueBattingMetrics = (
  timePeriod: MLBTimePeriod,
  battingSplit: BattingSplit,
): UseLeagueBattingMetrics | null => {
  
  const leagueBattingData = useSelector(selectLeagueBattingData);

  let leagueBattingStats: MetricInfos = {};

  for (const metric of Object.keys(leagueBattingData)) {
    const leagueMetricKey = metric as keyof LeagueBattingStats;

    // league based metrics
    let leagueAverageForMetric = leagueBattingData[leagueMetricKey].leagueAverage || 0;
    let leagueMinimumForMetric = Math.min(...leagueBattingData[leagueMetricKey].rankedTotals) || 0;
    let leagueMaximumForMetric = Math.max(...leagueBattingData[leagueMetricKey].rankedTotals) || 0;
    let leagueStdDeviationForMetric = leagueBattingData[leagueMetricKey].stdDeviation || 0;
    let correlationToWins = leagueBattingData[leagueMetricKey].correlationToWins || 0;
    

    leagueBattingStats[metric] = {
      leagueAverage: leagueAverageForMetric,
      leagueMinimum: leagueMinimumForMetric,
      leagueMaximum: leagueMaximumForMetric,
      leagueRankArray: leagueBattingData[leagueMetricKey].rankedTotals,
      leagueStdDeviation: leagueStdDeviationForMetric,
      metricCorrelationToWins: correlationToWins,
    }
  };
  
  return {
    leagueBattingStats,
  }
}

export default useLeagueBattingMetrics;