import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export interface PickPlotBarHeaderProps {
  leftOrRight: 'left' | 'right';
  teamName: string; 
  subCaptionBetInfo?: string;
  subCaptionOdds: string;
};

const PickPlotBarHeader = ({leftOrRight, teamName, subCaptionBetInfo = '', subCaptionOdds}: PickPlotBarHeaderProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: leftOrRight === 'left' ? 'flex-start' : 'flex-end'}}>
      <Typography variant="caption" sx={{fontSize: '10px'}}>{teamName.toUpperCase()}</Typography>
      <Typography variant="caption" sx={{fontSize: '10px'}}>{`${subCaptionBetInfo}  (${subCaptionOdds})`}</Typography>
    </Box>
  )
};

export default PickPlotBarHeader;