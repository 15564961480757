import { useSelector } from 'react-redux';
import {
  BattingStats,
  MLBTimePeriod,
  BattingSplit,
} from 'reducers/types/mlbTypes';

import { MLB_METRIC_MAP, MLB_TEAM_CODE } from 'config/mlb';
import { selectTeamBattingData } from 'reducers/mlbDataReducer';
import { getLeagueRankForMetric, getZScore, sumZScore } from 'components/helpers';
import {
  MetricInfos,
  IndividualMetricInfo,
  IndividualMetricInfos,
} from 'reducers/types/sharedTypes';
import { isEmpty } from 'lodash';

export interface UseTeamBattingMetrics {
  teamBattingStats: IndividualMetricInfos;
  teamBattingZscore: number;
};

const useTeamBattingMetrics = (
  teamCode: MLB_TEAM_CODE,
  leagueBattingStats: MetricInfos | undefined,
  timePeriod: MLBTimePeriod,
  battingSplit: BattingSplit
): UseTeamBattingMetrics | null => {
  const teamBattingData = useSelector(selectTeamBattingData(teamCode, timePeriod, battingSplit));
  
  if (!leagueBattingStats || isEmpty(leagueBattingStats)) {
    return null;
  };

  let zScoreForCorrelatedMetrics = 0;
  let teamBattingStats: IndividualMetricInfos = {};

  for (const metric of Object.keys(teamBattingData)) {
    const teamBattingMetricKey = metric as keyof BattingStats;

    let leagueMetricInfo = leagueBattingStats[metric];
    
    // team based metrics
    let teamBattingValueForMetric = 0;
    let teamRankForMetric = 0;
    let metricZScore = 0;

    teamBattingValueForMetric = teamBattingData[teamBattingMetricKey];
    let highOrLow = MLB_METRIC_MAP[teamBattingMetricKey].highOrLow;

    teamRankForMetric = getLeagueRankForMetric(leagueMetricInfo.leagueRankArray, teamBattingValueForMetric, highOrLow);

    metricZScore = getZScore(
      teamBattingValueForMetric,
      leagueMetricInfo.leagueAverage,
      leagueMetricInfo.leagueStdDeviation,
      highOrLow,
    );

    zScoreForCorrelatedMetrics = sumZScore(leagueMetricInfo.metricCorrelationToWins, zScoreForCorrelatedMetrics, metricZScore);

    teamBattingStats[metric] = {
      leagueRank: teamRankForMetric,
      metricValue: teamBattingValueForMetric,
      metricZScore
    }
  };
  
  return {
    teamBattingStats,
    teamBattingZscore: zScoreForCorrelatedMetrics,
  }
}

export default useTeamBattingMetrics;
