import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty, isEqual, isNull } from 'lodash';
import moment from 'moment';

import { MLB_TEAM_CODE } from 'config/mlb';
import { CompetitionStatus, OpenOrCurrent, OverOrUnder, PickEntity, PickType } from 'config/competitions';
import { AppDispatch } from 'reducers/store';

import { parseCsvData, buildCompetitionsCsvFilePath, generateUUID } from 'services/csvService';
import {
  BettingOdds,
  BettingPicks,
  Competitions,
  CompetitionsReducerState,
  HomeOrAway,
  MoneylineOdds,
  MoneylinePick,
  OverUnderOdds,
  OverUnderPick,
  SpreadOdds,
  SpreadPick
} from './types/competitionTypes';
import { LeagueCode, Sport } from './types/sharedTypes';
import { americanOddsToPercentage } from 'components/helpers';


const initialState: CompetitionsReducerState = {
  competitions: {},
  competitionIds: [],
  bettingPicks: {},
  bettingPickIds: [],
  bettingOdds: {},
  bettingOddsIds: [],
};

export const competitionsSlice = createSlice({
  name: "competitions",
  initialState,
  reducers: {
    setCompetitions(state: CompetitionsReducerState, action: PayloadAction<Competitions>) {
      if (!isEqual(state.competitions, action.payload)) {
        state.competitions = action.payload;
      }
    },
    setCompetitionIds(state: CompetitionsReducerState, action: PayloadAction<number[]>) {
      if (!isEqual(state.competitionIds, action.payload)) {
        state.competitionIds = action.payload;
      }
    },

    setBettingPicks(state: CompetitionsReducerState, action: PayloadAction<BettingPicks>) {
      if (!isEqual(state.bettingPicks, action.payload)) {
        state.bettingPicks = action.payload;
      }
    },
    setBettingPickIds(state: CompetitionsReducerState, action: PayloadAction<string[]>) {
      if (!isEqual(state.bettingPickIds, action.payload)) {
        state.bettingPickIds = action.payload;
      }
    },

    setBettingOdds(state: CompetitionsReducerState, action: PayloadAction<BettingOdds>) {
      if (!isEqual(state.bettingOdds, action.payload)) {
        state.bettingOdds = action.payload;
      }
    },
    setBettingOddsIds(state: CompetitionsReducerState, action: PayloadAction<string[]>) {
      if (!isEqual(state.bettingOddsIds, action.payload)) {
        state.bettingOddsIds = action.payload;
      }
    },
  }
})

export const {
  setCompetitions,
  setCompetitionIds,
  setBettingPicks,
  setBettingPickIds,
  setBettingOdds,
  setBettingOddsIds,
} = competitionsSlice.actions;

export type CompetitionsAppState = { [competitionsSlice.name]: CompetitionsReducerState };

export const selectCompetitionsState = (state: CompetitionsAppState) => state.competitions;
export const selectCompetitions = createSelector(selectCompetitionsState, (state) => state.competitions);
export const selectCompetitionIds = createSelector(selectCompetitionsState, (state) => state.competitionIds);

// this should eventually take a date instead from a date picker
export const selectLeagueCompetitionsForDate = (selectedLeagueCode: LeagueCode, date: string) => {
  return createSelector(selectCompetitions, (competitions: Competitions): Competitions => {
    let comps: Competitions = {};
    let selectedDate = moment(date, "M-DD-YYYY").valueOf();

    for (const competition in competitions) {
      const builtCompetitionDate = moment(competitions[competition].date, "M-DD-YYYY").valueOf();
      if (selectedDate === builtCompetitionDate) {

        if(competitions[competition].league === selectedLeagueCode){
          comps[competitions[competition].id] = competitions[competition];
        }
      }
    }
    
    return comps;
  });
};

export const selectCompetitionById = (competitionId: number) => {
  return createSelector(selectCompetitions, (competitions: Competitions) => {
    return competitions[competitionId] ? competitions[competitionId] : null;
  });
};

export const selectBettingOdds = createSelector(selectCompetitionsState, (state) => state.bettingOdds);
export const selectBettingOddsIds = createSelector(selectCompetitionsState, (state) => state.bettingOddsIds);

export const selectBettingOddsByCompetitionIds = (competitionIds: number[]) => {
  return createSelector(selectBettingOdds, (bettingOdds: BettingOdds): BettingOdds => {
    let betOdds: BettingOdds = {};

    for (const odds in bettingOdds) {
      if (competitionIds.includes(bettingOdds[odds].competitionId)) {
        betOdds[odds] = bettingOdds[odds];
      }
    }
    
    return betOdds;
  });
};

export const selectBettingOddsByCompetitionId = (competitionId: number) => {
  return createSelector(selectBettingOdds, (bettingOdds: BettingOdds) => {

    let betOdds: BettingOdds = {};
    for (const odds in bettingOdds) {
      if (bettingOdds[odds].competitionId === competitionId) {
        betOdds[odds] = bettingOdds[odds];
      }
    };

    return !isEmpty(betOdds) ? betOdds : null;
  });
};

export const selectCurrentBettingOddsForCompetition = (competitionId: number) => {
  return createSelector(selectBettingOdds, (bettingOdds: BettingOdds) => {
    let spreadOdds: SpreadOdds[] = [];
    let moneylineOdds: MoneylineOdds[] = [];
    let overUnderOdds: OverUnderOdds[] = [];

    let betOdds = {
      spreadOdds,
      moneylineOdds,
      overUnderOdds,
    };

    for (const odds in bettingOdds) {
      if (bettingOdds[odds].competitionId === competitionId) {

        if (bettingOdds[odds].oddsType === 'spread') {
          spreadOdds.push(bettingOdds[odds] as SpreadOdds);
        }

        if (bettingOdds[odds].oddsType === 'moneyline') {
          moneylineOdds.push(bettingOdds[odds] as MoneylineOdds);
        }

        if (bettingOdds[odds].oddsType === 'over-under') {
          overUnderOdds.push(bettingOdds[odds] as OverUnderOdds);
        }
      }
    }
    
    return betOdds;
  });
};


export const selectBettingPicks = createSelector(selectCompetitionsState, (state) => state.bettingPicks);
export const selectBettingPicksIds = createSelector(selectCompetitionsState, (state) => state.bettingPickIds);

export const selectBettingPicksByCompetitionIds = (competitionIds: number[]) => {
  return createSelector(selectBettingPicks, (bettingPicks: BettingPicks): BettingPicks => {
    let betPicks: BettingPicks = {};

    for (const picks in bettingPicks) {
      if (competitionIds.includes(bettingPicks[picks].competitionId)) {
        betPicks[picks] = bettingPicks[picks];
      }
    }
    
    return betPicks;
  });
};

export const selectBettingPicksByCompetitionId = (competitionId: number) => {
  return createSelector(selectBettingPicks, (bettingPicks: BettingPicks) => {

    let betPicks: BettingPicks = {};
    for (const pickId in bettingPicks) {
      if (bettingPicks[pickId].competitionId === competitionId) {
        betPicks[pickId] = bettingPicks[pickId];
      }
    };

    return !isEmpty(betPicks) ? betPicks : null;
  });
};

export const selectCurrentBettingPicksForCompetition = (competitionId: number) => {
  return createSelector(selectBettingPicks, (bettingPicks: BettingPicks) => {
    let spreadPicks: SpreadPick[] = [];
    let moneylinePicks: MoneylinePick[] = [];
    let overUnderPicks: OverUnderPick[] = [];

    let betPicks = {
      spreadPicks,
      moneylinePicks,
      overUnderPicks,
    };

    for (const pickId in bettingPicks) {
      if (bettingPicks[pickId].competitionId === competitionId) {

        if (bettingPicks[pickId].pickType === 'spread') {
          spreadPicks.push(bettingPicks[pickId] as SpreadPick);
        }

        if (bettingPicks[pickId].pickType === 'moneyline') {
          moneylinePicks.push(bettingPicks[pickId] as MoneylinePick);
        }

        if (bettingPicks[pickId].pickType === 'over-under') {
          overUnderPicks.push(bettingPicks[pickId] as OverUnderPick);
        }
      }
    }
    
    return betPicks;
  });
};




// export const selectMLBCompetition = (id: string) => {
//   return createSelector(selectMLBCompetitions, (mlbCompetitions: Competitions): Competition => {
//     return mlbCompetitions[id] ? mlbCompetitions[id] : buildCompetition();
//   });
// };

/**
 * since papa parse isn't really setup to handle passing in params and dealing with
 * returning data we have to update pieces individually. To get around the messiness
 * of that we can use the misc builder functions. To handle this for non current data
 * I can pass in timePeriod, battingSplit, and pitchingSplit into initializeTeamData
 * and then pass them in so each data set can be loaded and properly set in state.
 * 
 * Once that is complete, I can build/update selectors to make sure they take timePeriod
 * and split so specific data can be selected using the same selectors.
 * @returns 
 */
export const initializeCompetitionsData = () => {

  return (dispatch: AppDispatch) => {
    let competitions: Competitions = {};
    let competitionIds: number[] = [];
    let bettingPicks: BettingPicks = {};
    let bettingPickIds: string[] = [];
    let bettingOdds: BettingOdds = {};
    let bettingOddsIds: string[] = [];

    let home: HomeOrAway = 'home';
    let away: HomeOrAway = 'away';
    let over: OverOrUnder = 'over';
    let under: OverOrUnder = 'under';
    let open: OpenOrCurrent = 'open';
    let current: OpenOrCurrent = 'current';

    const processCompetitionsData = (papaData: any) => {
      for (const competitionData of papaData) {
        let competitionId = competitionData.id;
        let league = competitionData.league as LeagueCode;
        let awayTeamCode = competitionData.away_team_code as MLB_TEAM_CODE;
        let homeTeamCode = competitionData.home_team_code as MLB_TEAM_CODE;

         /**
         * Competitions
         */

        let competition = {
          id: competitionId,
          date: competitionData.date, // consider turning this into a date object for sortability
          time: competitionData.time, // this could be merged with date into date object for sortability
          sport: competitionData.sport as Sport,
          status: competitionData.status as CompetitionStatus,
          league,
          awayTeamId: competitionData.away_team_id,
          awayTeamCode,
          homeTeamId: competitionData.home_team_id,
          homeTeamCode,
          resultAwayScore: competitionData.result_away_score || 0,
          resultHomeScore: competitionData.result_home_score || 0,
          projectedAwayScore: competitionData.projected_away_score || 0,
          projectedHomeScore: competitionData.projected_home_score || 0,
          // not ideal but would rather source this form the spreadsheet for now until
          // a better service model can be built for fetching the specific game data;
          gameLink: competitionData.game_link || '',
        };

        /**
         * Odds
         */

        let awaySpreadOddsOpen: SpreadOdds = {
          competitionId: competitionId,
          openOrCurrent: open,
          oddsType: PickType.SPREAD,
          homeOrAway: away,
          spreadValue: competitionData.away_spread_value_open,
          // csv outputs american odds
          percentage: americanOddsToPercentage(competitionData.away_spread_odds_open),
        };
        let awaySpreadOddsOpenId = generateUUID();

        let awaySpreadOddsCurrent: SpreadOdds = {
          competitionId: competitionId,
          openOrCurrent: current,
          oddsType: PickType.SPREAD,
          homeOrAway: away,
          spreadValue: competitionData.away_spread_value_current,
          // csv outputs american odds
          percentage: americanOddsToPercentage(competitionData.away_spread_odds_current),
        };

        let awaySpreadOddsCurrentId = generateUUID();

        let homeSpreadOddsOpen: SpreadOdds = {
          competitionId: competitionId,
          openOrCurrent: open,
          oddsType: PickType.SPREAD,
          homeOrAway: home,
          spreadValue: competitionData.home_spread_value_open,
          // csv outputs american odds
          percentage: americanOddsToPercentage(competitionData.home_spread_odds_open),
        };
        let homeSpreadOddsOpenId = generateUUID();

        let homeSpreadOddsCurrent: SpreadOdds = {
          competitionId: competitionId,
          openOrCurrent: current,
          oddsType: PickType.SPREAD,
          homeOrAway: home,
          spreadValue: competitionData.home_spread_value_current,
          // csv outputs american odds
          percentage: americanOddsToPercentage(competitionData.home_spread_odds_current),
        };
        let homeSpreadOddsCurrentId = generateUUID();
 
        let awayMoneylineOddsOpen: MoneylineOdds = {
          competitionId: competitionId,
          openOrCurrent: open,
          oddsType: PickType.MONEYLINE,
          homeOrAway: away,
          // csv outputs american odds
          percentage: !isNull(competitionData.away_moneyline_odds_open) ? americanOddsToPercentage(competitionData.away_moneyline_odds_open) : 100,
        };
        let awayMoneylineOddsOpenId = generateUUID();
        let awayMoneylineOddsCurrent: MoneylineOdds = {
          competitionId: competitionId,
          openOrCurrent: current,
          oddsType: PickType.MONEYLINE,
          homeOrAway: away,
          // csv outputs american odds
          percentage: !isNull(competitionData.away_moneyline_odds_current) ? americanOddsToPercentage(competitionData.away_moneyline_odds_current) : 100,
        };
        let awayMoneylineOddsCurrentId = generateUUID();

        let homeMoneylineOddsOpen: MoneylineOdds = {
          competitionId: competitionId,
          openOrCurrent: open,
          oddsType: PickType.MONEYLINE,
          homeOrAway: home,
          // csv outputs american odds
          percentage: !isNull(competitionData.home_moneyline_odds_open) ? americanOddsToPercentage(competitionData.home_moneyline_odds_open) : 100,
        };
        let homeMoneylineOddsOpenId = generateUUID();

        let homeMoneylineOddsCurrent: MoneylineOdds = {
          competitionId: competitionId,
          openOrCurrent: current,
          oddsType: PickType.MONEYLINE,
          homeOrAway: home,
          // csv outputs american odds
          percentage: !isNull(competitionData.home_moneyline_odds_current) ? americanOddsToPercentage(competitionData.home_moneyline_odds_current) : 100,
        };
        let homeMoneylineOddsCurrentId = generateUUID();

        let overOddsOpen: OverUnderOdds = {
          competitionId: competitionId,
          openOrCurrent: open,
          oddsType: PickType.OVERUNDER,
          overOrUnder: over,
          totalPoints: competitionData.over_value_open,
          // csv outputs american odds
          percentage: americanOddsToPercentage(competitionData.over_odds_open),
        };
        let overOddsOpenId = generateUUID();

        let overOddsCurrent: OverUnderOdds = {
          competitionId: competitionId,
          openOrCurrent: current,
          oddsType: PickType.OVERUNDER,
          overOrUnder: over,
          totalPoints: competitionData.over_value_current,
          // csv outputs american odds
          percentage: americanOddsToPercentage(competitionData.over_odds_current),
        };
        let overOddsCurrentId = generateUUID();

        let underOddsOpen: OverUnderOdds = {
          competitionId: competitionId,
          openOrCurrent: open,
          oddsType: PickType.OVERUNDER,
          overOrUnder: under,
          totalPoints: competitionData.under_value_open,
          // csv outputs american odds
          percentage: americanOddsToPercentage(competitionData.under_odds_open),
        };
        let underOddsOpenId = generateUUID();

        let underOddsCurrent: OverUnderOdds = {
          competitionId: competitionId,
          openOrCurrent: current,
          oddsType: PickType.OVERUNDER,
          overOrUnder: under,
          totalPoints: competitionData.under_value_current,
          // csv outputs american odds
          percentage: americanOddsToPercentage(competitionData.under_odds_current),
        };
        let underOddsCurrentId = generateUUID();

        let odds: BettingOdds = {
          [awaySpreadOddsOpenId]: awaySpreadOddsOpen,
          [awaySpreadOddsCurrentId]: awaySpreadOddsCurrent,
          [homeSpreadOddsOpenId]: homeSpreadOddsOpen,
          [homeSpreadOddsCurrentId]: homeSpreadOddsCurrent,

          [awayMoneylineOddsOpenId]: awayMoneylineOddsOpen,
          [awayMoneylineOddsCurrentId]: awayMoneylineOddsCurrent,
          [homeMoneylineOddsOpenId]: homeMoneylineOddsOpen,
          [homeMoneylineOddsCurrentId]: homeMoneylineOddsCurrent,

          [overOddsOpenId]: overOddsOpen,
          [overOddsCurrentId]: overOddsCurrent,
          [underOddsOpenId]: underOddsOpen,
          [underOddsCurrentId]: underOddsCurrent,
        };

        let oddsIds = [
          awaySpreadOddsOpenId,
          awaySpreadOddsCurrentId,
          homeSpreadOddsOpenId,
          homeSpreadOddsCurrentId,

          awayMoneylineOddsOpenId,
          awayMoneylineOddsCurrentId,
          homeMoneylineOddsOpenId,
          homeMoneylineOddsCurrentId,

          overOddsOpenId,
          overOddsCurrentId,
          underOddsOpenId,
          underOddsCurrentId
        ];

        /**
         * Picks
         */
        let slSpreadPickHomeOrAway = competitionData.sl_spread_pick as HomeOrAway
        let slSpreadPickSpreadValue = Number(competitionData.sl_spread_pick_value);
        let slSpreadPick: SpreadPick = {
          competitionId: competition.id,
          pickType: PickType.SPREAD,
          entity: PickEntity.SL,
          homeOrAway: slSpreadPickHomeOrAway,
          spreadValue: slSpreadPickSpreadValue,
          percentage: competitionData.sl_spread_pick_percentage,
          grade: competitionData.sl_spread_pick_grade,
        };
        let slSpreadPickId = generateUUID();

        let omSpreadPick: SpreadPick = {
          competitionId: competition.id,
          pickType: PickType.SPREAD,
          entity: PickEntity.ODDSMAKERS,
          // oddsmakers pick vlaues are based off the sl selection
          // the sl shows how the oddsmakers think about the pick as a percentage
          homeOrAway: competitionData.sl_spread_pick as HomeOrAway,
          spreadValue: slSpreadPickSpreadValue,
          percentage: competitionData.om_spread_pick_percentage,
        };
        let omSpreadPickId = generateUUID();

        let isSLSpreadBetUnderdog = competitionData.sl_spread_pick_value > 0;

        let publicSpreadPickPercent = Number(competitionData.public_spread_away_percent);
        let publicHomeOrAway: HomeOrAway | 'split' = publicSpreadPickPercent === 0.5 ? 'split' : publicSpreadPickPercent > 0.5 ? away : home;
        let publicSpreadPick: SpreadPick = {
          competitionId: competition.id,
          pickType: PickType.SPREAD,
          entity: PickEntity.PUBLIC,
          homeOrAway: publicHomeOrAway,
          spreadValue: publicHomeOrAway === slSpreadPickHomeOrAway ? competitionData.sl_spread_pick_value : isSLSpreadBetUnderdog ? slSpreadPickSpreadValue * -1 : Math.abs(slSpreadPickSpreadValue),
          percentage: competitionData.public_spread_away_percent,
        };
        let publicSpreadPickId = generateUUID();

        let moneyBetSpreadPickPercent = Number(competitionData.money_bet_spread_away_percent);
        let moneyBetHomeOrAway: HomeOrAway | 'split' = moneyBetSpreadPickPercent === 0.5 ? 'split' : moneyBetSpreadPickPercent > 0.5 ? away : home;
        let moneyBetSpreadPick: SpreadPick = {
          competitionId: competition.id,
          pickType: PickType.SPREAD,
          entity: PickEntity.MONEY_BET,
          homeOrAway: moneyBetHomeOrAway,
          spreadValue: moneyBetHomeOrAway === slSpreadPickHomeOrAway ? competitionData.sl_spread_pick_value : isSLSpreadBetUnderdog ? slSpreadPickSpreadValue * -1 : Math.abs(slSpreadPickSpreadValue),
          percentage: competitionData.money_bet_spread_away_percent,
        };
        let moneyBetSpreadPickId = generateUUID();

        let slMoneylinePick: MoneylinePick = {
          competitionId: competition.id,
          pickType: PickType.MONEYLINE,
          entity: PickEntity.SL,
          homeOrAway: competitionData.sl_moneyline_pick as HomeOrAway,
          percentage: competitionData.sl_moneyline_pick_percentage,
          grade: competitionData.sl_moneyline_pick_grade,
        };
        let slMoneylinePickId = generateUUID();

        let omMoneylinePick: MoneylinePick = {
          competitionId: competition.id,
          pickType: PickType.MONEYLINE,
          entity: PickEntity.ODDSMAKERS,
          homeOrAway: competitionData.sl_moneyline_pick as HomeOrAway,
          percentage: competitionData.om_moneyline_pick_percentage,
        };
        let omMoneylinePickId = generateUUID();

        let publicMoneylinePick: MoneylinePick = {
          competitionId: competition.id,
          pickType: PickType.MONEYLINE,
          entity: PickEntity.PUBLIC,
          homeOrAway: away,
          percentage: competitionData.public_moneyline_away_percent,
        };
        let publicMoneylinePickId = generateUUID();

        let moneyBetMoneylinePick: MoneylinePick = {
          competitionId: competition.id,
          pickType: PickType.MONEYLINE,
          entity: PickEntity.MONEY_BET,
          homeOrAway: away,
          percentage: competitionData.money_bet_moneyline_away_percent,
        };
        let moneyBetMoneylinePickId = generateUUID()

        let slOverUnderPick: OverUnderPick = {
          competitionId: competition.id,
          pickType: PickType.OVERUNDER,
          entity: PickEntity.SL,
          overOrUnder: competitionData.sl_overunder_pick as OverOrUnder,
          totalPoints: competitionData.sl_overunder_pick_value,
          percentage: competitionData.sl_overunder_pick_percentage,
          grade: competitionData.sl_overunder_pick_grade,
        };
        let slOverUnderPickId = generateUUID();

        let omOverUnderPick: OverUnderPick = {
          competitionId: competition.id,
          pickType: PickType.OVERUNDER,
          entity: PickEntity.ODDSMAKERS,
          overOrUnder: competitionData.sl_overunder_pick as OverOrUnder,
          totalPoints: competitionData.sl_overunder_pick_value,
          percentage: competitionData.om_overunder_pick_percentage,
        };
        let omOverUnderPickId = generateUUID();

        let publicOverUnderPick: OverUnderPick = {
          competitionId: competition.id,
          pickType: PickType.OVERUNDER,
          entity: PickEntity.PUBLIC,
          overOrUnder: over,
          totalPoints: competitionData.sl_overunder_pick_value,
          percentage: competitionData.public_overunder_over_percent,
        };
        let publicOverUnderPickId = generateUUID();

        let moneyBetOverUnderPick: OverUnderPick = {
          competitionId: competition.id,
          pickType: PickType.OVERUNDER,
          entity: PickEntity.MONEY_BET,
          overOrUnder: over,
          totalPoints: competitionData.sl_overunder_pick_value,
          percentage: competitionData.money_bet_overunder_over_percent,
        };
        let moneyBetOverUnderPickId = generateUUID();

        let picks: BettingPicks = {
          [slSpreadPickId]: slSpreadPick,
          [omSpreadPickId]: omSpreadPick,
          [publicSpreadPickId]: publicSpreadPick,
          [moneyBetSpreadPickId]: moneyBetSpreadPick,

          [slMoneylinePickId]: slMoneylinePick,
          [omMoneylinePickId]: omMoneylinePick,
          [publicMoneylinePickId]: publicMoneylinePick,
          [moneyBetMoneylinePickId]: moneyBetMoneylinePick,

          [slOverUnderPickId]: slOverUnderPick,
          [omOverUnderPickId]: omOverUnderPick,
          [publicOverUnderPickId]: publicOverUnderPick,
          [moneyBetOverUnderPickId]: moneyBetOverUnderPick,
        };

        let pickIds = [
          slSpreadPickId,
          omSpreadPickId,
          publicSpreadPickId,
          moneyBetSpreadPickId,

          slMoneylinePickId,
          omMoneylinePickId,
          publicMoneylinePickId,
          moneyBetMoneylinePickId,

          slOverUnderPickId,
          omOverUnderPickId,
          publicOverUnderPickId,
          moneyBetOverUnderPickId,
        ];

        if (competitionData.analyzer_moneyline_pick) {
          let analyzerMoneylinePick: MoneylinePick = {
            competitionId: competition.id,
            pickType: PickType.MONEYLINE,
            entity: PickEntity.BET_WISER,
            homeOrAway: competitionData.analyzer_moneyline_pick as HomeOrAway,
          };
          let analyzerMoneylinePickId = generateUUID()

          picks = {...picks, [analyzerMoneylinePickId]: analyzerMoneylinePick };
          pickIds.push(analyzerMoneylinePickId)
        }

        competitions[competitionId] = competition;
        competitionIds.push(competitionId);
        
        bettingOdds = {...bettingOdds , ...odds};
        bettingOddsIds = bettingOddsIds.concat(oddsIds);

        bettingPicks = {...bettingPicks , ...picks};
        bettingPickIds = bettingPickIds.concat(pickIds);

      };

      dispatch(setCompetitions(competitions));
      dispatch(setCompetitionIds(competitionIds));

      dispatch(setBettingOdds(bettingOdds));
      dispatch(setBettingOddsIds(bettingOddsIds));
      
      dispatch(setBettingPicks(bettingPicks));
      dispatch(setBettingPickIds(bettingPickIds));
    };
    parseCsvData(buildCompetitionsCsvFilePath('competitions.csv'), processCompetitionsData);
  }
  
};

export default competitionsSlice.reducer;


/**
 * league average
 * correlation coefficient
 * stat rank?
 */