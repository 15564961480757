import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NotFound } from 'images';

export interface NotFoundModalProps {
  bodyText?: string;
}

const NotFoundModal = ({bodyText = ''}: NotFoundModalProps) => {

  return (
    <Box sx={{ mt: 5 }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Typography variant="h5" sx={{mt: 2, mb: 2}}>Page Not Found</Typography> 
        
        <img src={NotFound} alt="page not found" />
        
        <Typography variant="body1" sx={{mt: 2, mb: 2}}>{bodyText}</Typography> 
        
      </Box>
    </Box>
  )
};

export default NotFoundModal;