import { MLB_TEAM_CODE } from "config/mlb";

/**
 * 
 * @param playerName playerPapaData.NameASCII string;
 */
export const getTeamCodeForBatter = (playerName: string): MLB_TEAM_CODE => {
  let teamAbbr: MLB_TEAM_CODE;

  // batters
  switch(playerName) {
    case 'Jason Heyward':
      teamAbbr = MLB_TEAM_CODE.HOU;
      break;
    case 'Justin Turner':
      teamAbbr = MLB_TEAM_CODE.SEA;
      break;
    case 'Robbie Grossman':
      teamAbbr = MLB_TEAM_CODE.TEX;
      break;
    case 'Christian Bethancourt':
      teamAbbr = MLB_TEAM_CODE.CHC;
      break;
    case 'Gio Urshela':
      teamAbbr = MLB_TEAM_CODE.ATL;
      break;
    case 'Kevin Kiermaier':
      teamAbbr = MLB_TEAM_CODE.LAD;
      break;
    case 'Whit Merrifield':
      teamAbbr = MLB_TEAM_CODE.ATL;
      break;
    case 'Mark Canha':
      teamAbbr = MLB_TEAM_CODE.SFG;
      break;
    case 'Elias Diaz':
      teamAbbr = MLB_TEAM_CODE.SDP;
      break;
    case 'Niko Goodrum':
      teamAbbr = MLB_TEAM_CODE.BAL;
      break;
    case 'Nick Ahmed':
      teamAbbr = MLB_TEAM_CODE.LAD;
      break;
    case 'Eddie Rosario':
      teamAbbr = MLB_TEAM_CODE.ATL;
      break;
    case 'Ben Gamel':
      teamAbbr = MLB_TEAM_CODE.HOU;
      break;
    case 'Josh Bell':
      teamAbbr = MLB_TEAM_CODE.ARI;
      break;
    case 'Jesse Winker':
      teamAbbr = MLB_TEAM_CODE.NYM;
      break;
    case 'Carson Kelly':
      teamAbbr = MLB_TEAM_CODE.TEX;
      break;
    case 'Tomas Nido':
      teamAbbr = MLB_TEAM_CODE.CHC;
      break;
    case 'Jorge Soler':
      teamAbbr = MLB_TEAM_CODE.ATL;
      break;
    case 'Harold Ramirez':
      teamAbbr = MLB_TEAM_CODE.WSN;
      break;
    case 'Garrett Cooper':
      teamAbbr = MLB_TEAM_CODE.BAL;
      break;
    case 'Dominic Smith':
      teamAbbr = MLB_TEAM_CODE.CIN;
      break;
    case 'Amed Rosario':
      teamAbbr = MLB_TEAM_CODE.LAD;
      break;
    case 'Aledmys Diaz':
      teamAbbr = MLB_TEAM_CODE.HOU;
      break
    case 'J.D. Davis':
      teamAbbr = MLB_TEAM_CODE.NYY;
      break;
    case 'Pablo Reyes':
      teamAbbr = MLB_TEAM_CODE.NYM;
      break;
    case 'Luis Guillorme':
      teamAbbr = MLB_TEAM_CODE.LAA;
      break;
    case 'Forrest Wall':
      teamAbbr = MLB_TEAM_CODE.MIA;
      break;
      case 'Isiah Kiner-Falefa':
      teamAbbr = MLB_TEAM_CODE.PIT;
      break;
    case 'Danny Jansen':
      teamAbbr = MLB_TEAM_CODE.BOS;
      break;
    case 'Lane Thomas':
      teamAbbr = MLB_TEAM_CODE.CLE;
      break;
    case 'Ramon Laureano':
      teamAbbr = MLB_TEAM_CODE.ATL;
      break;
    case 'Eloy Jimenez':
      teamAbbr = MLB_TEAM_CODE.BAL;
      break;
    case 'Ty France':
      teamAbbr = MLB_TEAM_CODE.CIN;
      break;
    case 'Paul DeJong':
      teamAbbr = MLB_TEAM_CODE.KCR;
      break;
    case 'Victor Robles':
      teamAbbr = MLB_TEAM_CODE.SEA;
      break;
    case 'Luis Arraez':
      teamAbbr = MLB_TEAM_CODE.SDP;
      break;
    case 'Cavan Biggio':
      teamAbbr = MLB_TEAM_CODE.LAD;
      break;
    case 'Randy Arozarena':
      teamAbbr = MLB_TEAM_CODE.SEA;
      break;
    case 'Nick Senzel':
      teamAbbr = MLB_TEAM_CODE.CHW;
      break;
    case 'Austin Hays':
      teamAbbr = MLB_TEAM_CODE.PHI;
      break;
    case 'Bryan De La Cruz':
      teamAbbr = MLB_TEAM_CODE.PIT;
      break;
    case 'Tristan Gray':
      teamAbbr = MLB_TEAM_CODE.OAK;
      break;
    case 'Emmanuel Rivera':
      teamAbbr = MLB_TEAM_CODE.BAL;
      break;
    case 'Ryan McKenna':
      teamAbbr = MLB_TEAM_CODE.SFG;
      break;
    case 'Taylor Trammell':
      teamAbbr = MLB_TEAM_CODE.NYY;
      break;
    case 'Isaac Paredes':
      teamAbbr = MLB_TEAM_CODE.CHC;
      break;
    case 'Dylan Carlson':
      teamAbbr = MLB_TEAM_CODE.TBR;
      break;
    case 'Miguel Vargas':
      teamAbbr = MLB_TEAM_CODE.NYY;
      break;
    case 'Jazz Chisholm Jr.':
      teamAbbr = MLB_TEAM_CODE.CHW;
      break;
    case 'Christopher Morel':
      teamAbbr = MLB_TEAM_CODE.TBR;
      break;
    case 'Livan Soto':
      teamAbbr = MLB_TEAM_CODE.BAL;
      break;
    case 'Buddy Kennedy':
      teamAbbr = MLB_TEAM_CODE.PHI;
      break;
    case 'Jacob Amaya':
      teamAbbr = MLB_TEAM_CODE.CHW;
      break;
    case 'Jose Tena':
      teamAbbr = MLB_TEAM_CODE.WSN;
      break;
    case 'Kyle Stowers':
      teamAbbr = MLB_TEAM_CODE.MIA;
      break;
    case 'Duke Ellis':
      teamAbbr = MLB_TEAM_CODE.NYY;
      break;
    case 'Joey Wiemer':
      teamAbbr = MLB_TEAM_CODE.CIN;
      break;
    case 'Connor Norby':
      teamAbbr = MLB_TEAM_CODE.MIA;
      break;
    case 'Joey Loperfido':
      teamAbbr = MLB_TEAM_CODE.TOR;
      break;
    case 'Tommy Pham':
      teamAbbr = MLB_TEAM_CODE.KCR;
      break;
    case 'Austin Slater':
      teamAbbr = MLB_TEAM_CODE.BAL;
      break;
    case 'Derek Hill':
      teamAbbr = MLB_TEAM_CODE.MIA;
      break;
    case 'Zack Short':
      teamAbbr = MLB_TEAM_CODE.ATL;
      break;
    case 'Cristian Pache':
      teamAbbr = MLB_TEAM_CODE.MIA;
      break;

    default:
      throw new Error(`Batter: ${playerName} has no assignable team`)                                                                                                                                                                                                                 
  }

  return teamAbbr;
}

/**
 * 
 * @param playerName playerPapaData.NameASCII string;
 */
export const getTeamCodeForPitcher = (playerName: string): MLB_TEAM_CODE => {
  let teamAbbr: MLB_TEAM_CODE;

  // separating pitchers and batters for readability
  // next season separate by how many teams a player is included on
  // this is needed in case a player is traded more than once
  switch(playerName) {
    case 'Huascar Brazoban':
      teamAbbr = MLB_TEAM_CODE.NYM;
      break;
    case 'Chase Anderson':
      teamAbbr = MLB_TEAM_CODE.TEX;
      break;
    case 'Martin Perez':
      teamAbbr = MLB_TEAM_CODE.SDP;
      break;
    case 'Luis Garcia':
      teamAbbr = MLB_TEAM_CODE.BOS;
      break;
    case 'Luke Jackson':
      teamAbbr = MLB_TEAM_CODE.ATL;
      break;
    case 'Hector Neris':
      teamAbbr = MLB_TEAM_CODE.HOU;
      break;
    case 'James Paxton':
      teamAbbr = MLB_TEAM_CODE.BOS;
      break;
    case 'Jason Adam':
      teamAbbr = MLB_TEAM_CODE.SDP;
      break;
    case 'Yimi Garcia':
      teamAbbr = MLB_TEAM_CODE.SEA;
      break;
    case 'John Brebbia':
      teamAbbr = MLB_TEAM_CODE.ATL;
      break;
    case 'Burch Smith':
      teamAbbr = MLB_TEAM_CODE.BAL;
      break;
    case 'Shawn Armstrong':
      teamAbbr = MLB_TEAM_CODE.STL;
      break;
    case 'Andrew Chafin':
      teamAbbr = MLB_TEAM_CODE.TEX;
      break;
    case 'Dylan Floro':
      teamAbbr = MLB_TEAM_CODE.ARI;
      break;
    case 'Lucas Sims':
      teamAbbr = MLB_TEAM_CODE.BOS;
      break;
    case 'Jakob Junis':
      teamAbbr = MLB_TEAM_CODE.CIN;
      break;
      case 'JT Chargois':
      teamAbbr = MLB_TEAM_CODE.SEA;
      break;
    case 'Zach Eflin':
      teamAbbr = MLB_TEAM_CODE.BAL;
      break;
    case 'Frankie Montas':
      teamAbbr = MLB_TEAM_CODE.MIL;
      break;
    case 'Jorge Lopez':
      teamAbbr = MLB_TEAM_CODE.CHC;
      break;
    case 'Carlos Estevez':
      teamAbbr = MLB_TEAM_CODE.PHI;
      break;
    case 'Paul Blackburn':
      teamAbbr = MLB_TEAM_CODE.NYM;
      break;
    case 'Wes Parsons':
      teamAbbr = MLB_TEAM_CODE.CLE;
      break;
    case 'Michael Lorenzen':
      teamAbbr = MLB_TEAM_CODE.KCR;
      break;
    case 'Tim Mayza':
      teamAbbr = MLB_TEAM_CODE.NYY;
      break;
    case 'Cole Sulser':
      teamAbbr = MLB_TEAM_CODE.TBR;
      break;
    case 'Hunter Harvey':
      teamAbbr = MLB_TEAM_CODE.KCR;
      break;
    case 'Mark Leiter Jr.':
      teamAbbr = MLB_TEAM_CODE.NYY;
      break;
    case 'Brad Keller':
      teamAbbr = MLB_TEAM_CODE.BOS;
      break;
    case 'Ryne Stanek':
      teamAbbr = MLB_TEAM_CODE.NYM;
      break;
    case 'Luis Guillorme':
      teamAbbr = MLB_TEAM_CODE.LAA;
      break;
    case 'Brent Honeywell':
      teamAbbr = MLB_TEAM_CODE.LAD;
      break;
    case 'Ryan Yarbrough':
      teamAbbr =  MLB_TEAM_CODE.TOR;
      break;
    case 'Vinny Nittoli':
      teamAbbr = MLB_TEAM_CODE.BAL;
      break;
    case 'Tim Hill':
      teamAbbr = MLB_TEAM_CODE.NYY;
      break;
    case 'Elieser Hernandez':
      teamAbbr = MLB_TEAM_CODE.MIL;
      break;
    case 'Tanner Banks':
      teamAbbr = MLB_TEAM_CODE.PHI;
      break;
    case 'Jalen Beeks':
      teamAbbr = MLB_TEAM_CODE.PIT;
      break;
    case 'Michael Kopech':
      teamAbbr = MLB_TEAM_CODE.LAD;
      break;
    case 'Erick Fedde':
      teamAbbr = MLB_TEAM_CODE.STL;
      break;
    case 'Jonathan Hernandez':
      teamAbbr = MLB_TEAM_CODE.SEA;
      break;
    case 'Jack Flaherty':
      teamAbbr = MLB_TEAM_CODE.LAD;
      break;
    case 'Tanner Scott':
      teamAbbr = MLB_TEAM_CODE.SDP;
      break;
    case 'Jose Cuas':
      teamAbbr = MLB_TEAM_CODE.TOR;
      break;
    case 'Trey Wingenter':
      teamAbbr = MLB_TEAM_CODE.CHC;
      break;
    case 'Dennis Santana':
      teamAbbr = MLB_TEAM_CODE.PIT;
      break;
    case 'Tyler Jay':
      teamAbbr = MLB_TEAM_CODE.MIL;
      break;
    case 'Brock Burke':
      teamAbbr = MLB_TEAM_CODE.LAA;
      break;
    case 'Phil Maton':
      teamAbbr = MLB_TEAM_CODE.NYM;
      break;
    case 'Alex Young':
      teamAbbr = MLB_TEAM_CODE.NYM;
      break;
    case 'Enyel De Los Santos':
      teamAbbr = MLB_TEAM_CODE.NYY;
      break;
    case 'Jake Woodford':
      teamAbbr = MLB_TEAM_CODE.PIT;
      break;
    case 'Pedro Avila':
      teamAbbr = MLB_TEAM_CODE.CLE;
      break;
    case 'Seranthony Dominguez':
      teamAbbr = MLB_TEAM_CODE.BAL;
      break;
    case 'Trevor Richards':
      teamAbbr = MLB_TEAM_CODE.MIN;
      break;
    case 'Matt Festa':
      teamAbbr = MLB_TEAM_CODE.TEX;
      break;
    case 'Richard Lovelady':
      teamAbbr = MLB_TEAM_CODE.TBR;
      break;
    case 'A.J. Puk':
      teamAbbr = MLB_TEAM_CODE.ARI;
      break;
    case 'Daulton Jefferies':
      teamAbbr = MLB_TEAM_CODE.PIT;
      break;
    case 'Caleb Ferguson':
      teamAbbr = MLB_TEAM_CODE.HOU;
      break;
    case 'Lucas Erceg':
      teamAbbr = MLB_TEAM_CODE.KCR;
      break;
    case 'Shaun Anderson':
      teamAbbr = MLB_TEAM_CODE.MIA;
      break;
    case 'Aaron Civale':
      teamAbbr = MLB_TEAM_CODE.MIL;
      break;
    case 'Gregory Soto':
      teamAbbr = MLB_TEAM_CODE.BAL;
      break;
    case 'Brandon Bielak':
      teamAbbr = MLB_TEAM_CODE.OAK;
      break;
    case 'Joey Wentz':
      teamAbbr = MLB_TEAM_CODE.PIT;
      break;
    case 'Tyson Miller':
      teamAbbr = MLB_TEAM_CODE.CHC;
      break;
    case 'Joel Kuhnel':
      teamAbbr = MLB_TEAM_CODE.TBR;
      break;
    case 'Darren McCaughan':
      teamAbbr = MLB_TEAM_CODE.CLE;
      break;
    case 'Nate Pearson':
      teamAbbr = MLB_TEAM_CODE.CHC;
      break;
    case 'Yusei Kikuchi':
      teamAbbr = MLB_TEAM_CODE.HOU;
      break;
    case 'Connor Brogdon':
      teamAbbr = MLB_TEAM_CODE.LAD;
      break;
    case 'Yerry Rodriguez':
      teamAbbr = MLB_TEAM_CODE.BAL;
      break;
    case 'Luis Frias':
      teamAbbr = MLB_TEAM_CODE.TOR;
      break;
    case 'Trevor Rogers':
      teamAbbr = MLB_TEAM_CODE.TOR;
      break;
    case 'Roansy Contreras':
      teamAbbr = MLB_TEAM_CODE.LAA;
      break;
    case 'Eduardo Salazar':
      teamAbbr = MLB_TEAM_CODE.WSN;
      break;
    case 'Spencer Howard':
      teamAbbr = MLB_TEAM_CODE.CLE;
      break;
    case 'Janson Junk':
      teamAbbr = MLB_TEAM_CODE.OAK;
      break;
    case 'Ricky Vanasco':
      teamAbbr = MLB_TEAM_CODE.DET;
      break;
    case 'Brett de Geus':
      teamAbbr = MLB_TEAM_CODE.MIA;
      break;
    case 'Gus Varland':
      teamAbbr = MLB_TEAM_CODE.CHW;
      break;
    case 'Colin Selby':
      teamAbbr = MLB_TEAM_CODE.BAL;
      break;
    case 'Joe Jacques':
      teamAbbr = MLB_TEAM_CODE.ARI;
      break;
    case 'Nick Mears':
      teamAbbr = MLB_TEAM_CODE.MIL;
      break;
    case 'Xzavion Curry':
      teamAbbr = MLB_TEAM_CODE.MIA;
      break;
    case 'Easton Lucas':
      teamAbbr = MLB_TEAM_CODE.DET;
      break;
    case 'Hunter Bigge':
      teamAbbr = MLB_TEAM_CODE.TBR;
      break;
    case 'Bryan Hoeing':
      teamAbbr = MLB_TEAM_CODE.SDP;
      break;
    case 'Bradley Blalock':
      teamAbbr = MLB_TEAM_CODE.COL;
      break;
    case 'Anthony Veneziano':
      teamAbbr = MLB_TEAM_CODE.MIA;
      break;
    case 'Walter Pennington':
      teamAbbr = MLB_TEAM_CODE.TEX;
      break;
    case 'Will Klein':
      teamAbbr = MLB_TEAM_CODE.OAK;
      break;
    case 'Michael Tonkin':
      teamAbbr = MLB_TEAM_CODE.NYY;
      break;
    case 'Matt Bowman':
      teamAbbr = MLB_TEAM_CODE.MIN;
      break;
    case 'Thyago Vieira':
      teamAbbr = MLB_TEAM_CODE.ARI;
      break;
    case 'Jesus Tinoco':
      teamAbbr = MLB_TEAM_CODE.CHC;
      break;
    case 'Mitch White':
      teamAbbr = MLB_TEAM_CODE.MIL;
      break;
    case 'Yohan Ramirez':
      teamAbbr = MLB_TEAM_CODE.BOS;
      break;
    case 'Mike Baumann':
      teamAbbr = MLB_TEAM_CODE.LAA;
      break;
    default:
      throw new Error(`Pitcher: ${playerName} has no assignable team`)
  }

  return teamAbbr;
}