import Grid from '@mui/material/Unstable_Grid2';
import { LeagueCode } from 'reducers/types/sharedTypes';
import { DividerHeader, NotFoundModal } from 'components/library';
import { ScorePlacard } from '../scorePlacard';
import { OddOrPickPlacard } from '../oddOrPickPlacard';
import useCompetitionData from '../useCompetitionData';
import PickPlotBar from '../pickPlotBar/PickPlotBar';
import { Alert } from '@mui/material';
import { Analyzer } from 'components/analyzer';

interface CompetitionModalProps {
  selectedLeagueCode: LeagueCode;
  competitionId: number;
}

const CompetitionModal = ({ selectedLeagueCode, competitionId }: CompetitionModalProps) => {
  const competitionData = useCompetitionData(competitionId);
  if (!competitionData) return <NotFoundModal bodyText="No additional competition data found" />

  let open = 'open';
  let current = 'current';
  let away = 'away';
  let home = 'home';
  let under = 'under';
  let over = 'over';

  /** ALL THE CODE BELOW ME TO THE NEXT BLOCK COMMENT SHOULD MOVE TO INSIGHTS HOOK */

  let openSpreadOdds = competitionData.competitionOddsData.spreadOdds.filter(so => so.openOrCurrent === open);
  let openAwaySpreadOdds = openSpreadOdds.filter(soc => soc.homeOrAway === away)[0];
  let openHomeSpreadOdds = openSpreadOdds.filter(soc => soc.homeOrAway === home)[0];
  let isOpenAwaySpreadOddsBestPick = openAwaySpreadOdds.percentage > openHomeSpreadOdds.percentage;
  let isOpenAwaySpreadFavorite = openAwaySpreadOdds.spreadValue < 0;

  let currentSpreadOdds = competitionData.competitionOddsData.spreadOdds.filter(so => so.openOrCurrent === current);
  let currentAwaySpreadOdds = currentSpreadOdds.filter(soo => soo.homeOrAway === away)[0];
  let currentHomeSpreadOdds = currentSpreadOdds.filter(soo => soo.homeOrAway === home)[0];
  let isCurrentAwaySpreadOddsBestPick = currentAwaySpreadOdds.percentage > currentHomeSpreadOdds.percentage;
  let isCurrentAwaySpreadFavorite = currentAwaySpreadOdds.spreadValue < 0;

  // moneyline
  let openMoneylineOdds = competitionData.competitionOddsData.moneylineOdds.filter(mo => mo.openOrCurrent === open);
  let openAwayMoneylineOdds = openMoneylineOdds.filter(moo => moo.homeOrAway === away)[0];
  let openHomeMoneylineOdds = openMoneylineOdds.filter(moo => moo.homeOrAway === home)[0];
  let isOpenAwayMoneylineFavorite = openAwayMoneylineOdds.percentage > openHomeMoneylineOdds.percentage;

  let currentMoneylineOdds = competitionData.competitionOddsData.moneylineOdds.filter(mo => mo.openOrCurrent === current);
  let currentAwayMoneylineOdds = currentMoneylineOdds.filter(moo => moo.homeOrAway === away)[0];
  let currentHomeMoneylineOdds = currentMoneylineOdds.filter(moo => moo.homeOrAway === home)[0];
  let isCurrentAwayMoneylineFavorite = currentAwayMoneylineOdds.percentage > currentHomeMoneylineOdds.percentage;


  // over/under
  let openOverUnderOdds = competitionData.competitionOddsData.overUnderOdds.filter(ouo => ouo.openOrCurrent === current);
  let openUnderOdds = openOverUnderOdds.filter(ouoo => ouoo.overOrUnder === under)[0];
  let openOverOdds = openOverUnderOdds.filter(ouoo => ouoo.overOrUnder === over)[0];
  let isOpenUnderOddsFavorite = openUnderOdds.percentage > openOverOdds.percentage;

  let currentOverUnderOdds = competitionData.competitionOddsData.overUnderOdds.filter(ouo => ouo.openOrCurrent === current);
  let currentUnderOdds = currentOverUnderOdds.filter(ouoo => ouoo.overOrUnder === under)[0];
  let currentOverOdds = currentOverUnderOdds.filter(ouoo => ouoo.overOrUnder === over)[0];
  let isCurrentUnderOddsFavorite = currentUnderOdds.percentage > currentOverOdds.percentage;
  /** ALL THE CODE ABOVE ME TO THE NEXT BLOCK COMMENT SHOULD MOVE TO INSIGHTS HOOK */

  return (
    <Grid container sx={{mb: 10}}>
      <ScorePlacard selectedLeagueCode={selectedLeagueCode} competition={competitionData.competition} />

      <Grid xs={12}>
        <DividerHeader textAlign="center" fullWidth={true}><p>SPREAD</p></DividerHeader>
      </Grid>

      {openAwaySpreadOdds && openHomeSpreadOdds && (<OddOrPickPlacard
        leftItem={openAwaySpreadOdds}
        leftItemType="odd"
        leftItemBadgeTitle={isOpenAwaySpreadFavorite ? 'OPEN FAVORITE' : 'OPEN UNDERDOG'}
        leftIsFeatured={isOpenAwaySpreadOddsBestPick}
        rightItem={openHomeSpreadOdds}
        rightItemType="odd"
        rightItemBadgeTitle={isOpenAwaySpreadFavorite ? 'OPEN UNDERDOG' : 'OPEN FAVORITE'}
        rightIsFeatured={!isOpenAwaySpreadOddsBestPick}
      />)}

      {currentAwaySpreadOdds && currentHomeSpreadOdds && (<OddOrPickPlacard
        leftItem={currentAwaySpreadOdds}
        leftItemType="odd"
        leftItemBadgeTitle={isCurrentAwaySpreadFavorite ? 'CURRENT FAVORITE' : 'CURRENT UNDERDOG'}
        leftIsFeatured={isCurrentAwaySpreadOddsBestPick}
        rightItem={currentHomeSpreadOdds}
        rightItemType="odd"
        rightItemBadgeTitle={isCurrentAwaySpreadFavorite ? 'CURRENT UNDERDOG' : 'CURRENT FAVORITE'}
        rightIsFeatured={!isCurrentAwaySpreadOddsBestPick}
      />)}

      <Grid xs={12}>
        <DividerHeader textAlign="center" fullWidth={false}><p>SPREAD PICKS</p></DividerHeader>
      </Grid>

      <Grid xs={12}>
        <PickPlotBar
          selectedLeagueCode={selectedLeagueCode}
          competition={competitionData.competition}
          competitionPicks={competitionData.competitionPicksData.spreadPicks}
          leftOdd={currentAwaySpreadOdds}
          rightOdd={currentHomeSpreadOdds}
         />
      </Grid>

      <Grid xs={12}>
        <DividerHeader textAlign="center" fullWidth={true}><p>MONEYLINE</p></DividerHeader>
      </Grid>

      {openAwayMoneylineOdds && openHomeMoneylineOdds && (<OddOrPickPlacard
        leftItem={openAwayMoneylineOdds}
        leftItemType="odd"
        leftItemBadgeTitle={isOpenAwayMoneylineFavorite ? 'OPEN FAVORITE' : 'OPEN UNDERDOG'}
        leftIsFeatured={isOpenAwayMoneylineFavorite}
        rightItem={openHomeMoneylineOdds}
        rightItemType="odd"
        rightItemBadgeTitle={isOpenAwayMoneylineFavorite ? 'OPEN UNDERDOG' : 'OPEN FAVORITE'}
        rightIsFeatured={!isOpenAwayMoneylineFavorite}
      />)}

      {currentAwayMoneylineOdds && currentHomeMoneylineOdds && (<OddOrPickPlacard
        leftItem={currentAwayMoneylineOdds}
        leftItemType="odd"
        leftItemBadgeTitle={isCurrentAwayMoneylineFavorite ? 'CURRENT FAVORITE' : 'CURRENT UNDERDOG'}
        leftIsFeatured={isCurrentAwayMoneylineFavorite}
        rightItem={currentHomeMoneylineOdds}
        rightItemType="odd"
        rightItemBadgeTitle={isCurrentAwayMoneylineFavorite ? 'CURRENT UNDERDOG' : 'CURRENT FAVORITE'}
        rightIsFeatured={!isCurrentAwayMoneylineFavorite}
      />)}

      <Grid xs={12}>
        <DividerHeader textAlign="center" fullWidth={false}><p>MONEYLINE PICKS</p></DividerHeader>
      </Grid>

      <Grid xs={12}>
        <PickPlotBar
          selectedLeagueCode={selectedLeagueCode}
          competition={competitionData.competition}
          competitionPicks={competitionData.competitionPicksData.moneylinePicks}
          leftOdd={currentAwayMoneylineOdds}
          rightOdd={currentHomeMoneylineOdds}
         />
      </Grid>

      <Grid xs={12}>
        <DividerHeader textAlign="center" fullWidth={true}><p>OVER / UNDER</p></DividerHeader>
      </Grid>

      {openUnderOdds && openOverOdds && (<OddOrPickPlacard
        leftItem={openUnderOdds}
        leftItemType="odd"
        leftItemBadgeTitle={isOpenUnderOddsFavorite ? 'OPEN FAVORITE' : 'OPEN UNDERDOG'}
        leftIsFeatured={isOpenUnderOddsFavorite}
        rightItem={openOverOdds}
        rightItemType="odd"
        rightItemBadgeTitle={isOpenUnderOddsFavorite ? 'OPEN UNDERDOG' : 'OPEN FAVORITE'}
        rightIsFeatured={!isOpenUnderOddsFavorite}
      />)}

      {currentUnderOdds && currentOverOdds && (<OddOrPickPlacard
        leftItem={currentUnderOdds}
        leftItemType="odd"
        leftItemBadgeTitle={isCurrentUnderOddsFavorite ? 'CURRENT FAVORITE' : 'CURRENT UNDERDOG'}
        leftIsFeatured={isCurrentUnderOddsFavorite}
        rightItem={currentOverOdds}
        rightItemType="odd"
        rightItemBadgeTitle={isCurrentUnderOddsFavorite ? 'CURRENT UNDERDOG' : 'CURRENT FAVORITE'}
        rightIsFeatured={!isCurrentUnderOddsFavorite}
      />)}

      <Grid xs={12}>
        <DividerHeader textAlign="center" fullWidth={false}><p>OVER / UNDER PICKS</p></DividerHeader>
      </Grid>

      <Grid xs={12}>
        <PickPlotBar
          selectedLeagueCode={selectedLeagueCode}
          competition={competitionData.competition}
          competitionPicks={competitionData.competitionPicksData.overUnderPicks}
          leftOdd={currentUnderOdds}
          rightOdd={currentOverOdds}
         />
      </Grid>
      
      <Grid xs={12}>
        <Analyzer selectedLeagueCode={selectedLeagueCode} competition={competitionData.competition} />
      </Grid>
    </Grid>
  );
}


export default CompetitionModal;