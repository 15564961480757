import Papa from 'papaparse';
import { v4 as uuidv4 } from 'uuid';

export const parseOptions = {
  delimiter: ",",
  header: true,
  dynamicTyping: true,
  complete: (results: any, file: string) => {
    console.log('Parsing complete:', results, file);
  },
  download: true,
  error: (error: any, file: any) => {
    console.log('Error while parsing:', error, file);
  },
};

export const parseCsvData = (url: string, callBack: any) => {
  Papa.parse(url, {
      delimiter: ",",
      header: true,
      dynamicTyping: true,
      download: true,
      complete: function(results) {
          callBack(results.data);
      }
  });
}

export const buildMlbDataCsvFilePath = (fileName: string) => {
  return `${process.env.PUBLIC_URL}/mlbData/${fileName}`;
};

export const buildCompetitionsCsvFilePath = (fileName: string) => {
  return `${process.env.PUBLIC_URL}/competitions/${fileName}`;
};

export const generateUUID = () => {
  return uuidv4();
};
