import { MLB_TEAM_CODE, MLB_TEAM_CONFERENCE, MLB_TEAM_DIVISION } from 'config/mlb';
import { BattingSplit, BattingStats, MLBPlayer, MLBTeam, MLBTeams, MLBTimePeriod, PitchingSplit, PitchingStats } from 'reducers/types/mlbTypes';
import { LeagueCode } from 'reducers/types/sharedTypes';

export const buildMLBTeamData = (): { mlbTeams: MLBTeams, mlbTeamCodes: MLB_TEAM_CODE[] } => {
  let mlbTeams: MLBTeams = {};
  let mlbTeamCodes: MLB_TEAM_CODE[] = [];

  for (const teamCode in MLB_TEAM_CODE) {
    const abbr = teamCode as MLB_TEAM_CODE;

    const team: MLBTeam = {
      abbr,
      conference: MLB_TEAM_CONFERENCE[abbr],
      division: MLB_TEAM_DIVISION[abbr],
      teamData: {
        current: {
          pitching: {
            overall: buildPitchingStats(),
            lhh: buildPitchingStats(),
            rhh: buildPitchingStats(),
          },
          batting: {
            overall: buildBattingStats(),
            lhp: buildBattingStats(),
            rhp: buildBattingStats(),
          },
        },
        last14: {
          pitching: {
            overall: buildPitchingStats(),
            lhh: buildPitchingStats(),
            rhh: buildPitchingStats(),
          },
          batting: {
            overall: buildBattingStats(),
            lhp: buildBattingStats(),
            rhp: buildBattingStats(),
          },
        },
        last30: {
          pitching: {
            overall: buildPitchingStats(),
            lhh: buildPitchingStats(),
            rhh: buildPitchingStats(),
          },
          batting: {
            overall: buildBattingStats(),
            lhp: buildBattingStats(),
            rhp: buildBattingStats(),
          },
        },
      },
    };

    mlbTeams[abbr] = team;
    mlbTeamCodes.push(abbr);
  };

  return { mlbTeams, mlbTeamCodes }
};

export const buildMLBTeam = (teamAbbr: MLB_TEAM_CODE): MLBTeam => {
  return {
    abbr: teamAbbr,
    conference: MLB_TEAM_CONFERENCE[teamAbbr],
    division: MLB_TEAM_DIVISION[teamAbbr],
    teamData: {
      current: {
        pitching: {
          overall: buildPitchingStats(),
          lhh: buildPitchingStats(),
          rhh: buildPitchingStats(),
        },
        batting: {
          overall: buildBattingStats(),
          lhp: buildBattingStats(),
          rhp: buildBattingStats(),
        },
      },
      last14: {
        pitching: {
          overall: buildPitchingStats(),
          lhh: buildPitchingStats(),
          rhh: buildPitchingStats(),
        },
        batting: {
          overall: buildBattingStats(),
          lhp: buildBattingStats(),
          rhp: buildBattingStats(),
        },
      },
      last30: {
        pitching: {
          overall: buildPitchingStats(),
          lhh: buildPitchingStats(),
          rhh: buildPitchingStats(),
        },
        batting: {
          overall: buildBattingStats(),
          lhp: buildBattingStats(),
          rhp: buildBattingStats(),
        },
      },
    },
  };
};

export const buildLeagueData = () => {
  return {
    pitching: {
      gamesPlayed: buildLeagueStat(),
      wins: buildLeagueStat(),
      losses: buildLeagueStat(),
      saves: buildLeagueStat(),
      inningsPitched: buildLeagueStat(),
      war: buildLeagueStat(),
      xfip: buildLeagueStat(),
      era: buildLeagueStat(),
      leftOnBasePercentage: buildLeagueStat(),
      totalBattersFaced: buildLeagueStat(),
      homerunsAllowed: buildLeagueStat(),
      runsAllowed: buildLeagueStat(),
      whip: buildLeagueStat(),
      battingAverageAgainst: buildLeagueStat(),
      pitches: {
        fastball: { percentageThrow: buildLeagueStat(), averageVelocity: buildLeagueStat() },
        slider: { percentageThrow: buildLeagueStat(), averageVelocity: buildLeagueStat() },
        cutter: { percentageThrow: buildLeagueStat(), averageVelocity: buildLeagueStat() },
        curveball: { percentageThrow: buildLeagueStat(), averageVelocity: buildLeagueStat() },
        changeup: { percentageThrow: buildLeagueStat(), averageVelocity: buildLeagueStat() },
      },
      clutch: buildLeagueStat(),
    },
    batting: {
      atBats: buildLeagueStat(),
      plateAttempts: buildLeagueStat(),
      hits: buildLeagueStat(),
      homeruns: buildLeagueStat(),
      runsScored: buildLeagueStat(),
      rbis: buildLeagueStat(),
      walks: buildLeagueStat(),
      intentionalWalks: buildLeagueStat(),
      strikeouts: buildLeagueStat(),
      groundIntoDoublePlay: buildLeagueStat(),
      stolenBases: buildLeagueStat(),
      caughtStealing: buildLeagueStat(),
      battingAverage: buildLeagueStat(),
      onBasePercentage: buildLeagueStat(),
      sluggingPercentage: buildLeagueStat(),
      onBasePlusSluggingPercentage: buildLeagueStat(),
      isolatedPower: buildLeagueStat(),
      battingAverageOnBallsInPlay: buildLeagueStat(),
      weightedOnBaseAverage: buildLeagueStat(),
      weightedRunsAboveAverage: buildLeagueStat(),
      // runs per plate attempt scaled where average is 100
      // and is league and park adjusted
      wRCPlus: buildLeagueStat(),
      fastballRunsAboveAveragePer100: buildLeagueStat(),
      sliderRunsAboveAveragePer100: buildLeagueStat(),
      cutterRunsAboveAveragePer100: buildLeagueStat(),
      curveballRunsAboveAveragePer100: buildLeagueStat(),
      changeupRunsAboveAveragePer100: buildLeagueStat(),
      splitFingerFastballRunsAboveAveragePer100: buildLeagueStat(),
      hardHitBallPercentage: buildLeagueStat(),
      runDifferential: buildLeagueStat(),
    }
  }
};

export const buildPitcher = (teamCode: MLB_TEAM_CODE, playerName: string): MLBPlayer => {
  return {
    name: playerName,
    teamCode: teamCode,
    league: LeagueCode.MLB,
    playerData: {
      current: {
        overall: buildPitchingStats(),
        lhh: buildPitchingStats(),
        rhh: buildPitchingStats(),
      },
      last14: {
        overall: buildPitchingStats(),
        lhh: buildPitchingStats(),
        rhh: buildPitchingStats(),
      },
      last30: {
        overall: buildPitchingStats(),
        lhh: buildPitchingStats(),
        rhh: buildPitchingStats(),
      }
    },
  };
};

export const buildPitchingStats = (): PitchingStats => {
  return {
    gamesPlayed: 0,
    wins: 0,
    losses: 0,
    saves: 0,
    inningsPitched: 0,
    war: 0,
    xfip: 0,
    era: 0,
    leftOnBasePercentage: 0,
    totalBattersFaced: 0,
    homerunsAllowed: 0,
    runsAllowed: 0,
    whip: 0,
    battingAverageAgainst: 0,
    pitches: {
      fastball: { percentageThrow: 0, averageVelocity: 0, },
      slider: { percentageThrow: 0, averageVelocity: 0, },
      cutter: { percentageThrow: 0, averageVelocity: 0, },
      curveball: { percentageThrow: 0, averageVelocity: 0, },
      changeup: { percentageThrow: 0, averageVelocity: 0, },
    },
    clutch: 0,
  }
};

export const buildBattingStats = () => {
  return {
    atBats: 0,
    plateAttempts: 0,
    hits: 0,
    homeruns: 0,
    runsScored: 0,
    rbis: 0,
    walks: 0,
    intentionalWalks: 0,
    strikeouts: 0,
    groundIntoDoublePlay: 0,
    stolenBases: 0,
    caughtStealing: 0,
    battingAverage: 0,
    onBasePercentage: 0,
    sluggingPercentage: 0,
    onBasePlusSluggingPercentage: 0,
    isolatedPower: 0,
    battingAverageOnBallsInPlay: 0,
    weightedOnBaseAverage: 0,
    weightedRunsAboveAverage: 0,
    // runs per plate attempt scaled where average is 100
    // and is league and park adjusted
    wRCPlus: 0,
    fastballRunsAboveAveragePer100: 0,
    sliderRunsAboveAveragePer100: 0,
    cutterRunsAboveAveragePer100: 0,
    curveballRunsAboveAveragePer100: 0,
    changeupRunsAboveAveragePer100: 0,
    splitFingerFastballRunsAboveAveragePer100: 0,
    hardHitBallPercentage: 0,
    runDifferential: 0,
  }
};

export const buildBatter = (teamCode: MLB_TEAM_CODE, playerName: string): MLBPlayer => {
  return {
    name: playerName,
    teamCode: teamCode,
    league: LeagueCode.MLB,
    playerData: {
      current: {
        overall: buildBattingStats(),
        lhp: buildBattingStats(),
        rhp: buildBattingStats(),
      },
      last14: {
        overall: buildBattingStats(),
        lhp: buildBattingStats(),
        rhp: buildBattingStats(),
      },
      last30: {
        overall: buildBattingStats(),
        lhp: buildBattingStats(),
        rhp: buildBattingStats(),
      }
    },
  };
};

export const formatTeam = (
  statCategory: 'batting' | 'pitching',
  teamCode: MLB_TEAM_CODE,
  timePeriod: MLBTimePeriod,
  split: PitchingSplit | BattingSplit,
  splitData?: PitchingStats | BattingStats,
  existingTeam?: MLBTeam,
) => {
  let team = buildMLBTeam(teamCode);
  if (existingTeam) {
    team = existingTeam;
  };

  if (!splitData) {
    return team
  };

  if (statCategory === 'batting') {
    let battingSplit = split as BattingSplit;
    let battingSplitData = splitData as BattingStats;

    let battingSplits = team.teamData[timePeriod][statCategory];
    battingSplits[battingSplit] = battingSplitData;
  };

  if (statCategory === 'pitching') {
    let pitchingSplit = split as PitchingSplit;
    let pitchingSplitData = splitData as PitchingStats;

    let pitchingSplits = team.teamData[timePeriod][statCategory];
    pitchingSplits[pitchingSplit] = pitchingSplitData; 
  };

  return team;
};

export const buildLeagueStat = () => {
  return {
    leagueTotals: [],
    rankedTotals: [],
    leagueAverage: 0,
    correlationToWins: 0,
    stdDeviation: 0,
  }
};