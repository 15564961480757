export enum NCAA_TEAM_CODE {
  ABIL = 'ABIL',
  AF = 'AF',
  AKRON = 'AKRON',
  ALAM = 'ALAM',
  ALBANY = 'ALBANY',
  ALCORN = 'ALCORN',
  AMER = 'AMER',
  AP = 'AP',
  APLST = 'APLST',
  ARIZ = 'ARIZ',
  ARIZST = 'ARIZST',
  ARK = 'ARK',
  ARKST = 'ARKST',
  ARMY = 'ARMY',
  ARPB = 'ARPB',
  AUBURN = 'AUBURN',
  BALLST = 'BALLST',
  BAMA = 'BAMA',
  BAYLOR = 'BAYLOR',
  BC = 'BC',
  BGREEN = 'BGREEN',
  BING = 'BING',
  BOISE = 'BOISE',
  BTHN = 'BTHN',
  BUCK = 'BUCK',
  BUFF = 'BUFF',
  BUTLER = 'BUTLER',
  BYU = 'BYU',
  CAL = 'CAL',
  CAMP = 'CAMP',
  CAR = 'CAR',
  CCTST = 'CCTST',
  CHARLO = 'CHARLO',
  TNCHAT = 'TNCHAT',
  CINCY = 'CINCY',
  CLEM = 'CLEM',
  CMICH = 'CMICH',
  COLO = 'COLO',
  COLOST = 'COLOST',
  CPOLY = 'CPOLY',
  CREIGH = 'CREIGH',
  CSFULL = 'CSFULL',
  CSN = 'CSN',
  CSTCAR = 'CSTCAR',
  CUSE = 'CUSE',
  DAVID = 'DAVID',
  DAVIS = 'DAVIS',
  DAYTON = 'DAYTON',
  DEPAUL = 'DEPAUL',
  DUKE = 'DUKE',
  DUQ = 'DUQ',
  ECU = 'ECU',
  EIL = 'EIL',
  EKY = 'EKY',
  ELON = 'ELON',
  EMICH = 'EMICH',
  ETNST = 'ETNST',
  EWASH = 'EWASH',
  FAU = 'FAU',
  FGC = 'FGC',
  FIU = 'FIU',
  FLA = 'FLA',
  FORD = 'FORD',
  FRESNO = 'FRESNO',
  FSU = 'FSU',
  FUR = 'FUR',
  GAS = 'GAS',
  GAST = 'GAST',
  GATECH = 'GATECH',
  GMASON = 'GMASON',
  GRAM = 'GRAM',
  GTOWN = 'GTOWN',
  GWASH = 'GWASH',
  HARTFD = 'HARTFD',
  HAWAII = 'HAWAII',
  HOU = 'HOU',
  HOUBP = 'HOUBP',
  HOW = 'HOW',
  IDAHO = 'IDAHO',
  IDST = 'IDST',
  ILL = 'ILL',
  ILST = 'ILST',
  IND = 'IND',
  INST = 'INST',
  IOWA = 'IOWA',
  IOWAST = 'IOWAST',
  JACKST = 'JACKST',
  JAXST = 'JAXST',
  JMAD = 'JMAD',
  JVILLE = 'JVILLE',
  KANSAS = 'KANSAS',
  KENSAW = 'KENSAW',
  KENTST = 'KENTST',
  KSTATE = 'KSTATE',
  LAF = 'LAF',
  LAMAR = 'LAMAR',
  LAMON = 'LAMON',
  LATECH = 'LATECH',
  LEH = 'LEH',
  LIB = 'LIB',
  LINDEN = 'LINDEN',
  LNGBCH = 'LNGBCH',
  LPSCMB = 'LPSCMB',
  LSALLE = 'LSALLE',
  LSU = 'LSU',
  LVILLE = 'LVILLE',
  MAINE = 'MAINE',
  MARQET = 'MARQET',
  MASLOW = 'MASLOW',
  MD = 'MD',
  MEMP = 'MEMP',
  MERMAK = 'MERMAK',
  MIAMI = 'MIAMI',
  MIAOH = 'MIAOH',
  MICH = 'MICH',
  MICHST = 'MICHST',
  MINN = 'MINN',
  MISS = 'MISS',
  MISSST = 'MISSST',
  MIZZOU = 'MIZZOU',
  MONST = 'MONST',
  MNTNA = 'MNTNA',
  MRSHL = 'MRSHL',
  MTSU = 'MTSU',
  MURYST = 'MURYST',
  NAL = 'NAL',
  NAU = 'NAU',
  NAVY = 'NAVY',
  NCAT = 'NCAT',
  NCOLO = 'NCOLO',
  NCST = 'NCST',
  ND = 'ND',
  NDK = 'NDK',
  NDST = 'NDST',
  NEB = 'NEB',
  NEVADA = 'NEVADA',
  NH = 'NH',
  NICHST = 'NICHST',
  NILL = 'NILL',
  NJTECH = 'NJTECH',
  NMEX = 'NMEX',
  NMEXST = 'NMEXST',
  NOVA = 'NOVA',
  NRFST = 'NRFST',
  NTEXAS = 'NTEXAS',
  NWEST = 'NWEST',
  NWST = 'NWST',
  ODU = 'ODU',
  OHIO = 'OHIO',
  OHIOST = 'OHIOST',
  OKLA = 'OKLA',
  OKLAST = 'OKLAST',
  OREG = 'OREG',
  OREGST = 'OREGST',
  PITT = 'PITT',
  PORTST = 'PORTST',
  PROV = 'PROV',
  PSU = 'PSU',
  PURDUE = 'PURDUE',
  RI = 'RI',
  RICE = 'RICE',
  RICH = 'RICH',
  ROB = 'ROB',
  RUT = 'RUT',
  SACST = 'SACST',
  SAMST = 'SAMST',
  SALA = 'SALA',
  SC = 'SC',
  SDGST = 'SDGST',
  SDST = 'SDST',
  SELOU = 'SELOU',
  SEMOST = 'SEMOST',
  SETON = 'SETON',
  SFLA = 'SFLA',
  SIL = 'SIL',
  SJST = 'SJST',
  SMU = 'SMU',
  STBON = 'STBON',
  STETSON = 'STETSON',
  STJOES = 'STJOES',
  STJOHN = 'STJOHN',
  STLOU = 'STLOU',
  STONYBRK = 'STONYBRK',
  STNFRD = 'STNFRD',
  SUTAH = 'SUTAH',
  TAMC = 'TAMC',
  TCU = 'TCU',
  TEMPLE = 'TEMPLE',
  TENN = 'TENN',
  TEXAS = 'TEXAS',
  TNMART = 'TNMART',
  TNTECH = 'TNTECH',
  TOLEDO = 'TOLEDO',
  TRLST = 'TRLST',
  TULANE = 'TULANE',
  TULSA = 'TULSA',
  TROY = 'TROY',
  TWST = 'TWST',
  TXAM = 'TXAM',
  TXSA = 'TXSA',
  TXSTSM = 'TXSTSM',
  TXTECH = 'TXTECH',
  UAB = 'UAB',
  UCDAV = 'UCDAV',
  UCF = 'UCF',
  UCIRV = 'UCIRV',
  UCLA = 'UCLA',
  UCONN = 'UCONN',
  UCRIV = 'UCRIV',
  UCSB = 'UCSB',
  UGA = 'UGA',
  UK = 'UK',
  UL = 'UL',
  UMASS = 'UMASS',
  UMBC = 'UMBC',
  UNC = 'UNC',
  UNF = 'UNF',
  UNLV = 'UNLV',
  USC = 'USC',
  USCUP = 'USCUP',
  USM = 'USM',
  UTAH = 'UTAH',
  UTAHST = 'UTAHST',
  UTEP = 'UTEP',
  UVA = 'UVA',
  UVM = 'UVM',
  VANDY = 'VANDY',
  VATECH = 'VATECH',
  VCU = 'VCU',
  WAKE = 'WAKE',
  WASH = 'WASH',
  WASHST = 'WASHST',
  WCAR = 'WCAR',
  WEBER = 'WEBER',
  WICHST = 'WICHST',
  WIL = 'WIL',
  WISC = 'WISC',
  WMICH = 'WMICH',
  WKY = 'WKY',
  WVU = 'WVU',
  WYO = 'WYO',
  XAVIER = 'XAVIER',
  YST = 'YST',
};

export enum NCAA_TEAM_NAME_LONG {
  ABIL = 'Abilene Christian Wildcats',
  AF = 'Air Force Falcons',
  AKRON = 'Akron Zips',
  ALAM = 'Alabama A&M Bulldogs',
  ALBANY = 'Albany Great Danes',
  ALCORN = 'Alcorn State Braves',
  AMER = 'American University',
  AP = 'Austin Peay',
  APLST = 'App State Mountaineers',
  ARIZ = 'Arizona Wildcats',
  ARIZST = 'Arizona State Sun Devils',
  ARK = 'Arkansas Razorbacks',
  ARKST = 'Arkansas State Red Wolves',
  ARMY = 'Army Black Knights',
  ARPB = 'Arkansas-Pine Bluff Golden Lions',
  AUBURN = 'Auburn Tigers',
  BALLST = 'Ball State Cardinals',
  BAMA = 'Alabama Crimson Tide',
  BAYLOR = 'Baylor Bears',
  BC = 'Boston College Eagles',
  BGREEN = 'Bowling Green Falcons',
  BING = 'Binghamton Bearcats',
  BOISE = 'Boise State Broncos',
  BTHN = 'Bethune-Cookman Wildcats',
  BUCK = 'Bucknell Bison',
  BUFF = 'Buffalo Bulls',
  BUTLER = 'Butler Bulldogs',
  BYU = 'BYU Cougars',
  CAL = 'California Golden Bears',
  CAMP = 'Campbell Fighting Camels',
  CAR = 'Central Arkansas Bears',
  CCTST = 'Central Connecticut Blue Devils',
  CHARLO = 'Charlotte 49ers',
  TNCHAT = 'Chattanooga Mocs',
  CINCY = 'Cincinnati Bearcats',
  CLEM = 'Clemson Tigers',
  CMICH = 'Central Michigan Chippewas',
  COLO = 'Colorado Buffaloes',
  COLOST = 'Colorado State Rams',
  CPOLY = 'Cal Poly Mustangs',
  CREIGH = 'Creighton Bluejays',
  CSFULL = 'Cal State Fullerton Titans',
  CSN = 'Northridge Matadors',
  CSTCAR = 'Coastal Carolina Chanticleers',
  CUSE = 'Syracuse Orange',
  DAVID = 'Davidson Wildcats',
  DAVIS = 'UC Davis Aggies',
  DAYTON = 'Dayton Flyers',
  DEPAUL = 'DePaul Blue Demons',
  DUKE = 'Duke Blue Devils',
  DUQ = 'Duquesne Dukes',
  ECU = 'East Carolina Pirates',
  EIL = 'Eastern Illinois Panthers',
  EKY = 'Eastern Kentucky Colonels',
  ELON = 'Elon Phoenix',
  EMICH = 'Eastern Michigan Eagles',
  ETNST = 'Eastern Tennessee State Buccaneers',
  EWASH = 'Eastern Washington Eagles',
  FAU = 'Florida Atlantic Owls',
  FGC = 'Florida Gulf Coast Eagles',
  FIU = 'FIU Panthers',
  FLA = 'Florida Gators',
  FORD = 'Fordham Rams',
  FRESNO = 'Fresno State Bulldogs',
  FSU = 'Florida State Seminoles',
  FUR = 'Furman Paladins',
  GAS = 'Georgia Southern Eagles',
  GAST = 'Georgia State Panthers',
  GATECH = 'Georgia Tech Yellow Jackets',
  GMASON = 'George Mason Patriots',
  GRAM = 'Grambling State Tigers',
  GTOWN = 'Georgetown Hoyas',
  GWASH = 'George Washington Colonials',
  HARTFD = 'Hartford Hawks',
  HAWAII = 'Hawaii Rainbow Warriors',
  HOU = 'Houston Cougars',
  HOUBP = 'Houston Christian Huskies',
  HOW = 'Howard Bison',
  IDAHO = 'Idaho Vandals',
  IDST = 'Idaho State Bengals',
  ILL = 'Illinois Fighting Illini',
  ILST = 'Illinois State Redbirds',
  IND = 'Indiana Hoosiers',
  INST = 'Indiana State Sycamores',
  IOWA = 'Iowa Hawkeyes',
  IOWAST = 'Iowa State Cyclones',
  JACKST = 'Jackson State Tigers',
  JAXST = 'Jacksonville State Gamecocks',
  JMAD = 'James Madison Dukes',
  JVILLE = 'Jacksonville Dolphins',
  KANSAS = 'Kansas Jayhawks',
  KENTST = 'Kent State Golden Flashes',
  KENSAW = 'Kennesaw State Owls',
  KSTATE = 'Kansas State Wildcats',
  LAF = 'Lafayette Leopards',
  LAMAR = 'Lamar Cardinals',
  LAMON = 'UL Monroe Warhawks',
  LATECH = 'Louisiana Tech Bulldogs',
  LEH = 'Lehigh Mountain Hawks',
  LIB = 'Liberty Flames',
  LINDEN = 'Lindenwood Lions',
  LNGBCH = 'Long Beach State Beach',
  LPSCMB = 'Lipscomb Bisons',
  LSALLE = 'La Salle Explorers',
  LSU = 'LSU Tigers',
  LVILLE = 'Louisville Cardinals',
  MAINE = 'Maine Black Bears',
  MARQET = 'Marquette Golden Eagles',
  MASLOW = 'UMass Lowell River Hawks',
  MD = 'Maryland Terrapins',
  MEMP = 'Memphis Tigers',
  MERMAK = 'Merrimack Warriors',
  MIAMI = 'Miami (FL) Hurricanes',
  MIAOH = 'Miami (OH) RedHawks',
  MICH = 'Michigan Wolverines',
  MICHST = 'Michigan State Spartans',
  MINN = 'Minnesota Golden Gophers',
  MISS = 'Ole Miss Rebels',
  MISSST = 'Mississippi State Bulldogs',
  MIZZOU = 'Missouri Tigers',
  MONST = 'Montana State Bobcats',
  MNTNA = 'Montana Grizzlies',
  MRSHL = 'Marshall Thundering Herd',
  MTSU = 'Middle Tenn. Blue Raiders',
  MURYST = 'Murray State Racers',
  NAL = 'North Alabama Lions',
  NAU = 'Northern Arizona Lumberjacks',
  NAVY = 'Navy Midshipmen',
  NCAT = 'North Carolina A&T Aggies',
  NCOLO = 'Northern Colorado Bears',
  NCST = 'NC State Wolfpack',
  ND = 'Notre Dame Fighting Irish',
  NDK = 'North Dakota Fighting Hawks',
  NDST = 'North Dakota State Bison',
  NEB = 'Nebraska Cornhuskers',
  NEVADA = 'Nevada Wolf Pack',
  NH = 'New Hampshire Wildcats',
  NICHST = 'Nicholls Colonels',
  NILL = 'Northern Illinois Huskies',
  NJTECH = 'New Jersey Tech Highlanders',
  NMEX = 'New Mexico Lobos',
  NMEXST = 'New Mexico State Aggies',
  NOVA = 'Villanova Wildcats',
  NRFST = 'Norfolk State Spartans',
  NTEXAS = 'North Texas Mean Green',
  NWEST = 'Northwestern Wildcats',
  NWST = 'Northwestern State Demons',
  ODU = 'Old Dominion Monarchs',
  OHIO = 'Ohio Bobcats',
  OHIOST = 'Ohio State Buckeyes',
  OKLA = 'Oklahoma Sooners',
  OKLAST = 'Oklahoma State Cowboys',
  OREG = 'Oregon Ducks',
  OREGST = 'Oregon State Beavers',
  PORTST = 'Portland State Vikings',
  PITT = 'Pittsburgh Panthers',
  PROV = 'Providence Friars',
  PSU = 'Penn State Nittany Lions',
  PURDUE = 'Purdue Boilermakers',
  RI = 'Rhode Island Rams',
  RICE = 'Rice Owls',
  RICH = 'Richmond Spiders',
  ROB = 'Robert Morris Colonials',
  RUT = 'Rutgers Scarlet Knights',
  SACST = 'Sacramento State Hornets',
  SALA = 'South Alabama Jaguars',
  SAMST = 'Sam Houston Bearkats',
  SC = 'South Carolina Gamecocks',
  SDGST = 'San Diego State Aztecs',
  SDST = 'South Dakota State Jackrabbits',
  SELOU = 'SE Louisiana Lions',
  SEMOST = 'SE Missouri St. Redhawks',
  SETON = 'Seton Hall Pirates',
  SFLA = 'South Florida Bulls',
  SIL = 'Southern Illinois Salukis',
  SJST = 'San Jose State Spartans',
  SMU = 'SMU Mustangs',
  STBON = 'St. Bonaventure Bonnies',
  STETSON = 'Stetson Hatters',
  STJOES = "Saint Jospeh's Hawks",
  STJOHN = "Saint John's Red Storm",
  STLOU = 'Saint Louis Billikens',
  STONYBRK = 'Stony Brook Seawolves',
  STNFRD = 'Stanford Cardinal',
  SUTAH = 'Southern Utah Thunderbirds',
  TAMC = 'Texas A&M-Commerce Lions',
  TCU = 'TCU Horned Frogs',
  TEMPLE = 'Temple Owls',
  TENN = 'Tennessee Volunteers',
  TEXAS = 'Texas Longhorns',
  TNMART = 'UT Martin Skyhawks',
  TNTECH = 'Tennessee Tech Golden Eagles',
  TOLEDO = 'Toledo Rockets',
  TRLST = 'Tarleton State Texans',
  TROY = 'Troy Trojans',
  TULANE = 'Tulane Green Wave',
  TULSA = 'Tulsa Golden Hurricane',
  TWST = 'Towson Tigers',
  TXAM = 'Texas A&M Aggies',
  TXSA = 'UTSA Roadrunners',
  TXSTSM = 'Texas State Bobcats',
  TXTECH = 'Texas Tech Red Raiders',
  UAB = 'UAB Blazers',
  UCDAV = 'UC Davis Aggies',
  UCF = 'UCF Knights',
  UCIRV = 'UC Irvine Anteaters',
  UCLA = 'UCLA Bruins',
  UCONN = 'Connecticut Huskies',
  UCRIV = 'California Riverside Highlanders',
  UCSB = 'Santa Barbra Gauchos',
  UGA = 'Georgia Bulldogs',
  UK = 'Kentucky Wildcats',
  UL = "Louisiana Ragin' Cajuns",
  UMASS = 'Massachusetts Minutemen',
  UMBC = 'UMBC Retreivers',
  UNC = 'North Carolina Tar Heels',
  UNF = 'North Florida Ospreys',
  UNLV = 'UNLV Rebels',
  USC = 'USC Trojans',
  USCUP = 'South Carolina Upstate Spartans',
  USM = 'Southern Miss Golden Eagles',
  UTAH = 'Utah Utes',
  UTAHST = 'Utah State Aggies',
  UTEP = 'UTEP Miners',
  UVA = 'Virginia Cavaliers',
  UVM = 'Vermont Catamounts',
  VANDY = 'Vanderbilt Commodores',
  VATECH = 'Virginia Tech Hokies',
  VCU = 'VCU Rams',
  WAKE = 'Wake Forrest Demon Deacons',
  WASH = 'Washington Huskies',
  WASHST = 'Washington State Cougars',
  WCAR = 'Western Carolina Catamounts',
  WEBER = 'Weber State Wildcats',
  WICHST = 'Wichita State Shockers',
  WIL = 'Western Illinois Leathernecks',
  WISC = "Wisconsin Badgers",
  WMICH = 'Western Michigan Broncos',
  WKY = 'Western Kentucky Hilltoppers',
  WVU = 'West Virginia Mountaineers',
  WYO = 'Wyoming Cowboys',
  XAVIER = 'Xavier Musketeers',
  YST = 'Youngstown State Penguins',
};

export const NCAA_COLOR_CODES: {  [key in NCAA_TEAM_CODE]: { primary: string, secondary: string } } = {
  ABIL: { primary: 'rgb(79, 33, 112, 0.75)', secondary: 'rgb(197, 198, 200, 0.75)' },
  
  AF: { primary: 'rgb(0, 0, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },

  AKRON: { primary: 'rgb(4, 30, 66, 0.75)', secondary: 'rgb(168, 153, 104, 0.75)' },
  ALAM: { primary: 'rgb(102, 0, 0, 0.75)', secondary: 'rgb(255, 255, 255, 0.75)' },
  ALBANY: { primary: 'rgb(70, 22, 107, 0.75)', secondary: 'rgb(238, 178, 17, 0.75)' },
  ALCORN: { primary: 'rgb(206, 142, 0, 0.75)', secondary: 'rgb(75, 48, 106, 0.75)' },
  AMER: { primary: 'rgb(20, 61, 141, 0.75)', secondary: 'rgb(196, 18, 48, 0.75)' },
  AP: { primary: 'rgb(196, 30, 58, 0.75)', secondary: 'rgb(173, 175, 170, 0.75)' },
  APLST: { primary: 'rgb(34, 34, 34, 0.75)', secondary: 'rgb(255, 204, 0, 0.75)' },
  ARIZ: { primary: 'rgb(204, 0, 51, 0.75)', secondary: 'rgb(0, 51, 102, 0.75)' },
  ARIZST: { primary: 'rgb(140, 29, 64, 0.75)', secondary: 'rgb(255, 198, 39, 0.75)' },
  ARK: { primary: 'rgb(157, 34, 53, 0.75)', secondary: 'rgb(255, 255, 255, 0.75)' },
  ARKST: { primary: 'rgb(204, 0, 51, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  ARMY: { primary: 'rgb(211, 188, 141, 0.75)', secondary: 'rgb(177, 179, 179, 0.75)' },
  ARPB: { primary: 'rgb(238, 179, 16, 0.75)', secondary: 'rgb(227, 24, 55, 0.75)' },
  AUBURN: { primary: 'rgb(12, 35, 64, 0.75)', secondary: 'rgb(232, 119, 34, 0.75)' },
  
  BALLST: { primary: 'rgb(0, 0, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  
  BAMA: { primary: 'rgb(158, 27, 50, 0.75)', secondary: 'rgb(130, 138, 143, 0.75)' },
  BAYLOR: { primary: 'rgb(21, 71, 52, 0.75)', secondary: 'rgb(255, 184, 28, 0.75)' },
  BC: { primary: 'rgb(115, 0, 10, 0.75)', secondary: 'rgb(203, 182, 119, 0.75)' },
  BGREEN: { primary: 'rgb(254, 80, 0, 0.75)', secondary: 'rgb(79, 44, 29, 0.75)' },
  BING: { primary: 'rgb(0, 90, 67, 0.75)', secondary: 'rgb(22, 155, 98, 0.75)' },
  BOISE: { primary: 'rgb(214, 67, 9, 0.75)', secondary: 'rgb(0, 51, 160, 0.75)' },

  BTHN: { primary: 'rgb(0, 0, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  BUCK: { primary: 'rgb(0, 0, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  
  BUFF: { primary: 'rgb(0, 91, 187, 0.75)', secondary: 'rgb(255, 255, 255, 0.75)' },
  BUTLER: { primary: 'rgb(19, 41, 75, 0.75)', secondary: 'rgb(116, 118, 120, 0.75)' },
  BYU: { primary: 'rgb(0, 46, 93, 0.75)', secondary: 'rgb(0, 87, 184, 0.75)' },
  CAL: { primary: 'rgb(100, 71, 10, 0.75)', secondary: 'rgb(76, 34, 21, 0.75)' },
  
  CAMP: { primary: 'rgb(0, 0, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },

  CAR: { primary: 'rgb(79, 45, 127, 0.75)', secondary: 'rgb(129, 138, 143, 0.75)' },
  CCTST: { primary: 'rgb(0, 111, 113, 0.75)', secondary: 'rgb(162, 119, 82, 0.75)' },
  CHARLO: { primary: 'rgb(4, 106, 56, 0.75)', secondary: 'rgb(185, 151, 91, 0.75)' },
  TNCHAT: { primary: 'rgb(0, 56, 107, 0.75)', secondary: 'rgb(224, 170, 15, 0.75)' },
  CINCY: { primary: 'rgb(224, 1, 34, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  
  CMICH: { primary: 'rgb(0, 0, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },

  COLO: { primary: 'rgb(207, 184, 124, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  COLOST: { primary: 'rgb(30, 77, 43, 0.75)', secondary: 'rgb(200, 195, 114, 0.75)' },
  CLEM: { primary: 'rgb(245, 102, 0, 0.75)', secondary: 'rgb(82, 45, 128, 0.75)' },
  CPOLY: { primary: 'rgb(0, 56, 49, 0.75)', secondary: 'rgb(255, 227, 149, 0.75)' }, 
  CREIGH: { primary: 'rgb(0, 93, 170, 0.75)', secondary: 'rgb(0, 36, 93, 0.75)' },
  CSFULL: { primary: 'rgb(220, 130, 24, 0.75)', secondary: 'rgb(0, 39, 76, 0.75)' },
  CSN: { primary: 'rgb(207, 10, 44, 0.75)', secondary: 'rgb(195, 197, 200, 0.75)' },
  CSTCAR: { primary: 'rgb(0,111,113, 0.75)', secondary: 'rgb(162,119,82, 0.75)' },
  CUSE: { primary: 'rgb(247, 105, 0, 0.75)', secondary: 'rgb(255, 255, 255, 0.75)' },
  DAVID: { primary: 'rgb(172, 26, 47, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  
  DAVIS: { primary: 'rgb(0, 0, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  
  DAYTON: { primary: 'rgb(206, 17, 65, 0.75)', secondary: 'rgb(0, 75, 141, 0.75)' },
  DEPAUL: { primary: 'rgb(0, 94, 184, 0.75)', secondary: 'rgb(228, 0, 43, 0.75)' },
  DUKE: { primary: 'rgb(0, 48, 135, 0.75)', secondary: 'rgb(0, 83, 155, 0.75)' },
  DUQ: { primary: 'rgb(4, 30, 66, 0.75)', secondary: 'rgb(186, 12, 47, 0.75)' },
  ECU: { primary: 'rgb(89, 42, 138, 0.75)', secondary: 'rgb(253, 200, 47, 0.75)' },
  EIL: { primary: 'rgb(145, 146, 149, 0.75)', secondary: 'rgb(0, 75, 131, 0.75)' },
  EKY: { primary: 'rgb(76, 21, 30, 0.75)', secondary: 'rgb(255, 255, 255, 0.75)' },
  
  ELON: { primary: 'rgb(0, 0, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },

  EMICH: { primary: 'rgb(0, 105, 63, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  ETNST: { primary: 'rgb(4, 30, 66, 0.75)', secondary: 'rgb(255, 199, 44, 0.75)' },
  EWASH: { primary: 'rgb(167, 25, 51, 0.75)', secondary: 'rgb(255, 255, 255, 0.75)' },
  FAU: { primary: 'rgb(0, 51, 102, 0.75)', secondary: 'rgb(204, 0, 0, 0.75)' },
  FGC: { primary: 'rgb(0, 45, 114, 0.75)', secondary: 'rgb(0, 119, 73, 0.75)' },
  FIU: { primary: 'rgb(8, 30, 63, 0.75)', secondary: 'rgb(182, 134, 44, 0.75)' },
  FLA: { primary: 'rgb(0, 33, 165, 0.75)', secondary: 'rgb(250, 70, 22, 0.75)' },
  FORD: { primary: 'rgb(134, 0, 56, 0.75)', secondary: 'rgb(255, 255, 255, 0.75)' },
  FRESNO: { primary: 'rgb(219, 0, 50, 0.75)', secondary: 'rgb(0, 46, 109, 0.75)' },
  FSU: { primary: 'rgb(120, 47, 64, 0.75)', secondary: 'rgb(206, 184, 136, 0.75)' },
  FUR: { primary: 'rgb(32, 21, 71, 0.75)', secondary: 'rgb(88, 44, 131, 0.75)' },
  GAS: { primary: 'rgb(1, 30, 65, 0.75)', secondary: 'rgb(135, 113, 77, 0.75)' },
  GAST: { primary: 'rgb(0, 57, 166, 0.75)', secondary: 'rgb(198, 12, 48, 0.75)' },
  GATECH: { primary: 'rgb(179, 163, 105, 0.75)', secondary: 'rgb(0, 48, 87, 0.75)' },
  GMASON: { primary: 'rgb(30, 98, 56, 0.75)', secondary: 'rgb(226, 168, 43, 0.75)' },
  GRAM: { primary: 'rgb(234, 169, 33, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  GTOWN: { primary: 'rgb(4, 30, 66, 0.75)', secondary: 'rgb(141, 129, 123, 0.75)' },
  GWASH: { primary: 'rgb(226, 203, 146, 0.75)', secondary: 'rgb(0, 38, 84, 0.75)' },
  HARTFD: { primary: 'rgb(192, 36, 39, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  HAWAII: { primary: 'rgb(2, 71, 49, 0.75)', secondary: 'rgb(200, 200, 200, 0.75)' },
  HOU: { primary: 'rgb(201, 42, 57, 0.75)', secondary: 'rgb(125, 41, 53, 0.75)' },
  
  HOUBP: { primary: 'rgb(0, 0, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  
  HOW: { primary: 'rgb(0, 58, 99, 0.75)', secondary: 'rgb(227, 24, 55, 0.75)' },
  IDAHO: { primary: 'rgb(179, 163, 105, 0.75)', secondary: 'rgb(234, 171, 0, 0.75)' },
  
  IDST: { primary: 'rgb(0, 0, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  
  ILL: { primary: 'rgb(19, 41, 75, 0.75)', secondary: 'rgb(232, 74, 39, 0.75)' },
  ILST: { primary: 'rgb(200, 16, 46, 0.75)', secondary: 'rgb(254, 219, 0, 0.75)' },
  IND: { primary: 'rgb(153, 0, 0, 0.75)', secondary: 'rgb(238, 237, 235, 0.75)' },
  INST: { primary: 'rgb(244, 121, 32, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  IOWA: { primary: 'rgb(255, 205, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  IOWAST: { primary: 'rgb(200, 16, 46, 0.75)', secondary: 'rgb(241, 190, 72, 0.75)' },
  JACKST: { primary: 'rgb(0, 45, 98, 0.75)', secondary: 'rgb(0, 142, 214, 0.75)' },
  JAXST: { primary: 'rgb(203, 32, 39, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },

  JMAD: { primary: 'rgb(0, 0, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },

  JVILLE: { primary: 'rgb(0, 78, 68, 0.75)', secondary: 'rgb(137, 138, 141, 0.75)' },
  KANSAS: { primary: 'rgb(0, 81, 186, 0.75)', secondary: 'rgb(232, 0, 13, 0.75)' },
  KENTST: { primary: 'rgb(0, 57, 118, 0.75)', secondary: 'rgb(239, 171, 0, 0.75)' },
  KENSAW: { primary: 'rgb(253, 187, 48, 0.75)', secondary: 'rgb(11, 19, 21, 0.75)' },
  KSTATE: { primary: 'rgb(81, 40, 136, 0.75)', secondary: 'rgb(209, 209, 209, 0.75)' },
  
  LAF: { primary: 'rgb(0, 0, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },

  LAMAR: { primary: 'rgb(220, 0, 50, 0.75)', secondary: 'rgb(255, 255, 255, 0.75)' },
  LAMON: { primary: 'rgb(134, 0, 41, 0.75)', secondary: 'rgb(253, 185, 19, 0.75)' },
  LATECH: { primary: 'rgb(0, 47, 139, 0.75)', secondary: 'rgb(255, 0, 0, 0.75)' },
  LEH: { primary: 'rgb(101, 54, 0, 0.75)', secondary: 'rgb(255, 210, 79, 0.75)' },
  LIB: { primary: 'rgb(0, 45, 98, 0.75)', secondary: 'rgb(196, 18, 48, 0.75)' },
  LINDEN: { primary: 'rgb(0, 45, 98, 0.75)', secondary: 'rgb(196, 18, 48, 0.75)' },
  LNGBCH: { primary: 'rgb(255, 199, 42, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  LPSCMB: { primary: 'rgb(51, 30, 84, 0.75)', secondary: 'rgb(244, 170, 0, 0.75)' },
  LSALLE: { primary: 'rgb(255, 204, 0, 0.75)', secondary: 'rgb(0, 51, 102, 0.75)' },
  LSU: { primary: 'rgb(70, 29, 124, 0.75)', secondary: 'rgb(253, 208, 35, 0.75)' },
  LVILLE: { primary: 'rgb(227, 27, 35, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  MAINE: { primary: 'rgb(0, 50, 99, 0.75)', secondary: 'rgb(176, 215, 255, 0.75)' },
  MARQET: { primary: 'rgb(0, 51, 102, 0.75)', secondary: 'rgb(205, 204, 0, 0.75)' },
  MASLOW: { primary: 'rgb(0, 61, 165, 0.75)', secondary: 'rgb(200, 16, 46, 0.75)' },
  
  MERMAK: { primary: 'rgb(0, 0, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  
  MEMP: { primary: 'rgb(0, 48, 135, 0.75)', secondary: 'rgb(137, 141, 141, 0.75)' },
  MD: { primary: 'rgb(224, 58, 62, 0.75)', secondary: 'rgb(255, 213, 32, 0.75)' },
  MIAMI: { primary: 'rgb(244, 115, 33, 0.75)', secondary: 'rgb(0,80,48, 0.75)' },
  MIAOH: { primary: 'rgb(200, 16, 46, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  MICH: { primary: 'rgb(0, 39, 76, 0.75)', secondary: 'rgb(255, 203, 5, 0.75)' },
  MICHST: { primary: 'rgb(24, 69, 59, 0.75)', secondary: 'rgb(255, 255, 255, 0.75)' },
  MINN: { primary: 'rgb(122,0,25, 0.75)', secondary: 'rgb(255,204,51, 0.75)' },
  MISS: { primary: 'rgb(204, 9, 47, 0.75)', secondary: 'rgb(22, 43, 72, 0.75)' },
  MISSST: { primary: 'rgb(102, 0, 0, 0.75)', secondary: 'rgb(204, 204, 204, 0.75)' },
  MIZZOU: { primary: 'rgb(241, 184, 45, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  MONST: { primary: 'rgb(0, 32, 91, 0.75)', secondary: 'rgb(185, 151, 91, 0.75)' },
  MRSHL: { primary: 'rgb(0, 177, 64, 0.75)', secondary: 'rgb(162, 170, 173, 0.75)' },
  MTSU: { primary: 'rgb(0, 102, 204, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  MURYST:  { primary: 'rgb(255, 69, 0, 0.75)', secondary: 'rgb(0, 33, 68, 0.75)' },
  MNTNA: { primary: 'rgb(134, 31, 65, 0.75)', secondary: 'rgb(137, 141, 141, 0.75)' },
  NAL: { primary: 'rgb(70, 22, 107, 0.75)', secondary: 'rgb(219, 159, 17, 0.75)' },
  NAU:  { primary: 'rgb(0 ,51, 102, 0.75)', secondary: 'rgb(255, 210, 0, 0.75)' },
  NAVY: { primary: 'rgb(0, 32, 91, 0.75)', secondary: 'rgb(197, 183, 131, 0.75)' },
  NCAT: { primary: 'rgb(243, 178, 55, 0.75)', secondary: 'rgb(0, 61, 109, 0.75)' },
  NCOLO:  { primary: 'rgb(1, 45, 91, 0.75)', secondary: 'rgb(254, 189, 54, 0.75)' },
  NCST: { primary: 'rgb(204, 0, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  NDST: { primary: 'rgb(0, 102, 51, 0.75)', secondary: 'rgb(255, 204, 0, 0.75)' },
  ND: { primary: 'rgb(12, 35, 64, 0.75)', secondary: 'rgb(201, 151, 0, 0.75)' },
  NDK: { primary: 'rgb(0, 154, 68, 0.75)', secondary: 'rgb(171, 174, 174, 0.75)' },
  NEB: { primary: 'rgb(227, 25, 55, 0.75)', secondary: 'rgb(0,0,0, 0.75)' },
  NEVADA: { primary: 'rgb(0, 46, 98, 0.75)', secondary: 'rgb(128, 127, 132, 0.75)' },
  NH: { primary: 'rgb(4, 30, 66, 0.75)', secondary: 'rgb(187, 188, 188, 0.75)' },
  NICHST: { primary: 'rgb(174, 19, 42, 0.75)', secondary: 'rgb(114, 128, 138, 0.75)' },
  
  NILL: { primary: 'rgb(0, 0, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },

  NJTECH: { primary: 'rgb(204, 0, 0, 0.75)', secondary: 'rgb(0, 0, 51, 0.75)' },
  NMEX: { primary: 'rgb(186, 12, 47, 0.75)', secondary: 'rgb(167, 168, 170, 0.75)' },
  NMEXST: { primary: 'rgb(134, 31, 65, 0.75)', secondary: 'rgb(151, 153, 155, 0.75)' },
  NOVA: { primary: 'rgb(0, 32, 91, 0.75)', secondary: 'rgb(19, 181, 234, 0.75)' },
  NRFST: { primary: 'rgb(0, 122, 83, 0.75)', secondary: 'rgb(2443, 208, 62, 0.75)' },
  NTEXAS: { primary: 'rgb(5, 144, 51, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  NWEST: { primary: 'rgb(78, 42, 132, 0.75)', secondary: 'rgb(255, 255, 255, 0.75)' },
  NWST: { primary: 'rgb(79, 45, 139, 0.75)', secondary: 'rgb(255, 100, 24, 0.75)' },
  ODU: { primary: 'rgb(0,48,87, 0.75)', secondary: 'rgb(124,135,142, 0.75)' },
  OHIO: { primary: 'rgb(0, 104, 72, 0.75)', secondary: 'rgb(205, 160, 119, 0.75)' },
  OHIOST: { primary: 'rgb(187, 0, 0, 0.75)', secondary: 'rgb(102, 102, 102, 0.75)' },
  OKLA: { primary: 'rgb(132, 23, 25, 0.75)', secondary: 'rgb(253, 249, 216, 0.75)' },
  OKLAST: { primary: 'rgb(0, 65, 90, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  OREG: { primary: 'rgb(18, 71, 52, 0.75)', secondary: 'rgb(254, 225, 35, 0.75)' },
  OREGST: { primary: 'rgb(220, 68, 5, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  
  PITT: { primary: 'rgb(0, 53, 148, 0.75)', secondary: 'rgb(255, 184, 28, 0.75)' },
  PORTST: { primary: 'rgb(21, 71, 52, 0.75)', secondary: 'rgb(214, 214, 209, 0.75)' },
  PROV: { primary: 'rgb(138, 141, 143, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  PSU: { primary: 'rgb(4, 30, 66, 0.75)', secondary: 'rgb(255, 255, 255, 0.75)' },
  PURDUE: { primary: 'rgb(206, 184, 136, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  RI: { primary: 'rgb(104, 171, 232, 0.75)', secondary: 'rgb(0, 33, 71, 0.75)' },
  RICE: { primary: 'rgb(0, 32, 91, 0.75)', secondary: 'rgb(193, 198, 200, 0.75)' },
  RICH: { primary: 'rgb(0, 0, 102, 0.75)', secondary: 'rgb(153, 0, 0, 0.75)' },

  ROB: { primary: 'rgb(0, 0, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },  

  RUT: { primary: 'rgb(201, 42, 57, 0.75)', secondary: 'rgb(125, 41, 53, 0.75)' },
  SACST: { primary: 'rgb(0, 78, 56, 0.75)', secondary: 'rgb(196, 182, 129, 0.75)' },
  SALA: { primary: 'rgb(0, 32, 91, 0.75)', secondary: 'rgb(191, 13, 62, 0.75)' },
  SAMST: { primary: 'rgb(254, 81, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  SC: { primary: 'rgb(115, 0, 10, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  
  SDGST: { primary: 'rgb(0, 0, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  
  SDST: { primary: 'rgb(0, 51, 160, 0.75)', secondary: 'rgb(255, 209, 0, 0.75)' },
  SELOU: { primary: 'rgb(0, 99, 65, 0.75)', secondary: 'rgb(234, 170, 0, 0.75)' },
  SEMOST: { primary: 'rgb(200, 16, 46, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  SETON: { primary: 'rgb(0, 51, 160, 0.75)', secondary: 'rgb(158, 162, 163, 0.75)' },
  SFLA: { primary: 'rgb(0, 103, 71, 0.75)', secondary: 'rgb(207, 196, 147, 0.75)' },
  SIL: { primary: 'rgb(114, 0, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  SJST: { primary: 'rgb(0, 85, 162, 0.75)', secondary: 'rgb(229, 168, 35, 0.75)' },
  SMU: { primary: 'rgb(53, 76, 161, 0.75)', secondary: 'rgb(204, 0, 0, 0.75)' },
  STBON: { primary: 'rgb(84, 38, 26, 0.75)', secondary: 'rgb(255, 199, 38, 0.75)' },
  STETSON: { primary: 'rgb(0, 82, 62, 0.75)', secondary: 'rgb(59, 135, 41, 0.75)' },
  STJOES: { primary: 'rgb(161, 31, 53, 0.75)', secondary: 'rgb(83, 86, 91, 0.75)' },
  STJOHN: { primary: 'rgb(186, 12, 47, 0.75)', secondary: 'rgb(4, 28, 44, 0.75)' },
  STLOU: { primary: 'rgb(0, 61, 165, 0.75)', secondary: 'rgb(200, 201, 199, 0.75)' },
  STONYBRK: { primary: 'rgb(153, 0, 0, 0.75)', secondary: 'rgb(22, 36, 62, 0.75)' },
  STNFRD: { primary: 'rgb(140, 21, 21, 0.75)', secondary: 'rgb(77, 79, 83, 0.75)' },
  SUTAH: { primary: 'rgb(196, 20, 37, 0.75)', secondary: 'rgb(31, 34, 35, 0.75)' },
  
  TAMC: { primary: 'rgb(0, 0, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  
  TCU: { primary: 'rgb(77, 25, 121, 0.75)', secondary: 'rgb(164, 189, 173, 0.75)' },
  TEMPLE: { primary: 'rgb(157, 34, 53, 0.75)', secondary: 'rgb(255, 205, 0, 0.75)' },
  TENN: { primary: 'rgb(255, 130, 0, 0.75)', secondary: 'rgb(88, 89, 91, 0.75)' },
  TEXAS: { primary: 'rgb(191, 87, 0, 0.75)', secondary: 'rgb(51, 63, 72, 0.75)' },
  
  TNMART: { primary: 'rgb(247, 151, 40, 0.75)', secondary: 'rgb(0, 42, 91, 0.75)' },
  TNTECH: { primary: 'rgb(79, 41, 132, 0.75)', secondary: 'rgb(255, 221, 0, 0.75)' },

  TOLEDO: { primary: 'rgb(0, 0, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  TROY: { primary: 'rgb(0, 0, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  
  TULANE: { primary: 'rgb(0, 103, 71, 0.75)', secondary: 'rgb(67, 176, 42, 0.75)' },
  TULSA: { primary: 'rgb(0, 45, 114, 0.75)', secondary: 'rgb(200, 16, 46, 0.75)' },
  
  TRLST: { primary: 'rgb(0, 0, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  TWST: { primary: 'rgb(0, 0, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },

  TXAM: { primary: 'rgb(80, 0, 0, 0.75)', secondary: 'rgb(255, 255, 255, 0.75)' },
  TXSA: { primary: 'rgb(241, 90, 34, 0.75)', secondary: 'rgb(12, 35, 64, 0.75)' },
  TXSTSM: { primary: 'rgb(80, 18, 20, 0.75)', secondary: 'rgb(141, 115, 74, 0.75)' },
  TXTECH: { primary: 'rgb(204, 0, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  UAB: { primary: 'rgb(0, 99, 65, 0.75)', secondary: 'rgb(204, 138, 0, 0.75)' },
  UCDAV: { primary: 'rgb(0, 40, 85, 0.75)', secondary: 'rgb(179, 163, 105, 0.75)' },
  UCF: { primary: 'rgb(255, 202, 6, 0.75)', secondary: 'rgb(186, 155, 55, 0.75)' },
  UCIRV: { primary: 'rgb(12, 35, 64, 0.75)', secondary: 'rgb(255, 199, 44, 0.75)' },
  UCLA: { primary: 'rgb(45, 104, 96, 0.75)', secondary: 'rgb(242, 169, 0, 0.75)' },
  UCONN: { primary: 'rgb(0,14,47, 0.75)', secondary: 'rgb(228,0,43, 0.75)' },
  UCRIV: { primary: 'rgb(45, 108, 192, 0.75)', secondary: 'rgb(0, 48, 102, 0.75)' },
  UCSB: { primary: 'rgb(0, 54, 96, 0.75)', secondary: 'rgb(254, 188, 17, 0.75)' },
  UGA: { primary: 'rgb(186, 12, 47, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  UK: { primary: 'rgb(0, 51, 160, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  UL: { primary: 'rgb(206, 24, 30, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  UMASS: { primary: 'rgb(151, 27, 47, 0.75)', secondary: 'rgb(87, 41, 50, 0.75)' },
  UMBC: { primary: 'rgb(177, 137, 6, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  UNC: { primary: 'rgb(123, 175, 212, 0.75)', secondary: 'rgb(255, 255, 255, 0.75)' },
  UNF: { primary: 'rgb(3, 27, 73, 0.75)', secondary: 'rgb(186,. 188, 190, 0.75)' },
  UNLV: { primary: 'rgb(177,2,2, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  USC: { primary: 'rgb(153, 27, 30, 0.75)', secondary: 'rgb(255, 199, 44, 0.75)' },
  USCUP: { primary: 'rgb(0 ,112, 60, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  USM: { primary: 'rgb(0, 0, 0, 0.75)', secondary: 'rgb(255, 171, 0, 0.75)' },
  UTAH: { primary: 'rgb(204, 0, 0, 0.75)', secondary: 'rgb(128, 128, 128, 0.75)' },
  UTAHST: { primary: 'rgb(0, 38, 58, 0.75)', secondary: 'rgb(138, 141, 143, 0.75)' },
  UTEP: { primary: 'rgb(255, 130, 0, 0.75)', secondary: 'rgb(4, 30, 66, 0.75)' },
  UVA: { primary: 'rgb(35, 45, 75, 0.75)', secondary: 'rgb(248, 76, 30, 0.75)' },
  UVM: { primary: 'rgb(21, 71, 52, 0.75)', secondary: 'rgb(135, 91, 41, 0.75)' },
  VANDY: { primary: 'rgb(134,109,75, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  VATECH: { primary: 'rgb(99, 0, 49, 0.75)', secondary: 'rgb(207, 69, 32, 0.75)' },
  VCU: { primary: 'rgb(255, 179, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  WAKE: { primary: 'rgb(158, 126, 56, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  WASH: { primary: 'rgb(51, 0, 111, 0.75)', secondary: 'rgb(232, 211, 162, 0.75)' },
  WASHST: { primary: 'rgb(152, 30, 50, 0.75)', secondary: 'rgb(94, 106, 113, 0.75)' },
  WCAR: { primary: 'rgb(89, 44, 136, 0.75)', secondary: 'rgb(193, 168, 117, 0.75)' },
  WEBER: { primary: 'rgb(75, 38, 130, 0.75)', secondary: 'rgb(161, 161, 164, 0.75)' },
  WICHST: { primary: 'rgb(255, 205, 0, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  WIL: { primary: 'rgb(80, 7, 120, 0.75)', secondary: 'rgb(255, 205, 0, 0.75)' },
  WISC: { primary: 'rgb(197, 5, 12, 0.75)', secondary: 'rgb(255, 255, 255, 0.75)' },
  WMICH: { primary: 'rgb(108, 64, 35, 0.75)', secondary: 'rgb(181, 162, 104, 0.75)' },
  WKY: { primary: 'rgb(198, 12, 48, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  WVU: { primary: 'rgb(0, 40, 85, 0.75)', secondary: 'rgb(234, 170, 0, 0.75)' },
  WYO: { primary: 'rgb(255, 197, 37, 0.75)', secondary: 'rgb(73, 47, 36, 0.75)' },
  XAVIER: { primary: 'rgb(12, 35, 64, 0.75)', secondary: 'rgb(158, 162, 163, 0.75)' },
  YST: { primary: 'rgb(200, 16, 46, 0.75)', secondary: 'rgb(243, 213, 78, 0.75)' },
};

/**
 * Skipped Conferences:
 * - Big South:            https://teamcolorcodes.com/ncaa-color-codes/big-south-conference-colors/
 * - CAA:                  https://teamcolorcodes.com/ncaa-color-codes/colonial-athletic-association-colors/
 * - Horizon League:       https://teamcolorcodes.com/ncaa-color-codes/horizon-league/
 * - IVY League:           https://teamcolorcodes.com/ncaa-color-codes/ivy-league/
 * - MAAC:                 https://teamcolorcodes.com/ncaa-color-codes/maac/
 * - MEAC:                 https://teamcolorcodes.com/ncaa-color-codes/mid-eastern-athletic-conference-colors/
 * - Missouri Valley:      https://teamcolorcodes.com/ncaa-color-codes/missouri-valley/
 * - Mountain West:        https://teamcolorcodes.com/ncaa-color-codes/mountain-west/
 * - Northeast Conference: https://teamcolorcodes.com/ncaa-color-codes/northeast-conference-nec-colors/
 * - Ohio Valley:          https://teamcolorcodes.com/ncaa-color-codes/ohio-valley-conference-colors/
 * - Patriot League:       https://teamcolorcodes.com/ncaa-color-codes/patriot-league-colors/
 * - Southern Conference:  https://teamcolorcodes.com/ncaa-color-codes/southern-conference-colors/
 * - Southland Conference: https://teamcolorcodes.com/ncaa-color-codes/southland-conference-colors/
 * - Summit League:        https://teamcolorcodes.com/ncaa-color-codes/summit-league/
 * - Sun Belt Conference:  https://teamcolorcodes.com/ncaa-color-codes/sun-belt/
 * - SWAC:                 https://teamcolorcodes.com/ncaa-color-codes/southwestern-athletic-conference-colors/
 * - WAC:                  https://teamcolorcodes.com/ncaa-color-codes/western-athletic-conference/
 * - WCC:                  https://teamcolorcodes.com/ncaa-color-codes/west-coast-conference/
 * 
 */


/**
 * Tomorrow:
 * - finish colors for ncaa schools that are already entered
 * - add new ncaa games for thursday and friday only
 * - finish remaining schools and colors
 * - add saturday, sunday, and monday games
 */