import { Dispatch, SetStateAction } from "react";
import { useSelector } from 'react-redux';
import { BattingStats, LeagueBattingStats, BattingSplit, MLBTimePeriod } from 'reducers/types/mlbTypes';
import { getZScore, truncateMetric } from 'components/helpers';

import { HighOrLow, MLB_METRIC_MAP, MLB_TEAM_CODE } from 'config/mlb';

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Typography from '@mui/material/Typography';


import {
  selectTeamBattingData,
  selectLeagueBattingData,
} from 'reducers/mlbDataReducer';

import MetricRow from './MetricRow';

export interface BattingMetricsProps {
  awayTeam: MLB_TEAM_CODE;
  homeTeam: MLB_TEAM_CODE;
  timePeriod: MLBTimePeriod;
  battingSplit: BattingSplit;
  hideUncorrelatedData: boolean;
};

export const BattingMetrics = ({
  homeTeam,
  awayTeam,
  timePeriod,
  battingSplit,
  hideUncorrelatedData,
}: BattingMetricsProps) => {
  let leagueBattingData = useSelector(selectLeagueBattingData);
  let awayTeamCurrentBattingOverallData = useSelector(selectTeamBattingData(awayTeam, timePeriod, battingSplit));
  let homeTeamCurrentBattingOverallData = useSelector(selectTeamBattingData(homeTeam, timePeriod, battingSplit));

  let awayBattingZScoreTotal = 0;
  let homeBattingZScoreTotal = 0;


  let metricRowData: any = [];
  let onlyCorrelatedMetricRows: any = [];
  for (const teamMetricKey in awayTeamCurrentBattingOverallData) {
    const leagueMetricName = teamMetricKey as keyof LeagueBattingStats;
    const teamMetricName = teamMetricKey as keyof BattingStats;

    if (
      teamMetricName === 'runsScored' || leagueMetricName === 'runsScored'
    ) {
      continue
    }

    let highOrLowMetric = MLB_METRIC_MAP[teamMetricName].highOrLow as HighOrLow;

    const awayZScore = getZScore(
      awayTeamCurrentBattingOverallData[teamMetricName],
      leagueBattingData[leagueMetricName].leagueAverage,
      leagueBattingData[leagueMetricName].stdDeviation,
      highOrLowMetric,
    );
    
    const homeZScore = getZScore(
      homeTeamCurrentBattingOverallData[teamMetricName],
      leagueBattingData[leagueMetricName].leagueAverage,
      leagueBattingData[leagueMetricName].stdDeviation,
      highOrLowMetric,
    );

    const correlationToWins = leagueBattingData[leagueMetricName].correlationToWins;

    let awayZScoreForTotals = 0;
    let homeZScoreForTotals = 0;

    if (correlationToWins > 0) {
      awayZScoreForTotals += awayZScore > 0 ? Math.abs(awayZScore) : -Math.abs(awayZScore);
      homeZScoreForTotals += homeZScore > 0 ? Math.abs(homeZScore) : -Math.abs(homeZScore);

      if (correlationToWins > 0.5) {
        awayBattingZScoreTotal += awayZScoreForTotals;
        homeBattingZScoreTotal += homeZScoreForTotals;

        onlyCorrelatedMetricRows.push({
          statName: teamMetricKey,
          awayStat: awayTeamCurrentBattingOverallData[teamMetricName],
          awayZScore: awayZScoreForTotals,
          homeZScore: homeZScoreForTotals,
          homeStat: homeTeamCurrentBattingOverallData[teamMetricName],
          correlationToWins,
          leagueAverage: leagueBattingData[leagueMetricName].leagueAverage,
        })
      };
    };

    // strong negative correlation should not negatively impact the total z-score, aka:
    // A pitcher with low era is a good thing not a negative thing so use the absolute value
    if (correlationToWins < 0) {
      awayZScoreForTotals += awayZScore < 0 ? Math.abs(awayZScore) : -Math.abs(awayZScore);
      homeZScoreForTotals += homeZScore < 0 ? Math.abs(homeZScore) : -Math.abs(homeZScore);

      if (correlationToWins < -0.5) {
        awayBattingZScoreTotal += awayZScoreForTotals;
        homeBattingZScoreTotal += homeZScoreForTotals;

        onlyCorrelatedMetricRows.push({
          statName: teamMetricKey,
          awayStat: awayTeamCurrentBattingOverallData[teamMetricName],
          awayZScore: awayZScoreForTotals,
          homeZScore: homeZScoreForTotals,
          homeStat: homeTeamCurrentBattingOverallData[teamMetricName],
          correlationToWins,
          leagueAverage: leagueBattingData[leagueMetricName].leagueAverage,
        })
      };
    };

    metricRowData.push({
      statName: teamMetricKey,
      awayStat: awayTeamCurrentBattingOverallData[teamMetricName],
      awayZScore: awayZScoreForTotals,
      homeZScore: homeZScoreForTotals,
      homeStat: homeTeamCurrentBattingOverallData[teamMetricName],
      correlationToWins,
      leagueAverage: leagueBattingData[leagueMetricName].leagueAverage,
    })
  }

  let metricDataSet = hideUncorrelatedData ? onlyCorrelatedMetricRows : metricRowData;

  let metricRows = metricDataSet
    .sort((a: any, b: any) => Math.abs(b.correlationToWins) - Math.abs(a.correlationToWins))
    .map((data: any) => {
    const statName = data.statName as keyof BattingStats;
    return (
      <MetricRow
        key={statName}
        awayTeamStat={data.awayStat}
        awayZScore={data.awayZScore}
        homeTeamStat={data.homeStat}
        homeZScore={data.homeZScore}
        statName={statName}
        statCorrelationToWins={data.correlationToWins}
        leagueAverageForStat={data.leagueAverage}
      />
    )
  })

  return (
    <div style={{ width: '100%' }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} textAlign="center">
        <Grid xs={4}>
          {truncateMetric(awayBattingZScoreTotal)}
        </Grid>

        <Grid xs={4}>
          <Typography variant="h6" gutterBottom noWrap>Batting Score</Typography>
        </Grid>
          
        <Grid xs={4}>
          {truncateMetric(homeBattingZScoreTotal)}
        </Grid>

        <Grid xs={12}>
          {metricRows}
        </Grid>
      </Grid>
      
    </div>
  )
};

export default BattingMetrics;


/**
 * would be good to add things like (last 5 games or last 10 game to catch streaky teams)
 * 
 * consider adding in if last 5 games were wins/losses and against teams with winning/losing records
 */