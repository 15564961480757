import { configureStore } from '@reduxjs/toolkit';

import mlbDataReducer from 'reducers/mlbDataReducer';
import competitionsReducer from 'reducers/competitionsReducer';

import { mlbGameInfoApi } from 'services/mlbStatsApiService';

export const store = configureStore({
  reducer: {
    competitions: competitionsReducer,
    mlbData: mlbDataReducer,
    [mlbGameInfoApi.reducerPath]: mlbGameInfoApi.reducer,

  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({}).concat(mlbGameInfoApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
