export enum MLB_CONFERENCE {
  AL = 'American League',
  NL = 'National League',
}

export enum MLB_DIVISION {
  WEST = 'West',
  CENTRAL = 'Central',
  EAST = 'East',
}

export enum MLB_TEAM_CODE {
  ARI = 'ARI',
  ATL = 'ATL',
  BAL = 'BAL',
  BOS = 'BOS',
  CHC = 'CHC',
  CHW = 'CHW',
  CIN = 'CIN',
  CLE = 'CLE',
  COL = 'COL',
  DET = 'DET',
  HOU = 'HOU',
  KCR = 'KCR',
  LAA = 'LAA',
  LAD = 'LAD',
  MIA = 'MIA',
  MIL = 'MIL',
  MIN = 'MIN',
  NYY = 'NYY',
  NYM = 'NYM',
  OAK = 'OAK',
  PHI = 'PHI',
  PIT = 'PIT',
  SDP = 'SDP',
  SFG = 'SFG',
  SEA = 'SEA',
  STL = 'STL',
  TBR = 'TBR',
  TEX = 'TEX',
  TOR = 'TOR',
  WSN = 'WSN',
};

export enum MLB_TEAM_NAME_LONG {
  ARI = 'Arizona Diamondbacks',
  ATL = 'Atlanta Braves',
  BAL = 'Baltimore Orioles',
  BOS = 'Boston Red Sox',
  CHC = 'Chicago Cubs',
  CHW = 'Chicago White Sox',
  CIN = 'Cincinnatti Reds',
  CLE = 'Cleveland Guardians',
  COL = 'Colorado Rockies',
  DET = 'Detroit Tigers',
  HOU = 'Houston Astros',
  KCR = 'Kansas City Royals',
  LAA = 'Los Angeles Angels',
  LAD = 'Los Angeles Dodgers',
  MIA = 'Miami Marlins',
  MIL = 'Milwaukee Brewers',
  MIN = 'Minnesota Twins',
  NYY = 'New York Yankees',
  NYM = 'New York Mets',
  OAK = 'Oakland Athletics',
  PHI = 'Philadelphia Phillies',
  PIT = 'Pittsburgh Pirates',
  SDP = 'San Diego Padres',
  SFG = 'San Francisco Giants',
  SEA = 'Seattle Mariners',
  STL = 'St. Louis Cardinals',
  TBR = 'Tampa Bay Rays',
  TEX = 'Texas Rangers',
  TOR = 'Toronto Blue Jays',
  WSN = 'Washington Nationals',
};

export const MLB_SL_TEAM_CODE: { [key: string]: MLB_TEAM_CODE } = {
  ARI: MLB_TEAM_CODE.ARI,
  ATL: MLB_TEAM_CODE.ATL,
  BAL: MLB_TEAM_CODE.BAL,
  BOS: MLB_TEAM_CODE.BOS,
  CHC: MLB_TEAM_CODE.CHC,
  CHW: MLB_TEAM_CODE.CHW,
  CIN: MLB_TEAM_CODE.CIN,
  CLE: MLB_TEAM_CODE.CLE,
  COL: MLB_TEAM_CODE.COL,
  DET: MLB_TEAM_CODE.DET,
  HOU: MLB_TEAM_CODE.HOU,
  KC: MLB_TEAM_CODE.KCR,
  LAA: MLB_TEAM_CODE.LAA,
  LAD: MLB_TEAM_CODE.LAD,
  MIA: MLB_TEAM_CODE.MIA,
  MIL: MLB_TEAM_CODE.MIL,
  MIN: MLB_TEAM_CODE.MIN,
  NYY: MLB_TEAM_CODE.NYY,
  NYM: MLB_TEAM_CODE.NYM,
  OAK: MLB_TEAM_CODE.OAK,
  PHI: MLB_TEAM_CODE.PHI,
  PIT: MLB_TEAM_CODE.PIT,
  SD: MLB_TEAM_CODE.SDP,
  SF: MLB_TEAM_CODE.SFG,
  SEA: MLB_TEAM_CODE.SEA,
  STL: MLB_TEAM_CODE.STL,
  TB: MLB_TEAM_CODE.TBR,
  TEX: MLB_TEAM_CODE.TEX,
  TOR: MLB_TEAM_CODE.TOR,
  WAS:  MLB_TEAM_CODE.WSN,
};

export enum MLB_TEAM_CONFERENCE {
  ARI = MLB_CONFERENCE.NL,
  ATL = MLB_CONFERENCE.NL,
  BAL = MLB_CONFERENCE.AL,
  BOS = MLB_CONFERENCE.AL,
  CHC = MLB_CONFERENCE.NL,
  CHW = MLB_CONFERENCE.AL,
  CIN = MLB_CONFERENCE.NL,
  CLE = MLB_CONFERENCE.AL,
  COL = MLB_CONFERENCE.NL,
  DET = MLB_CONFERENCE.AL,
  HOU = MLB_CONFERENCE.AL,
  KCR = MLB_CONFERENCE.AL,
  LAA = MLB_CONFERENCE.AL,
  LAD = MLB_CONFERENCE.NL,
  MIA = MLB_CONFERENCE.NL,
  MIL = MLB_CONFERENCE.NL,
  MIN = MLB_CONFERENCE.AL,
  NYY = MLB_CONFERENCE.AL,
  NYM = MLB_CONFERENCE.NL,
  OAK = MLB_CONFERENCE.AL,
  PHI = MLB_CONFERENCE.NL,
  PIT = MLB_CONFERENCE.NL,
  SDP = MLB_CONFERENCE.NL,
  SFG = MLB_CONFERENCE.NL,
  SEA = MLB_CONFERENCE.AL,
  STL = MLB_CONFERENCE.NL,
  TBR = MLB_CONFERENCE.AL,
  TEX = MLB_CONFERENCE.AL,
  TOR = MLB_CONFERENCE.AL,
  WSN = MLB_CONFERENCE.NL,
};

export enum MLB_TEAM_DIVISION {
  ARI = MLB_DIVISION.WEST,
  ATL = MLB_DIVISION.EAST,
  BAL = MLB_DIVISION.EAST,
  BOS = MLB_DIVISION.EAST,
  CHC = MLB_DIVISION.CENTRAL,
  CHW = MLB_DIVISION.CENTRAL,
  CIN = MLB_DIVISION.CENTRAL,
  CLE = MLB_DIVISION.CENTRAL,
  COL = MLB_DIVISION.WEST,
  DET = MLB_DIVISION.CENTRAL,
  HOU = MLB_DIVISION.WEST,
  KCR = MLB_DIVISION.CENTRAL,
  LAA = MLB_DIVISION.WEST,
  LAD = MLB_DIVISION.WEST,
  MIA = MLB_DIVISION.EAST,
  MIL = MLB_DIVISION.CENTRAL,
  MIN = MLB_DIVISION.CENTRAL,
  NYY = MLB_DIVISION.EAST,
  NYM = MLB_DIVISION.EAST,
  OAK = MLB_DIVISION.WEST,
  PHI = MLB_DIVISION.EAST,
  PIT = MLB_DIVISION.CENTRAL,
  SDP = MLB_DIVISION.WEST,
  SFG = MLB_DIVISION.WEST,
  SEA = MLB_DIVISION.WEST,
  STL = MLB_DIVISION.CENTRAL,
  TBR = MLB_DIVISION.EAST,
  TEX = MLB_DIVISION.WEST,
  TOR = MLB_DIVISION.EAST,
  WSN = MLB_DIVISION.EAST,
};

export const MLB_COLOR_CODES: {  [key in MLB_TEAM_CODE]: { primary: string, secondary: string } } = {
  ARI: {  primary: 'rgb(167, 25, 48, 0.75)', secondary: 'rgb(227, 212, 173, 0.75)' },
  ATL: {  primary: 'rgb(206, 17, 65, 0.75)', secondary: 'rgb(19, 39, 79, 0.75)' },
  BAL: {  primary: 'rgb(223, 70, 1, 0.75)', secondary: 'rgb(39, 37, 31, 0.75)' },
  BOS: {  primary: 'rgb(189, 48, 57, 0.75)', secondary: 'rgb(12, 35, 64, 0.75)' },
  CHC: {  primary: 'rgb(14, 51, 134, 0.75)', secondary: 'rgb(204, 52, 51, 0.75)' },
  CHW: {  primary: 'rgb(39,37,31, 0.75)', secondary: 'rgb(196,206,212, 0.75)' },
  CIN: {  primary: 'rgb(198,1,31, 0.75)', secondary: 'rgb(0, 0, 0, 0.75)' },
  CLE: {  primary: 'rgb(0, 56, 93, 0.75)', secondary: 'rgb(229, 0, 34, 0.75)' },
  COL: {  primary: 'rgb(51, 51, 102, 0.75)', secondary: 'rgb(196, 206, 212, 0.75)' },
  DET: {  primary: 'rgb(12, 35, 64, 0.75)', secondary: 'rgb(250, 70, 22, 0.75)' },
  HOU: {  primary: 'rgb(0, 45, 98, 0.75)', secondary: 'rgb(235, 110, 31, 0.75)' },
  KCR: {  primary: 'rgb(0, 70, 135, 0.75)', secondary: 'rgb(189, 155, 96, 0.75)' },
  LAA: {  primary: 'rgb(186, 0, 33, 0.75)', secondary: 'rgb(0, 50, 99, 0.75)' },
  LAD: {  primary: 'rgb(0, 90, 156, 0.75)', secondary: 'rgb(91, 192, 191, 0.75)' },
  MIA: {  primary: 'rgb(0, 163, 224, 0.75)', secondary: 'rgb(65, 116, 141, 0.75)' },
  MIL: {  primary: 'rgb(255, 197, 47, 0.75)', secondary: 'rgb(18, 40, 75, 0.75)' },
  MIN: {  primary: 'rgb(0, 43, 92, 0.75)', secondary: 'rgb(211, 17, 69, 0.75)' },
  NYY: {  primary: 'rgb(0, 48, 135, 0.75)', secondary: 'rgb(196, 206, 211, 0.75)' },
  NYM: {  primary: 'rgb(12, 35, 64, 0.75)', secondary: 'rgb(252, 89, 16, 0.75)' },
  OAK: {  primary: 'rgb(0, 56, 49, 0.75)', secondary: 'rgb(239, 178, 30, 0.75)' },
  PHI: {  primary: 'rgb(232, 24, 40, 0.75)', secondary: 'rgb(107, 172, 228, 0.75)' },
  PIT: {  primary: 'rgb(253,184,39, 0.75)', secondary: 'rgb(39, 37, 31, 0.75)' },
  SDP: {  primary: 'rgb(47, 36, 29, 0.75)', secondary: 'rgb(255, 196, 37, 0.75)' },
  SFG: {  primary: 'rgb(253, 90, 30, 0.75)', secondary: 'rgb(39, 37, 31, 0.75)' },
  SEA: {  primary: 'rgb(12, 44, 86, 0.75)', secondary: 'rgb(0, 92, 92, 0.75)' },
  STL: {  primary: 'rgb(196, 30, 58, 0.75)', secondary: 'rgb(129, 180, 235, 0.75)' },
  TBR: {  primary: 'rgb(9, 44, 92, 0.75)', secondary: 'rgb(143, 188, 230, 0.75)' },
  TEX: {  primary: 'rgb(0,50,120, 0.75)', secondary: 'rgb(192, 17, 31, 0.75)' },
  TOR: {  primary: 'rgb(19, 74, 142, 0.75)', secondary: 'rgb(29, 45, 92, 0.75)' },
  WSN: {  primary: 'rgb(171,0,3, 0.75)', secondary: 'rgb(20,34,90, 0.75)' },
};

export type PitchingGroup = 'all' | 'starting' | 'relief';
export type Pitches = 'fastball' | 'slider' | 'cutter' | 'curveball' | 'changeup'// | 'knuckleball';

export enum PitchType {
  FB = 'Fastball',
  SL = 'Slider',
  CU = 'Cutter',
  CB = 'Curveball',
  CH = 'Changeup',
  // KC = 'Knuckleball',
};

export type HighOrLow = 'higher' | 'lower';

export interface MLBMetric {
  abbreviation: string;
  longName: string;
  description: string;
  truncationDigits: number;
  highOrLow: HighOrLow;
};

export const MLB_METRIC_MAP: { [key: string]: MLBMetric } = {
  atBats: {
    abbreviation: 'AB',
    longName: 'At Bats',
    description: 'Number of trips to the plate in which the batter does not walk, get hit by a pitch, sacrifice (fly or bunt), or reach on interference.',
    truncationDigits: 0,
    highOrLow: 'higher'// really more N/A
  },
  plateAttempts: {
    abbreviation: 'PA',
    longName: 'Plate Appreances',
    description: 'Number of times the player has come to the plate.',
    truncationDigits: 0,
    highOrLow: 'higher' as HighOrLow, // really more N/A
  },
  hits: {
    abbreviation: 'H',
    longName: 'Hits',
    description: 'Number of hits.',
    truncationDigits: 0,
    highOrLow: 'higher' as HighOrLow,
  },
  homeruns: {
    abbreviation: 'HR',
    longName: 'Home Runs',
    description: 'Number of home runs.',
    truncationDigits: 0,
    highOrLow: 'higher' as HighOrLow,
  },
  runsScored: {
    abbreviation: 'R',
    longName: 'Runs Scored',
    description: 'Number of runs scored.',
    truncationDigits: 0,
    highOrLow: 'higher' as HighOrLow,
  },
  rbis: {
    abbreviation: 'RBI',
    longName: 'Runs Batted In',
    description: "Number of times a run scores as a result of a batter's plate appearance, not counting situations in which an error caused the run to score or the batter hit into a double play",
    truncationDigits: 0,
    highOrLow: 'higher' as HighOrLow,
  },
  // batter walks
  walks: {
    abbreviation: 'BB',
    longName: 'Walks',
    description: 'Total number of walks (includes intentional walks).',
    truncationDigits: 0,
    highOrLow: 'higher' as HighOrLow,
  },
  // batter intentional walks
  intentionalWalks: {
    abbreviation: 'IBB',
    longName: 'Intentional Walks',
    description: 'Number of times the batter was intentionally walked.',
    truncationDigits: 0,
    highOrLow: 'higher' as HighOrLow,
  },
  strikeouts: {
    abbreviation: 'SO',
    longName: 'Strikeouts',
    description: 'Number of strikeouts.',
    truncationDigits: 0,
    highOrLow: 'lower' as HighOrLow,
  },
  groundIntoDoublePlay: {
    abbreviation: 'GDP',
    longName: 'Grounded into Double Play',
    description: 'Number of times batter hit into a double play.',
    truncationDigits: 0,
    highOrLow: 'lower' as HighOrLow,
  },
  stolenBases: {
    abbreviation: 'SB',
    longName: 'Stolen Bases',
    description: 'Number of stolen bases.',
    truncationDigits: 0,
    highOrLow: 'higher' as HighOrLow,
  },
  caughtStealing: {
    abbreviation: 'CS',
    longName: 'Caught Stealing',
    description: 'Number of times caught stealing.',
    truncationDigits: 0,
    highOrLow: 'lower' as HighOrLow,
  },
  battingAverage: {
    abbreviation: 'AVG',
    longName: 'Batting Average',
    description: 'Rate of hits per at bat, calculated as H/AB.',
    truncationDigits: 3,
    highOrLow: 'higher' as HighOrLow,
  },
  onBasePercentage: {
    abbreviation: 'OBP',
    longName: 'On Base Percentage',
    description: 'Rate at which the batter reaches base, calculated as (H+BB+HBP)/(AB+BB+HBP+SF).',
    truncationDigits: 3,
    highOrLow: 'higher' as HighOrLow,
  },
  sluggingPercentage: {
    abbreviation: 'SLG',
    longName: 'Slugging Percentage',
    description: 'Average number of total bases per at bat, calculated as Total Bases/AB.',
    truncationDigits: 3,
    highOrLow: 'higher' as HighOrLow,
  },
  onBasePlusSluggingPercentage: {
    abbreviation: 'OPS',
    longName: 'On Base Plus Slugging',
    description: 'Combination of OBP and SLG, calculated as OBP+SLG.',
    truncationDigits: 3,
    highOrLow: 'higher' as HighOrLow,
  },
  isolatedPower: {
    abbreviation: 'ISO',
    longName: 'Isolated Power',
    description: 'Average number of extra bases per at bat, calculated several ways such as: SLG - AVG.',
    truncationDigits: 3,
    highOrLow: 'higher' as HighOrLow,
  },
  battingAverageOnBallsInPlay: {
    abbreviation: 'BABIP',
    longName: 'Batting Average on Balls in Play',
    description: 'The rate at which the batter gets a hit when he puts the ball in play, calculated as (H-HR)/(AB-K-HR+SF).',
    truncationDigits: 3,
    highOrLow: 'higher' as HighOrLow,
  },
  weightedOnBaseAverage: {
    abbreviation: 'wOBA',
    longName: 'Weighted on Base Average',
    description: 'Combines all the different aspects of hitting into one metric, weighting each of them in proportion to their actual run value. While batting average, on-base percentage, and slugging percentage fall short in accuracy and scope, wOBA measures and captures offensive value more accurately and comprehensively.',
    truncationDigits: 3,
    highOrLow: 'higher' as HighOrLow,
  },
  weightedRunsAboveAverage: {
    abbreviation: 'wRAA',
    longName: 'Weighted Runs Above Average',
    description: 'Number of runs above or below average a player has added as a hitter.',
    truncationDigits: 1,
    highOrLow: 'higher' as HighOrLow,
  },
  // runs per plate attempt scaled where average is 100
  // and is league and park adjusted
  wRCPlus: {
    abbreviation: 'wRC+',
    longName: 'Weighted Runs Created Plus',
    description: 'The most comprehensive rate statistic used to measure hitting performance because it takes into account the varying weights of each offensive action (like wOBA) and then adjusts them for the park and league context in which they took place.',
    truncationDigits: 1,
    highOrLow: 'higher' as HighOrLow,
  },
  fastballRunsAboveAveragePer100: {
    abbreviation: 'wFB/C',
    longName: 'Weighted Fastball Runs per 100 pitches',
    description: 'the average amount of runs a hitter/team produced against 100 pitches thrown of that type.',
    truncationDigits: 1,
    highOrLow: 'higher' as HighOrLow,
  },
  sliderRunsAboveAveragePer100: {
    abbreviation: 'wSL/C',
    longName: 'Weighted Slider Runs per 100 pitches',
    description: 'the average amount of runs a hitter/team produced against 100 pitches thrown of that type.',
    truncationDigits: 1,
    highOrLow: 'higher' as HighOrLow,
  },
  cutterRunsAboveAveragePer100: {
    abbreviation: 'wCT/C',
    longName: 'Weighted Cutter Runs per 100 pitches',
    description: 'the average amount of runs a hitter/team produced against 100 pitches thrown of that type.',
    truncationDigits: 1,
    highOrLow: 'higher' as HighOrLow,
  },
  curveballRunsAboveAveragePer100: {
    abbreviation: 'wCB/C',
    longName: 'Weighted Curveball Runs per 100 pitches',
    description: 'the average amount of runs a hitter/team produced against 100 pitches thrown of that type.',
    truncationDigits: 1,
    highOrLow: 'higher' as HighOrLow,
  },
  changeupRunsAboveAveragePer100: {
    abbreviation: 'wCH/C',
    longName: 'Weighted Changeup Runs per 100 pitches',
    description: 'the average amount of runs a hitter/team produced against 100 pitches thrown of that type.',
    truncationDigits: 1,
    highOrLow: 'higher' as HighOrLow,
  },
  splitFingerFastballRunsAboveAveragePer100: {
    abbreviation: 'wSF/C',
    longName: 'Weighted Split Finger Runs per 100 pitches',
    description: 'the average amount of runs a hitter/team produced against 100 pitches thrown of that type.',
    truncationDigits: 1,
    highOrLow: 'higher' as HighOrLow,
  },
  hardHitBallPercentage: {
    abbreviation: 'HardHit%',
    longName: 'Hard Hit Percentage',
    description: 'Percentage of batted balls with exit velocity of 95MPH or higher.',
    truncationDigits: 1,
    highOrLow: 'higher' as HighOrLow, // really more N/A
  },

  // calculated metrics
  runDifferential: {
    abbreviation: 'RD',
    longName: 'Run Differential',
    description: 'Total number of runs scored - total number of runs allowed.',
    truncationDigits: 0,
    highOrLow: 'higher' as HighOrLow,
  },


  // pitching stats
  gamesPlayed: {
    abbreviation: 'GP',
    longName: 'Games Played',
    description: 'Number of games played.',
    truncationDigits: 0,
    highOrLow: 'higher' as HighOrLow, // really more N/A
  },
  wins: {
    abbreviation: 'W',
    longName: 'Wins',
    description: 'Number of wins.',
    truncationDigits: 0,
    highOrLow: 'higher' as HighOrLow,
  },
  losses: {
    abbreviation: 'L',
    longName: 'Losses',
    description: 'Number of losses.',
    truncationDigits: 0,
    highOrLow: 'lower' as HighOrLow,
  },
  saves: {
    abbreviation: 'SV',
    longName: 'Saves',
    description: 'Number of saves.',
    truncationDigits: 0,
    highOrLow: 'higher' as HighOrLow,
  },
  homerunsAllowed: {
    abbreviation: 'HR',
    longName: 'Homeruns Allowed',
    description: 'Number of homeruns surrendered by a pitcher or team.',
    truncationDigits: 0,
    highOrLow: 'lower' as HighOrLow,
  },
  inningsPitched: {
    abbreviation: 'IP',
    longName: 'Innings Pitched',
    description: 'Number of innings pitched.',
    truncationDigits: 1,
    highOrLow: 'higher' as HighOrLow, // really more N/A
  },
  war: {
    abbreviation: 'WAR',
    longName: 'Wins Above Replacement',
    description: 'If this player got injured and their team had to replace them with a freely available minor leaguer or a AAAA player from their bench, how much value would the team be losing (in terms of wins)?',
    truncationDigits: 2,
    highOrLow: 'higher' as HighOrLow,
  },
  xfip: {
    abbreviation: 'xFIP',
    longName: 'Expected Fielding Independent Pitching',
    description: "Estimates a pitcher's expected run prevention independent of the performance of their defense.",
    truncationDigits: 2,
    highOrLow: 'lower' as HighOrLow,
  },
  era: {
    abbreviation: 'ERA',
    longName: 'Earned Run Average',
    description: 'Number of earned runs a pitcher allows per nine innings.',
    truncationDigits: 3,
    highOrLow: 'lower' as HighOrLow,
  },
  leftOnBasePercentage: {
    abbreviation: 'LOB%',
    longName: 'Left on Base Percent',
    description: 'Measures the percentage of base runners that a pitcher strands on base over the course of a season.',
    truncationDigits: 3,
    highOrLow: 'higher' as HighOrLow,
  },
  totalBattersFaced: {
    abbreviation: 'TBF',
    longName: 'Total Batters Faced',
    description: 'Number of batters faced.',
    truncationDigits: 0,
    highOrLow: 'lower' as HighOrLow, // really more N/A
  },
  runsAllowed: {
    abbreviation: 'R',
    longName: 'Runs Allowed',
    description: 'Number of runs allowed.',
    truncationDigits: 0,
    highOrLow: 'lower' as HighOrLow,
  },
  whip: {
    abbreviation: 'WHIP',
    longName: 'Walks and Hits per Inning Pitched',
    description: 'Essentially a measurement of how many base runners a pitcher allows per inning',
    truncationDigits: 2,
    highOrLow: 'lower' as HighOrLow,
  },
  battingAverageAgainst: {
    abbreviation: 'BAA',
    longName: 'Batting Average Against',
    description: 'The opponents batting average against a team/pitcher.',
    truncationDigits: 3,
    highOrLow: 'lower' as HighOrLow,
  },
  clutch: {
    abbreviation: 'C',
    longName: 'Clutch',
    description: 'How much better or worse a player does in high leverage situations than he would have done in a contextually neutral environment.',
    truncationDigits: 2,
    highOrLow: 'higher' as HighOrLow,
  },
};