import { useState, useEffect } from 'react'

interface Counter {
  current: string
  isPaused: boolean
  isOver: boolean
  pause: () => void
  play: () => void
  reset: () => void
  togglePause: () => void
}

const useCountUp = (min: number, max: number): Counter => {
  const [count, setCount] = useState(min)
  const [paused, setPaused] = useState(false)
  const [isOver, setIsOver] = useState(false)

  useEffect(() => {
    if (paused) {
      return
    }

    const interval = setInterval(() => {
      setCount((prev) => prev + 1)
    }, 5)

    if (count >= max) {
      setIsOver(true)
      clearInterval(interval)
      return
    }

    return () => clearInterval(interval)
  }, [count, min, max, paused])

  return {
    current: count.toString(),
    isPaused: paused,
    isOver,
    pause: () => setPaused(true),
    play: () => setPaused(false),
    reset: () => {
      setIsOver(false)
      setCount(min)
    },
    togglePause: () => {
      setPaused(!paused)
    },
  }
}

export default useCountUp;