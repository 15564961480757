import Box from "@mui/material/Box";
import Competition from "./competition/Competition";
import { LeagueCode } from "reducers/types/sharedTypes";
import { Moment } from "moment-timezone";
import { AuthContext } from "context/AuthContext";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import useCompetitionDatasForLeagueAndDate from './useCompetitionDatasForLeagueAndDate';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useMediaQuery, useTheme } from "@mui/material";


export interface CompetitionsProps {
  selectedLeagueCode: LeagueCode;
  selectedDate: Moment | null;
}

const Competitions = ({selectedLeagueCode, selectedDate}: CompetitionsProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const user = useContext(AuthContext);
  let navigate = useNavigate();
  let dateForView = selectedDate ? selectedDate.format("M-DD-YYYY") : moment(new Date()).format("M-DD-YYYY");
  const competitionDataRows = useCompetitionDatasForLeagueAndDate(selectedLeagueCode, dateForView);


  useEffect(() => {
    if (!user) {
      navigate("/")
    }
  }, []);

  return (
    <Box sx={{mb: 5, background: 'white', position: 'relative'}}>

      <Grid
        item
        xs={12}
        sx={{
          borderBottom: '1px solid lightGrey',
          pt: 0.5,
          pb: 0.5,
          position: 'sticky',
          top: isMobileScreen ? 12 : 0,
          zIndex: 11,
          background: 'white',
          ml: isMobileScreen ? 0 : 20, mr: isMobileScreen ? 0 : 20
        }}
      >
        <Typography variant="h5" sx={{letterSpacing: '1px'}}>{dateForView}</Typography>
      </Grid>

      {competitionDataRows && competitionDataRows.sort().map((row) => (
        <Competition
          key={row.competition.id}
          selectedLeagueCode={selectedLeagueCode}
          competition={row.competition}
          competitionOddsData={row.competitionOddsData}
          competitionPicksData={row.competitionPicksData}
        />
      ))}
    </Box> 
  )
};

export default Competitions;