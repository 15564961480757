import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { pickEntityColor } from "components/helpers";
import { MoneylinePick, OverUnderPick, SpreadPick } from "reducers/types/competitionTypes";

export interface PickEntityDisplayProps {
  pick: SpreadPick | MoneylinePick | OverUnderPick;
};

const PickEntityDisplay = ({pick}: PickEntityDisplayProps) => {
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  return (
    <Box sx={{ display: 'flex', alignItems: 'center'}}>
      <Box sx={{ height: isTabletOrMobile ? '10px' : '15px', width: isTabletOrMobile ? '10px' : '15px', background: pickEntityColor(pick.entity), mr: 1 }} />
      <Typography variant="caption" sx={{fontSize: isTabletOrMobile ? '10px' : '14px'}}>{pick.entity}</Typography>
    </Box>
  )
};

export default PickEntityDisplay;