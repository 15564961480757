import { SpreadOdds } from "reducers/types/competitionTypes";

export const openedAndRemainedSpreadFavorite = (openAwaySpreadOdds: SpreadOdds, currentAwaySpreadOdds: SpreadOdds) => {
  if (openAwaySpreadOdds.spreadValue < 0 && currentAwaySpreadOdds.spreadValue < 0) {
    return "The away team opened the favorite and remained the favorite";
  } else if (openAwaySpreadOdds.spreadValue < 0 && currentAwaySpreadOdds.spreadValue < 0) {
    return "The away team opened the favorite, but became the underdog";
  } else if (openAwaySpreadOdds.spreadValue > 0 && currentAwaySpreadOdds.spreadValue > 0) {
    return "The home team opened the favorite and remained the favorite";
  } else {
    return "The home team opened the favorite, but became the underdog";
  }
};