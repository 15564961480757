import { Box, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { getNumberWithOrdinal, truncateMetric } from "components/helpers";
import { MLB_METRIC_MAP } from 'config/mlb';

export interface MetricRowProps {
  metricName: string;
  metricSplit: string;
  leftMetricValue: number;
  leftMetricLeagueRank?: number;
  rightMetricValue: number;
  rightMetricLeagueRank?: number;
};

const MetricRow = ({
  metricName,
  metricSplit,
  
  leftMetricValue = 0,
  leftMetricLeagueRank = 0,

  rightMetricValue = 0,
  rightMetricLeagueRank = 0,

}: MetricRowProps) => {
  let theme = useTheme();

  let leftColor = theme.palette.primary.main;
  let leftBorder = 'none';
  let rightColor = theme.palette.primary.main;
  let rightBorder = 'none';

  let metricInfo = MLB_METRIC_MAP[metricName];
  if (metricInfo.highOrLow === 'higher') {

    if (leftMetricValue > rightMetricValue) {
      rightColor = theme.palette.grey[500];
      leftBorder = `1px solid ${leftColor}`;
    }

    if (rightMetricValue > leftMetricValue) {
      leftColor = theme.palette.grey[500];
      rightBorder = `1px solid ${rightColor}`;
    }

  } else {

    if (leftMetricValue < rightMetricValue) {
      rightColor = theme.palette.grey[500];
      leftBorder = `1px solid ${leftColor}`;
    }

    if (rightMetricValue < leftMetricValue) {
      leftColor = theme.palette.grey[500];
      rightBorder = `1px solid ${rightColor}`;
    }

  }

  let leftLeagueRankText = getNumberWithOrdinal(leftMetricLeagueRank);
  if (leftMetricLeagueRank === 0) {
    leftLeagueRankText = 'N/A';
  }

  let rightLeagueRankText = getNumberWithOrdinal(rightMetricLeagueRank);
  if (rightMetricLeagueRank === 0) {
    rightLeagueRankText = 'N/A';
  }

  return (
    <Grid container xs={12} sx={{p: 2}}>
      <Grid xs={4.5} sx={{ border: leftBorder }}>
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="caption" color="primary"><strong>{truncateMetric(leftMetricValue, metricInfo.truncationDigits)}</strong></Typography>
          <Typography variant="caption">{`(${leftLeagueRankText})`}</Typography>
        </Box>
      </Grid>

      <Grid xs={3}>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Box>
            <ArrowLeftIcon fontSize="large" sx={{ color: leftColor }} />
          </Box>

          <Typography variant="caption"><strong>{metricSplit.toUpperCase()}</strong></Typography>

          <Box>
            <ArrowRightIcon fontSize="large" sx={{ color: rightColor }} />
          </Box>
        </Box>
      </Grid>

      <Grid xs={4.5} sx={{ border: rightBorder }}>
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="caption" color="primary"><strong>{truncateMetric(rightMetricValue, metricInfo.truncationDigits)}</strong></Typography>
          <Typography variant="caption">{`(${rightLeagueRankText})`}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MetricRow;