import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import { OddsBadge } from "../oddsBadge";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CircularPercentProgress from "components/library/CircularPercentProgress";
import { useTheme } from "@mui/material";
import { percentageToAmericanOdds } from "components/helpers/competitions";
import { useCountUp } from "components/library";
import '../competition/competition.css';
import { isUndefined } from "lodash";

export interface OddsCardProps {
  percentage?: number;
  value?: string;
  badgeTitle?: string;
  isFeatured?: boolean;
  // this should be cleaned up so 'null isn't just presumed a game that hasn't been completed
  spreadWinner?: boolean;
};

const OddsCard = ({ spreadWinner, percentage, badgeTitle, value, isFeatured }: OddsCardProps) => {
  const theme = useTheme();
  const { current: currentPercentage } = useCountUp(0, percentage ? (percentage * 100) : 0)
  
  let containerClass;
  if (isUndefined(spreadWinner)) {
    containerClass = "non-final-odds"
  } else if (spreadWinner) {
    containerClass = "winner-odds"
  } else {
    containerClass = "loser-odds"
  }

  return (
    <Box className={containerClass} sx={{textAlign: 'center'}}>
      <Card sx={{ width: 160, borderRadius: '0px', padding: 1 }} elevation={0}>
        <CardActionArea>
          <OddsBadge badgeTitle={badgeTitle} isFeatured={isFeatured} />

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: `1px solid ${theme.palette.accentTwo.main}`,
              borderTop: 'none',
            }}
          >

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                p: 2,
              }}
            >
              {value  && (<Typography variant="body1">{value}</Typography>)}
              <Typography
                variant="subtitle2"
                color="primary"
                sx={{paddingTop: value ? 0 : '12px', height: value ? 'auto' : '34px' }}
              >
                <strong>{`${percentage ? percentageToAmericanOdds(percentage) : "--"}`}</strong>
              </Typography>
            </Box>

            <Box sx={{ height: '50px' , ml: 2, mr: 2 }}>
              <Divider orientation="vertical" />
            </Box>

            <CircularPercentProgress currentValue={Number(currentPercentage) / 100} />
          </Box>
          
        </CardActionArea>
      </Card>
    </Box>
  )
};

export default OddsCard;