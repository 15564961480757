import { Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import Box from "@mui/material/Box";
import { CompetitionStatus } from "config/competitions";
import CountUp from 'react-countup';
import { HomeOrAway } from "reducers/types/competitionTypes";
import './scorecard.css';
import "../../../../App.css";
import { gameWinner } from "components/helpers/competitions";

interface ScorecardProps {
  competitionStatus: CompetitionStatus;
  awayProjectedScore?: number;
  homeProjectedScore?: number;
  awayFinalScore?: number;
  homeFinalScore?: number;
  homeOrAway: HomeOrAway;
};

const Scorecard = ({
  competitionStatus,
  awayProjectedScore,
  homeProjectedScore,
  awayFinalScore,
  homeFinalScore,
  homeOrAway,
}: ScorecardProps) => {
  let scorecardHeaderText = competitionStatus === CompetitionStatus.COMPLETED ? 'Final' : 'Projected';

  let finalScore = homeOrAway === 'home' ? Number(homeFinalScore) : Number(awayFinalScore)
  let projectedScore = homeOrAway === 'home' ? Number(homeProjectedScore) : Number(awayProjectedScore);

  let isNotStarted = competitionStatus === CompetitionStatus.NOT_STARTED;
  let competitionWinner = gameWinner(competitionStatus, Number(awayFinalScore), Number(homeFinalScore));

  let isWinner = competitionWinner === homeOrAway;
  let isGameLoser = competitionWinner !== 'split' && competitionWinner !== homeOrAway;

  let countComponents: JSX.Element[] = [];

  if (isNotStarted) {
    countComponents = [
      <Box sx={{ ml: "2px", mr: "2px"}} key={homeOrAway}>
        <CountUp
          start={0}
          end={Number(projectedScore)}
          // https://github.com/glennreyes/react-countup/issues/822
          delay={0}
          duration={2}
          separator=" "
          decimals={1}
          decimal="."
        >
          {({ countUpRef }) => (
            <div style={{padding: '4px', paddingLeft: '8px', paddingRight: '8px', boxShadow: "inset 0 0 3px black" }}>
              <Typography variant="h5" color="white" ref={countUpRef} sx={{fontFamily: "Numbers Normal"}} />
            </div>
          )}
        </CountUp>
      </Box>
    ]
  } else {
    let formattedScoreForComponent = String(finalScore).length === 1 ? `0${finalScore}` : String(finalScore);
    countComponents = formattedScoreForComponent.split('').map((digit: string, index: number) => {

      return (
        <Box key={index} sx={{ ml: "2px", mr: "2px"}}>
          <CountUp
            start={0}
            end={Number(digit)}
            // https://github.com/glennreyes/react-countup/issues/822
            delay={0}
            duration={2}
            separator=" "
          >
            {({ countUpRef }) => (
              <div style={{padding: '4px', boxShadow: "inset 0 0 3px black" }}>
                <Typography variant="h5" color="white" ref={countUpRef} sx={{fontFamily: "Numbers Normal"}} />
              </div>
            )}
          </CountUp>
        </Box>
      )
    });
  }
  
  return (
    <Grid xs={6} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', pt: 1, pb: 2 }}>
      <Typography variant="caption" sx={{ color: 'mediumGray' }}>{homeOrAway.toUpperCase()}</Typography>
      <Box
      className={isWinner ? "winner-score" : isGameLoser ? "loser-score" : 'non-final-score' }
        sx={{width: '100px' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, background: 'white', maxWidth: '100px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              border: '1px solid mediumGray',
              borderRadius: '10px',
              p: 1,
              pt: 0,
              background: 'white',
              maxWidth: '100px'
            }}
          >
            <Box sx={{ m: 1, mt: 0.25 }}>
              <Typography variant="caption" sx={{ color: 'mediumGray' }}>{scorecardHeaderText}</Typography>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {countComponents}
            </Box>

          </Box>
        </Box>
      </Box>
    </Grid>
  )
};

export default Scorecard;